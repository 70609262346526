import { SegmentedControl } from '@mantine/core';
import { OrderSummaryMode } from 'store/pricing/types';
import { getString } from 'strings/translation';

type Props = {
  language: string;
  mode: OrderSummaryMode;
  onChange: (mode: OrderSummaryMode) => void;
};

const OrderSummaryModeToggle = ({ onChange, language, mode }: Props) => {
  const options = [
    {
      value: 'listPrice',
      label: getString('listPrice', language),
    },
    {
      value: 'agencyPrice',
      label: getString('agencyPrice', language),
    },
  ];

  return (
    <SegmentedControl
      data={options}
      value={mode}
      onChange={(value) => onChange(value as OrderSummaryMode)}
    />
  );
};

export default OrderSummaryModeToggle;
