import React, { useState } from 'react';
import {
  Button,
  Modal,
  Text,
  Grid,
  Group,
  Checkbox,
  Space,
  Select,
  TextInput,
} from '@mantine/core';
import { SAMPLING, SCAN, skuProductTypes, skuSamplingTypes, skuTypes } from 'constants/pricing';
import { FONT_WEIGHT_BOLDISH } from 'constants/mantine';
import { SkuType, TypeOfSku } from 'store/pricing/types';
import showToast from 'actions/toastActions';

interface Props {
  opened: boolean;
  onClose: () => void;
  onSubmit: (sku: Partial<SkuType>) => void;
  allSkus: SkuType[] | undefined;
}

const AddSkuModal = ({ opened, onClose, onSubmit, allSkus }: Props) => {
  const [newSku, setNewSku] = useState<Partial<SkuType>>({});
  const [loading, setLoading] = useState(false);

  const updateBooleanProduct = (product: string) => {
    setNewSku((prev) => ({ ...prev, [product]: !prev[product] }));
  };

  const submitForm = async () => {
    if (!newSku.name || !newSku.name_pt || !newSku.netsuite_name || !newSku.type) {
      return showToast('Please fill all required fields', 'error');
    }
    try {
      setLoading(true);
      await onSubmit(newSku);
      setNewSku({});
      onClose();
      showToast('SKU added successfully');
    } catch (error) {
      showToast(error.message, 'error');
    }
    setLoading(false);
  };

  return (
    <Modal title="Add SKU" centered opened={opened} onClose={onClose} size="lg">
      <Grid grow>
        {['name', 'name_pt', 'netsuite_name'].map((item) => (
          <Grid.Col span={4} key={item}>
            <TextInput
              label={`${item.toUpperCase()}*`}
              value={newSku[item] || ''}
              onChange={(e) => setNewSku({ ...newSku, [item]: e.currentTarget.value })}
            />
          </Grid.Col>
        ))}
        <Grid.Col span={4}>
          <Select
            label="SKU Type*"
            data={skuTypes}
            onChange={(val) => val && setNewSku({ ...newSku, type: val as TypeOfSku })}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          {newSku.type === SAMPLING && (
            <Select
              label="Sampling Type"
              data={skuSamplingTypes}
              onChange={(val) => val && setNewSku({ ...newSku, sampling_zone_type: val })}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          {newSku.type === SAMPLING && (
            <TextInput
              label="Sampling Depth"
              rightSection="in"
              value={newSku.sampling_depth || ''}
              onChange={(e) =>
                setNewSku({ ...newSku, sampling_depth: Number(e.currentTarget.value) })
              }
              type="number"
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          {newSku.type && [SAMPLING, SCAN].includes(newSku.type) && (
            <Select
              label="Analysis SKU ID"
              data={allSkus?.map((sku) => ({ value: String(sku.id), label: sku.name }))}
              onChange={(val) => val && setNewSku({ ...newSku, analysis_sku_id: Number(val) })}
              value={String(newSku.analysis_sku_id) || null}
              maw="12rem"
            />
          )}
        </Grid.Col>
      </Grid>
      <Space h="lg" />
      <Grid>
        {skuProductTypes.map((product) => (
          <Grid.Col span={6} key={product}>
            <Group justify="space-between" w="80%">
              <Text fw={FONT_WEIGHT_BOLDISH}>{product}</Text>
              <Checkbox
                checked={newSku[product] || false}
                onChange={() => updateBooleanProduct(product)}
              />
            </Group>
          </Grid.Col>
        ))}
      </Grid>
      <Space h="xl" />
      <Group justify="flex-end">
        <Button variant="outline" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={submitForm} loading={loading}>
          Save
        </Button>
      </Group>
    </Modal>
  );
};

export default AddSkuModal;
