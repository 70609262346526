import React from 'react';
import { Center, Loader } from '@mantine/core';

type Props = {
  /** @default 40vh */
  h?: string | number;
};

const CenteredLoader = ({ h = '40vh' }: Props) => {
  return (
    <Center h={h}>
      <Loader />
    </Center>
  );
};

export default CenteredLoader;
