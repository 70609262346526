/**
 * @file `useQuery` hooks for the V3 order flow. Note that many of these are dependent on the
 * routing structure, so resist the urge to globalize it. The only global aspects should be the
 * query keys and fetchers (and optionally the query options, if needed).
 */
import { useQuery } from '@tanstack/react-query';
import { CUSTOM_SKU_ID, DUMMY_USER_ID } from 'constants/pricing';
import { requestAgenciesforOperation } from 'store/agencies/requests';
import fetchFieldGeometry from 'store/fields/requests';
import { generatePriceEstimate, getAllSkus } from 'store/pricing/requests';
import { requestOperationUsers } from 'store/user/requests';
import { getPriceEstimateSkusPayload } from 'util/pricing';
import {
  fieldsQueryKeys,
  operationsQueryKeys,
  pricingQueryKeys,
  agenciesQueryKeys,
} from 'util/queryKeys';
import {
  activeSamplingPlansQueryOptions,
  defaultPricingQueryOptions,
  operationQueryOptions,
} from 'util/queryOptions';
import { useNumericRouteParams } from './hooks';
import { useOrderFormContext } from './orderFormContext';

export const useSamplingPlansQuery = () => {
  const { fieldId } = useNumericRouteParams();

  return useQuery(activeSamplingPlansQueryOptions(fieldId));
};

export const useFieldQuery = () => {
  const { fieldId } = useNumericRouteParams();

  return useQuery({
    queryKey: fieldsQueryKeys.field(fieldId),
    queryFn: () => fetchFieldGeometry(fieldId),
  });
};

export const useAllAgenciesQuery = () => {
  const { operationId } = useNumericRouteParams();
  return useQuery({
    queryKey: agenciesQueryKeys.operationAgencies,
    queryFn: () => requestAgenciesforOperation(operationId),
  });
};

export const useOperationQuery = () => {
  const { operationId } = useNumericRouteParams();

  return useQuery(operationQueryOptions(operationId));
};

export const useOperationUsersQuery = () => {
  const { operationId } = useNumericRouteParams();

  return useQuery({
    queryKey: operationsQueryKeys.operationUsers(operationId),
    queryFn: () => requestOperationUsers(operationId),
    select: (data) => {
      return {
        users: [
          ...data.users,
          {
            // Default dummy user
            first_name: 'EarthOptics',
            last_name: '',
            id: DUMMY_USER_ID,
            default_shipping_lab: null,
            email: '',
            email_verified: true,
            business_name: null,
            notifications_preference: '',
            cell: null,
            billing_addresses: [],
            created_at: '',
            updated_at: '',
            eula_accepted: true,
            is_active: true,
            roles: [],
            agencies: [],
            agency_primary: true,
            user_locale: '',
            can_scan: true,
          },
        ],
      };
    },
  });
};

export const useAllSkusQuery = () =>
  useQuery({
    queryKey: pricingQueryKeys.allSkus,
    queryFn: () => getAllSkus(),
  });

export const useDefaultPricesQuery = () => useQuery(defaultPricingQueryOptions);

export const usePriceEstimateQuery = () => {
  const form = useOrderFormContext();
  const { fieldId } = useNumericRouteParams();
  const allSkusQuery = useAllSkusQuery();
  const fieldQuery = useFieldQuery();
  const defaultPricingQuery = useDefaultPricesQuery();
  const formValues = form.getValues();
  const { isSplitDensity, sampledById, scannedById, primaryProductId, nutrientAnalysisPackageId } =
    formValues;
  const acreage = fieldQuery.data?.features[0].properties.acreage || 0;
  const { skuPrices, targetBioDensity, targetScanDensity } = getPriceEstimateSkusPayload(
    formValues,
    form.getTransformedValues(),
    allSkusQuery.data || [],
    acreage,
    defaultPricingQuery?.data,
  );

  return {
    targetBioDensity,
    targetScanDensity,
    priceEstimateQuery: useQuery({
      enabled: !!skuPrices.length,
      queryKey: pricingQueryKeys.priceEstimate({
        fieldId,
        skuPrices,
        isSplitDensity,
        sampledById,
        scannedById,
        primaryProductId,
        nutrientAnalysisPackageId,
      }),
      retry: false,
      queryFn: () =>
        generatePriceEstimate({
          agency_id: formValues.agencyId || null,
          field_id: fieldId,
          sku_prices: skuPrices,
          assigned_to_id: sampledById || null,
          assigned_to_scan_id: scannedById || null,
          is_split_density: isSplitDensity,
          is_custom_analysis: primaryProductId === CUSTOM_SKU_ID,
        }),
    }),
  };
};
