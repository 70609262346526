import React from 'react';
import { Alert, Flex, Loader, Text } from '@mantine/core';
import { getAcreageUnitFromLang } from 'util/units';
import { usePriceEstimateQuery } from 'apps/ZoneAnalysisV3/queries';
import { getBeforeAndAfterDecimal } from 'util/numUtils';

type Props = {
  language: string;
};

const AnalysisProductPrice = ({ language }: Props) => {
  const { priceEstimateQuery } = usePriceEstimateQuery();
  const { data, isPending, error } = priceEstimateQuery;
  const price = data?.estimated_list_price_per_acre || 0;
  const formattedPrice = getBeforeAndAfterDecimal(price);

  if (isPending) {
    return <Loader />;
  }

  if (!formattedPrice) {
    return null;
  }

  if (error) {
    return (
      <Alert variant="filled" color="red.8">
        {error.message}
      </Alert>
    );
  }

  return (
    <Flex align="baseline" gap={0}>
      <Text fz="3rem">${formattedPrice.beforeDecimal}</Text>
      <span>
        {formattedPrice.afterDecimal ? `.${formattedPrice.afterDecimal}` : ''} /{' '}
        {getAcreageUnitFromLang(language)}
      </span>
    </Flex>
  );
};

export default AnalysisProductPrice;
