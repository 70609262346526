import {
  Flex,
  Pill,
  PillGroup,
  Stack,
  Text,
  type ComboboxItem,
  type ComboboxLikeRenderOptionInput,
} from '@mantine/core';
import { FaCheckCircle } from 'react-icons/fa';
import { SkuType } from 'store/pricing/types';
import { getSubProductsByPrimarySku } from 'util/pricing';

type Props = {
  itemProps: ComboboxLikeRenderOptionInput<ComboboxItem>;
  selectedSku: SkuType | undefined;
};

const AnalysisProductSelectItem = ({ itemProps, selectedSku }: Props) => {
  const subProducts = selectedSku ? getSubProductsByPrimarySku(selectedSku) : null;
  const { truBio, truNutrient, truTill } = subProducts || {};

  return (
    <Flex align="center" gap="xs" mb={6}>
      <FaCheckCircle
        style={{ marginLeft: 'auto', visibility: itemProps.checked ? 'visible' : 'hidden' }}
        fontSize="1.35em"
        color="green"
      />
      <Stack gap={4}>
        <Text size="md" fw="bold">
          {itemProps.option.label}
        </Text>
        <PillGroup>
          {truNutrient && (
            <Pill size="xs" bg="blue" c="white">
              TruNutrient
            </Pill>
          )}
          {truBio && (
            <Pill size="xs" bg="blue" c="white">
              TruBio
            </Pill>
          )}
          {truTill && (
            <Pill size="xs" bg="blue" c="white">
              TruTill
            </Pill>
          )}
        </PillGroup>
      </Stack>
    </Flex>
  );
};

export default AnalysisProductSelectItem;
