import { ZONE_TYPES } from 'constants/mapbox';
import {
  CUSTOM_SKU_ID,
  IS_PRO,
  IS_TILL_RX,
  NUTRIENT_TRUNUTRIENT_MAPPING,
  STANDARD_NUTRIENT_SKU_ID,
  TRUBIO_SKU_ID,
  TRUNUTRIENT_SKU_ID,
  TRUTILL_SKU_ID,
} from 'constants/pricing';
import {
  AGENCY,
  agencyProductTypes,
  COMPLETE_BIO,
  contractDensities,
  contractEditableProductsMap,
  contractTypeDisplayNames,
  CONTRACT_TYPES,
  LIST,
  NUTRIENT_PANEL,
  PATTERN_360,
  PERFORMANCE_PANEL,
  productList,
  PRODUCT_COMBINATIONS,
} from 'constants/products';
import { DEFAULT_BIO_DENSITY, DEFAULT_SCAN_DENSITY } from 'constants/samplePlanning';
import {
  ContractMinMaxType,
  ContractPriceBucketType,
  ContractResponseType,
  ContractType,
  DefaultContractPriceType,
} from 'store/agencies/types';
import {
  DefaultPricingResponse,
  OrderForm,
  OrderFormTransformedType,
  PriceSummary,
  SkuItemPayload,
  SkuType,
} from 'store/pricing/types';
import { getString } from 'strings/translation';
import { DEFAULT_COMMITTED_SAMPLES } from '../constants/variables';
import { isNumber } from './numUtils';
import { getSplitDensityOptions } from './samplePlan';
import { dollarsToReal } from './units';

type SkuTypeWithSkuId = SkuType & { sku_id: number };

export const getProductPriceDisplay = (panel: string, priceType: string, language: string) => {
  return {
    panelType: panel,
    priceType: `${panel}_${priceType}` as keyof ContractPriceBucketType,
    label: `${getString(panel, language)} ${getString(priceType, language)} ($)`,
  };
};

export const getContractTypeOptions = (
  language: string,
  contractTypes: string[] = CONTRACT_TYPES,
) => {
  return contractTypes.map((contractType) => ({
    label: getString(contractTypeDisplayNames[contractType], language),
    value: contractType,
  }));
};

export const getSamplePriceDisplay = (
  panel: string,
  priceBucket: ContractPriceBucketType | undefined,
  language: string,
) => {
  if (!priceBucket) {
    return '';
  }
  return `$${dollarsToReal(priceBucket[panel] || 0, language)} / ${getString('sample', language)}`;
};

export const splitOutListProducts = (contract: ContractResponseType) => {
  const newPriceBuckets = contract.price_buckets.reduce(
    (list, bucket) => {
      const { density } = bucket;
      if (!density) {
        return list;
      }
      return {
        ...list,
        [density]: bucket,
      };
    },
    {} as ContractType['price_buckets'],
  );
  return {
    ...contract,
    price_buckets: newPriceBuckets,
  };
};

export const setupDefaultContracts = (defaultContract: DefaultContractPriceType) =>
  Object.keys(defaultContract).reduce((acc, tier) => {
    const buckets = defaultContract[tier];
    return {
      ...acc,
      [tier]: {
        price_buckets: buckets,
        contract_type: tier,
        committed_samples: DEFAULT_COMMITTED_SAMPLES,
      },
    };
  }, {});

export const fillMissingDensities = (
  contract: ContractType,
  fillBucket: ContractType['price_buckets'],
) => {
  const filledContract = { ...contract };
  contractDensities.forEach((density) => {
    if (!filledContract.price_buckets[density]) {
      filledContract.price_buckets[density] = fillBucket[density];
    }
  });
  return filledContract;
};

const getProductPriceType = (product: string, priceType: string) => {
  if (product === PERFORMANCE_PANEL && priceType === LIST) {
    return COMPLETE_BIO;
  }
  if (product === NUTRIENT_PANEL && priceType === LIST) {
    return PATTERN_360;
  }
  return product;
};
const getContractMinMaxError = (
  product: string,
  priceType: string,
  lowHigh: string,
  language: string,
) => {
  const contractPriceType = getProductPriceType(product, priceType);
  return `${getString(contractPriceType, language)} ${getString(priceType, language)}: ${getString(
    lowHigh,
    language,
  )}`;
};

export const checkProductPriceRequirements = (
  contract: ContractType,
  minMaxPrices: ContractMinMaxType,
  language: string,
) => {
  let errorMsg: string | null = null;
  for (const priceType of [AGENCY, LIST]) {
    const products = contractEditableProductsMap[priceType];
    for (const product of products) {
      const contractPriceName = `${product}_${priceType}`;
      for (const density of contractDensities) {
        const priceBucket = contract.price_buckets[density];
        if (priceBucket[contractPriceName] < minMaxPrices[product][priceType][0]) {
          errorMsg = getContractMinMaxError(product, priceType, 'tooLowMsg', language);
          break;
        }
        if (priceBucket[contractPriceName] > minMaxPrices[product][priceType][1]) {
          errorMsg = getContractMinMaxError(product, priceType, 'tooHighMsg', language);
          break;
        }
      }
    }
    if (errorMsg) {
      break;
    }
  }
  return errorMsg;
};

export const removeUnusedPriceBucketKeys = (contract: ContractType) => {
  const allowedKeys = agencyProductTypes.flatMap((type) =>
    contractEditableProductsMap[type].map((product) => `${product}_${type}`),
  );
  const newPriceBuckets = Object.entries(contract.price_buckets).reduce(
    (list, [density, bucket]) => {
      list[density] = Object.keys(bucket).reduce((newBucket, priceKey) => {
        if (allowedKeys.includes(priceKey)) {
          newBucket[priceKey] = bucket[priceKey];
        }
        return newBucket;
      }, {});
      return list;
    },
    {},
  );
  return {
    ...contract,
    price_buckets: newPriceBuckets,
  };
};

export const getSubProductsByPrimarySku = ({
  performance_panel,
  pressure_panel,
  is_till_rx,
  nutrient_panel,
  nitrate_panel,
  is_pro,
}: SkuType): Pick<OrderForm, 'truBio' | 'truNutrient' | 'truTill' | 'nutrient'> => ({
  truBio: pressure_panel && performance_panel,
  truNutrient: nutrient_panel && is_pro,
  nutrient: nutrient_panel || nitrate_panel,
  truTill: is_till_rx,
});

export const getProductsByPrimarySku = (sku: SkuType): Pick<OrderForm, 'products'> => ({
  products: productList.filter((product) => sku[product]),
});

export const getSkuById = (skus: SkuType[], id: number): SkuType | undefined =>
  skus.find((sku) => sku.id === id);

export const getNonCustomSkus = (allSkus: SkuType[], formValues: OrderForm): SkuTypeWithSkuId[] => {
  const { primaryProductId, nutrientAnalysisPackageId } = formValues;
  const primarySku = getSkuById(allSkus, primaryProductId);

  if (!primarySku) {
    return [];
  }

  const skuPayload = [{ sku_id: primarySku.id, ...primarySku }];
  if (formValues.nutrient && nutrientAnalysisPackageId) {
    // Only add Lab package if Nutrient is on plan
    const truNutrientSecondSku = getSkuById(
      allSkus,
      NUTRIENT_TRUNUTRIENT_MAPPING[nutrientAnalysisPackageId],
    );
    if (truNutrientSecondSku) {
      skuPayload.push({ sku_id: truNutrientSecondSku.id, ...truNutrientSecondSku });
    }
  }
  return skuPayload;
};

export const getSplitDensitySkus = (
  allSkus: SkuType[],
  formValues: OrderForm,
  acres: number,
  zoneCount: number | undefined,
  isZonesPolygon: boolean,
) => {
  const { zones } = formValues;
  // Assume truBio and Nutrient
  const truBioSku = getSkuById(allSkus, TRUBIO_SKU_ID);
  const { nutrientAnalysisPackageId } = formValues;
  const nutrientSku = getSkuById(allSkus, nutrientAnalysisPackageId || STANDARD_NUTRIENT_SKU_ID);
  const totalZones = zoneCount || Math.round(acres / DEFAULT_BIO_DENSITY);
  const splitDensityOptions = getSplitDensityOptions(zoneCount || totalZones);
  const truBioZonesCount = zones
    ? new Set(zones.features.map((zone) => zone.properties?.sample_group)).size
    : splitDensityOptions?.[0]?.value || 0;
  if (truBioSku && nutrientSku) {
    return [
      {
        sku_id: truBioSku.id,
        acres: acres,
        density: acres / truBioZonesCount,
        zones: truBioZonesCount,
        zone_type: isZonesPolygon ? ZONE_TYPES.POLYGON : ZONE_TYPES.POINT,
      },
      {
        sku_id: nutrientSku.id,
        acres: acres,
        density: acres / totalZones,
        zones: totalZones,
        zone_type: isZonesPolygon ? ZONE_TYPES.POLYGON : ZONE_TYPES.POINT,
      },
    ];
  }
  return [];
};

export const getSelectedSkusFromForm = (
  formValues: OrderForm,
  allSkus: SkuType[],
): SkuTypeWithSkuId[] => {
  const { primaryProductId, truBio, truNutrient, truTill, nutrient, nutrientAnalysisPackageId } =
    formValues;
  const isCustom = primaryProductId === CUSTOM_SKU_ID;

  if (isCustom) {
    const skus: SkuTypeWithSkuId[] = [];
    if (truBio) {
      const sku = getSkuById(allSkus, TRUBIO_SKU_ID);

      if (sku) {
        skus.push({ sku_id: sku.id, ...sku });
      }
    }

    if (truNutrient && nutrientAnalysisPackageId) {
      const truNutrientSku = getSkuById(allSkus, TRUNUTRIENT_SKU_ID);
      const truNutrientSecondSku = getSkuById(
        allSkus,
        NUTRIENT_TRUNUTRIENT_MAPPING[nutrientAnalysisPackageId],
      );
      if (truNutrientSku && truNutrientSecondSku) {
        const sharedSkus = [
          { sku_id: truNutrientSku.id, ...truNutrientSku },
          { sku_id: truNutrientSecondSku.id, ...truNutrientSecondSku },
        ];
        skus.push(...sharedSkus);
      }
    }

    if (truTill) {
      const sku = getSkuById(allSkus, TRUTILL_SKU_ID);

      if (sku) {
        skus.push({ sku_id: sku.id, ...sku });
      }
    }

    if (nutrient && !truNutrient && nutrientAnalysisPackageId) {
      // If nutrient without truNutrient, specify based in the lab package selected
      const nutrientSku = getSkuById(allSkus, STANDARD_NUTRIENT_SKU_ID);
      const nutrientSecondSku = getSkuById(allSkus, nutrientAnalysisPackageId);
      if (nutrientSku && nutrientSecondSku) {
        skus.push({ sku_id: nutrientSecondSku.id, ...nutrientSecondSku });
      }
    }
    return skus;
  }

  return getNonCustomSkus(allSkus, formValues);
};

export const checkSkuHasProductFromList = (
  sku: SkuTypeWithSkuId | SkuType | undefined,
  products: string[],
): boolean => !!sku && products.some((product) => sku[product]);

export const addOptionsToSkus = (
  skuPrices: SkuTypeWithSkuId[],
  acres: number,
  zoneCount: number,
  scanPointsCount: number,
  isZonesPolygon: boolean,
): SkuItemPayload[] =>
  skuPrices.map((sku) => {
    const hasBio = checkSkuHasProductFromList(sku, PRODUCT_COMBINATIONS.tru_bio);
    const isProOrTill = checkSkuHasProductFromList(sku, [IS_PRO, IS_TILL_RX]);
    if (hasBio && isProOrTill) {
      return {
        ...sku,
        acres,
        density: acres / zoneCount,
        zones: zoneCount,
        scan_points: scanPointsCount,
        scan_density: acres / scanPointsCount,
        zone_type: ZONE_TYPES.POLYGON,
      };
    }
    if ((hasBio || checkSkuHasProductFromList(sku, [NUTRIENT_PANEL])) && !isProOrTill) {
      return {
        ...sku,
        acres,
        density: acres / zoneCount,
        zones: zoneCount,
        zone_type: isZonesPolygon ? ZONE_TYPES.POLYGON : ZONE_TYPES.POINT,
      };
    }
    return {
      ...sku,
      acres,
      scan_points: scanPointsCount,
      scan_density: acres / scanPointsCount,
      zone_type: ZONE_TYPES.POINT,
    };
  });

export const findAvgDensity = (densities: [number, number][]): number | null => {
  if (!densities.length) return null;

  const maxOfMins = Math.max(...densities.map(([min]) => min));
  const minOfMaxs = Math.min(...densities.map(([, max]) => max));

  return maxOfMins <= minOfMaxs ? (maxOfMins + minOfMaxs) / 2 : null;
};

export const getZoneEstimatedCount = ({
  selectedSkuIds,
  defaultPricing,
  acres,
  minDensityKey,
  maxDensityKey,
}: {
  selectedSkuIds: number[];
  defaultPricing: DefaultPricingResponse;
  acres: number;
  minDensityKey: 'min_density' | 'scan_min_density';
  maxDensityKey: 'max_density' | 'scan_max_density';
}): number => {
  const foundSkuMinMax = selectedSkuIds.flatMap((skuId) =>
    (defaultPricing[skuId]?.prices || [])
      .filter((price) => isNumber(price[minDensityKey]) && isNumber(price[maxDensityKey]))
      .map((price) => [price[minDensityKey], price[maxDensityKey]] as [number, number]),
  );
  const avgDensity = findAvgDensity(foundSkuMinMax);
  return avgDensity ? Math.round(acres / avgDensity) : DEFAULT_BIO_DENSITY;
};

export const getPriceEstimateSkusPayload = (
  formValues: OrderForm,
  transformedValues: OrderFormTransformedType,
  allSkus: SkuType[],
  acres: number,
  defaultPricing: DefaultPricingResponse | undefined,
): { skuPrices: SkuItemPayload[]; targetBioDensity: number; targetScanDensity: number } => {
  if (!defaultPricing) {
    return { skuPrices: [], targetBioDensity: 0, targetScanDensity: 0 };
  }
  const { zones, scanPoints, isSplitDensity } = formValues;
  const { isProOrTillRx, hasBioProducts } = transformedValues;

  // Sampling fees change for polygon vs point zones (only applicable to non-pro/till)
  const isZonesPolygon = zones
    ? zones.features.some((feat) => feat.properties?.zone_type !== ZONE_TYPES.POINT)
    : true;

  if (isSplitDensity) {
    // Price estimate will not work for split density, until zones are selected --- FOR NOW
    const zoneCount = zones?.features.length;
    return {
      skuPrices: getSplitDensitySkus(allSkus, formValues, acres, zoneCount, isZonesPolygon),
      targetBioDensity: DEFAULT_BIO_DENSITY,
      targetScanDensity: 0,
    };
  }

  const selectedSkus = getSelectedSkusFromForm(formValues, allSkus);
  const zonesCount = hasBioProducts
    ? zones?.features.length ||
      getZoneEstimatedCount({
        selectedSkuIds: selectedSkus.map((sku) => sku.sku_id),
        defaultPricing,
        acres,
        minDensityKey: 'min_density',
        maxDensityKey: 'max_density',
      })
    : 0;
  const scanPointsCount = isProOrTillRx
    ? scanPoints?.features.length ||
      getZoneEstimatedCount({
        selectedSkuIds: selectedSkus.map((sku) => sku.sku_id),
        defaultPricing,
        acres,
        minDensityKey: 'scan_min_density',
        maxDensityKey: 'scan_max_density',
      })
    : 0;
  return {
    skuPrices: addOptionsToSkus(selectedSkus, acres, zonesCount, scanPointsCount, isZonesPolygon),
    targetBioDensity: zonesCount ? acres / zonesCount : 0,
    targetScanDensity: scanPointsCount ? acres / scanPointsCount : 0,
  };
};

export const getOrderPriceTotalsBySkuType = (
  priceSummaries: PriceSummary[],
  isListPrice?: boolean,
): {
  perAcre: number;
  perZone: number;
  total: number;
} => {
  return priceSummaries.reduce(
    (acc, { price, list_price, total_price, total_list_price, price_type }) => {
      const isPerAcrePricing = price_type === 'per_acre';
      const isPerSamplePricing = price_type === 'per_sample';

      const displayPrice = isListPrice ? list_price : price;
      const displayTotalPrice = isListPrice ? total_list_price : total_price;

      return {
        total: acc.total + displayTotalPrice,
        perAcre: isPerAcrePricing ? acc.perAcre + displayPrice : acc.perAcre,
        perZone: isPerSamplePricing ? acc.perZone + displayPrice : acc.perZone,
      };
    },
    { perAcre: 0, perZone: 0, total: 0 },
  );
};

export const findClosestDensity = (
  densitySelections: number[],
  selectedDensity: number,
): number => {
  let closestValue = densitySelections[0];
  let smallestDifference = Math.abs(densitySelections[0] - selectedDensity);
  densitySelections.forEach((density) => {
    const difference = Math.abs(density - selectedDensity);
    if (difference < smallestDifference) {
      closestValue = density;
      smallestDifference = difference;
    }
  });
  return closestValue || DEFAULT_SCAN_DENSITY;
};
