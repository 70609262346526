import React, { useMemo } from 'react';
import { MapboxSample } from 'store/fields/types';
import { AnalyticType, SingleAnalyticType } from 'store/analytics/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  getSampleRangeText,
  getPercentAtRiskAcres,
  planHasMissingSample,
  getMinMaxSampleRange,
  roundAnalyticValue,
} from 'util/results';
import { AWAITING_RESULTS, NO_DATA, NOT_ANALYZED } from 'constants/fieldRisks';
import { RecommendationType } from 'store/recommendations/types';
import { roundThreeDecimal } from 'util/numUtils';
import { NOT_APPLICABLE, NO_DATA as NoDataText } from 'constants/defaultValues';
import { SimpleGrid, Stack, Text } from '@mantine/core';
import styles from './Summary.module.css';

type SummaryPropsType = {
  planAnalytic: SingleAnalyticType;
  activeAnalytic: AnalyticType;
  samples: MapboxSample[];
  recommendation: RecommendationType | undefined;
  risk: string;
  toggleEditRecommendation: (arg0: boolean) => void;
};

const Summary = ({
  planAnalytic,
  activeAnalytic,
  samples,
  recommendation,
  risk,
  toggleEditRecommendation,
}: SummaryPropsType) => {
  const language = useBroswerLanguage();
  const sampleRange = getMinMaxSampleRange(samples, activeAnalytic);

  const quantity = useMemo(() => {
    return planAnalytic.quantity ? roundAnalyticValue(planAnalytic.quantity) : NoDataText;
  }, [activeAnalytic]);

  return (
    <Stack>
      <SimpleGrid cols={3}>
        <Text>{getString('fieldAvg', language)}:</Text>
        <Text fw={700}>{quantity}</Text>
      </SimpleGrid>
      <SimpleGrid cols={3}>
        <Text>{getString('rating', language)}:</Text>
        <Text fw={700}>
          {getString(planAnalytic.risk_level || '', language)} {getString('risk', language)}
        </Text>
      </SimpleGrid>
      <SimpleGrid cols={3}>
        <Text>{getString('acAtRisk', language)}:</Text>
        <Text fw={700}>{roundThreeDecimal(getPercentAtRiskAcres(samples, activeAnalytic))}</Text>
      </SimpleGrid>
      <SimpleGrid cols={3}>
        <Text>{getString('range', language)}:</Text>
        <Text fw={700}>
          {getSampleRangeText(sampleRange.lowest, sampleRange.highest)} {planAnalytic.unit || ''}
        </Text>
      </SimpleGrid>
      <Text>{getString('recommendation', language)}:</Text>
      <Text
        fw={700}
        className={styles.Clickable}
        onClick={() =>
          toggleEditRecommendation(![NOT_ANALYZED, NO_DATA, AWAITING_RESULTS].includes(risk))
        }
      >
        {recommendation?.recommendation || (risk === AWAITING_RESULTS ? 'TBD' : NOT_APPLICABLE)}
      </Text>
      <i className={styles.Disclaimer}>
        {planHasMissingSample(samples, activeAnalytic) &&
          `* ${getString('averageAppliedToZone', language)}`}
      </i>
    </Stack>
  );
};

export default Summary;
