import { Box, Grid } from '@mantine/core';
import { getString } from 'strings/translation';
import OrderSummaryGrid from './OrderSummaryGrid';

const { Col: Column } = Grid;

type Props = {
  language: string;
  totalPrice: number;
  pricePerAcre: number;
};

const OrderSummaryTotalsFooter = ({ language, totalPrice, pricePerAcre }: Props) => {
  return (
    <Box px="md" w="100%">
      <OrderSummaryGrid>
        <Column span={4}>{getString('totals', language)}</Column>
        <Column span={1}>${pricePerAcre.toLocaleString()}</Column>
        <Column span={1}>${totalPrice.toLocaleString()}</Column>
      </OrderSummaryGrid>
    </Box>
  );
};

export default OrderSummaryTotalsFooter;
