import { Grid } from '@mantine/core';

type Props = {
  children: React.ReactNode;
};

const OrderSummaryGrid = ({ children }: Props) => {
  return (
    <Grid columns={6} w="100%">
      {children}
    </Grid>
  );
};

export default OrderSummaryGrid;
