import React, { useEffect, useRef } from 'react';
import {
  Table,
  TextInput,
  Select,
  ActionIcon,
  Tooltip,
  Flex,
  Modal,
  Space,
  Group,
  Button,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FiSave, FiRotateCcw, FiX } from 'react-icons/fi';
import { priceDetailDensityKeys, PriceDetailType, SkuType } from 'store/pricing/types';
import { PRICE_TYPES } from 'constants/pricing';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isNumber } from 'util/numUtils';
import showToast from 'actions/toastActions';
import { createDefaultPrice, deleteDefaultPrice, updateDefaultPrice } from 'store/pricing/requests';
import { pricingQueryKeys } from 'util/queryKeys';
import { useDisclosure } from '@mantine/hooks';

interface Props {
  priceSku?: PriceDetailType;
  skuOptions: SkuType[] | undefined;
}

const DefaultSkuPriceRow = ({ skuOptions, priceSku }: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  const rowRef = useRef<HTMLTableRowElement>(null);
  const queryClient = useQueryClient();
  const queryKey = pricingQueryKeys.defaultPricing;

  const form = useForm<Partial<PriceDetailType>>({
    mode: 'uncontrolled',
    initialValues: {
      sku_id: String(priceSku?.sku_id),
      price_type: priceSku?.price_type,
      price: priceSku?.price,
      list_price: priceSku?.list_price,
      min_density: priceSku?.min_density,
      max_density: priceSku?.max_density,
      notes: '',
    },

    validate: {
      sku_id: (value) => (!isNumber(Number(value)) ? 'Please select a SKU' : null),
      price_type: (value) => (!value ? 'Please select a price type' : null),
      price: (value) => (!isNumber(Number(value)) ? 'Please enter a valid price' : null),
      list_price: (value) => (!isNumber(Number(value)) ? 'Please enter a valid list price' : null),
    },
  });

  const defaultPriceMutation = useMutation({
    mutationFn: (defaultPrice: Partial<PriceDetailType> & { isDelete?: boolean }) => {
      if (defaultPrice?.isDelete && defaultPrice.id) {
        return deleteDefaultPrice(defaultPrice.id);
      }
      return defaultPrice?.id ? updateDefaultPrice(defaultPrice) : createDefaultPrice(defaultPrice);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      showToast('Default price updated successfully');
      if (!priceSku) {
        resetPriceSku();
      }
    },
    onError: () => {
      showToast('Failed to update default price. Refresh or try again.', 'error');
    },
  });

  useEffect(() => {
    if (priceSku) {
      form.setInitialValues({
        ...priceSku,
        sku_id: String(priceSku.sku_id),
      });
      resetPriceSku();
    }
  }, [priceSku]);

  const resetPriceSku = () => {
    form.reset();
  };

  const addUpdatePriceSku = (isDelete?: boolean) => {
    if (form.validate()?.hasErrors) {
      return showToast('Price values are invalid.', 'error');
    }
    const newPriceSku = form.getValues();
    priceDetailDensityKeys.forEach((key) => {
      if (!isNumber(newPriceSku[key])) {
        newPriceSku[key] = null;
      }
    });
    defaultPriceMutation.mutate({
      ...newPriceSku,
      sku_id: Number(newPriceSku.sku_id),
      isDelete,
    });
  };

  return (
    <Table.Tr key={priceSku?.id} ref={rowRef}>
      <Modal opened={opened} onClose={close} title="Deactivate Price?" centered>
        <Space h="lg" />
        <Group justify="flex-end">
          <Button color="darkRed" onClick={() => addUpdatePriceSku(true)}>
            Deactivate
          </Button>
        </Group>
      </Modal>
      <Table.Td>
        <Select
          data={skuOptions?.map((sku) => ({ value: String(sku.id), label: sku.name, ...sku }))}
          placeholder="Select SKU"
          key={form.key('sku_id')}
          {...form.getInputProps('sku_id')}
          disabled={!!priceSku}
        />
      </Table.Td>
      <Table.Td>
        <Select
          data={PRICE_TYPES}
          placeholder="Per Acre / Sample"
          key={form.key('price_type')}
          {...form.getInputProps('price_type')}
        />
      </Table.Td>
      {['price', 'list_price'].map((display) => (
        <Table.Td key={display}>
          <TextInput
            leftSection="$"
            type="number"
            key={form.key(display)}
            {...form.getInputProps(display, { type: 'input' })}
          />
        </Table.Td>
      ))}
      {priceDetailDensityKeys.map((display) => (
        <Table.Td key={display}>
          <TextInput
            type="number"
            key={form.key(display)}
            {...form.getInputProps(display, { type: 'input' })}
          />
        </Table.Td>
      ))}
      <Table.Td>
        <TextInput key={form.key('notes')} {...form.getInputProps('notes', { type: 'input' })} />
      </Table.Td>
      <Table.Td>
        <Flex gap="sm">
          <Tooltip label="Save" bg="blue">
            <ActionIcon
              onClick={() => addUpdatePriceSku()}
              disabled={!form.isDirty() || !form.isValid()}
            >
              <FiSave />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Clear changes" bg="blue">
            <ActionIcon
              variant="outline"
              color="darkRed"
              onClick={resetPriceSku}
              disabled={!form.isDirty()}
            >
              <FiRotateCcw />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Table.Td>
      <Table.Td>
        {priceSku?.id && (
          <Tooltip label="Deactivate SKU" bg="blue">
            <ActionIcon variant="outline" color="darkRed" onClick={open}>
              <FiX />
            </ActionIcon>
          </Tooltip>
        )}
      </Table.Td>
    </Table.Tr>
  );
};

export default DefaultSkuPriceRow;
