import React from 'react';
import { Table, Group, Text, Stack, Button, Center, Loader } from '@mantine/core';
import { SkuType } from 'store/pricing/types';
import AddSkuModal from './AddSkuModal';
import { useDisclosure } from '@mantine/hooks';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { createSku, deleteSku, updateSku } from 'store/pricing/requests';
import showToast from 'actions/toastActions';
import { pricingQueryKeys } from 'util/queryKeys';
import SkuRow from './SkuRow';
import { skuTypes } from 'constants/pricing';

interface Props {
  skuQuery: UseQueryResult<SkuType[], Error>;
}

const SkuTable = ({ skuQuery }: Props) => {
  const [opened, { open, close }] = useDisclosure(false);

  const queryClient = useQueryClient();
  const queryKey = pricingQueryKeys.allSkus;

  const skutation = useMutation({
    mutationFn: (sku: Partial<SkuType> & { isDelete?: boolean }) => {
      if (sku?.isDelete && sku.id) {
        return deleteSku(sku.id);
      }
      return sku.id ? updateSku(sku) : createSku(sku);
    },
    onError: (err) => showToast(err.message, 'error'),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  if (skuQuery.isLoading) {
    return (
      <Center h="40vh">
        <Loader />
      </Center>
    );
  }

  const skuTypesObj = skuQuery.data?.reduce<Record<string, SkuType[]>>(
    (acc, sku) => {
      acc[sku.type].push(sku);
      return acc;
    },
    Object.fromEntries(skuTypes.map((type) => [type, []])),
  );

  return (
    <Stack>
      <AddSkuModal
        opened={opened}
        onClose={close}
        onSubmit={skutation.mutate}
        allSkus={skuQuery.data}
      />
      <Group justify="flex-end">
        <Button variant="outline" onClick={open}>
          Add SKU
        </Button>
      </Group>

      {skuQuery.data?.length ? (
        <Table withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Name (pt)</Table.Th>
              <Table.Th>Netsuite Name</Table.Th>
              <Table.Th>Type</Table.Th>
              <Table.Th>Pro</Table.Th>
              <Table.Th>Till Rx</Table.Th>
              <Table.Th>Pressure Panel</Table.Th>
              <Table.Th>Performance Panel</Table.Th>
              <Table.Th>Nutrient Panel</Table.Th>
              <Table.Th>Nitrate Panel</Table.Th>
              <Table.Th>590 Analysis</Table.Th>
              <Table.Th>Analysis SKU (sampling)</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {skuTypesObj &&
              Object.keys(skuTypesObj).map((skuType) => (
                <>
                  <Table.Tr key={skuType}>
                    <Table.Td>{skuType.toUpperCase()}</Table.Td>
                  </Table.Tr>
                  {skuTypesObj[skuType].map((sku) => (
                    <SkuRow key={sku.id} sku={sku} mutateSku={skutation.mutate} />
                  ))}
                </>
              ))}
          </Table.Tbody>
        </Table>
      ) : (
        <Center h="40vh">
          <Text fw={FONT_WEIGHT_BOLD}>No SKUs exist yet..</Text>
        </Center>
      )}
    </Stack>
  );
};

export default SkuTable;
