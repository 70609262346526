import { Modal } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import CenteredLoader from 'common/CenteredLoader';
import { ContractPricingPayload, ContractPricingType } from 'store/pricing/types';
import { contractsPricingQueryOptions } from 'util/queryOptions';
import PricingTable from './PricingTable';

type Props = {
  agencyIdString: string;
  onClose: VoidFunction;
  onSubmit: (payload: ContractPricingPayload) => void;
  contractPricing?: ContractPricingType | null;
};

const ContractPricingModal = ({ contractPricing, onClose, onSubmit, agencyIdString }: Props) => {
  const { data: defaultContractPricings, isFetching } = useQuery({
    ...contractsPricingQueryOptions(),
    enabled: !contractPricing,
  });

  const contractPricingData =
    contractPricing || (defaultContractPricings && defaultContractPricings[0]);

  return (
    <Modal opened onClose={onClose} size="auto" title="Contract Pricing">
      {isFetching && <CenteredLoader />}
      {contractPricingData && (
        <PricingTable
          initialContractData={contractPricingData}
          onSubmit={onSubmit}
          onModalClose={onClose}
          agencyIdString={agencyIdString}
        />
      )}
    </Modal>
  );
};

export default ContractPricingModal;
