import { Select, type ComboboxData } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { getString } from 'strings/translation';

type Props = {
  language: string;
  disabled: boolean;
  onChange: (val: string | null) => void;
  options: Array<{
    id: number;
    displayName: number;
    label: number;
    value: string;
  }>;
};

const ProPointScanDensitySelect = ({ language, onChange, disabled, options }: Props) => {
  const form = useOrderFormContext();
  const inputProps = form.getInputProps('scanDensity');

  const mantineFriendlyOptions: ComboboxData = options.map((option) => ({
    label: option.displayName.toString(),
    value: option.value.toString(),
  }));

  return (
    <Select
      key={form.key('scanDensity')}
      label={getString('density', language)}
      data={mantineFriendlyOptions}
      w="5rem"
      disabled={disabled}
      {...form.getInputProps('scanDensity')}
      value={String(form.getValues().scanDensity)}
      onChange={(density) => {
        onChange(density);
        inputProps.onChange(density);
      }}
    />
  );
};

export default ProPointScanDensitySelect;
