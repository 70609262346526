import { useQuery } from '@tanstack/react-query';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { useAllSkusQuery } from 'apps/ZoneAnalysisV3/queries';
import SelectWithInlineLabel from 'common/Components/SelectWithInlineLabel';
import { EXTERNAL_LAB_SKU_ID } from 'constants/pricing';
import { OrderForm } from 'store/pricing/types';
import { externalLabsQueryOptions } from 'util/queryOptions';
import { getAnalysisPackageOptions } from 'util/samplePlanV3';

type Props = {
  disabled: boolean;
};

const NUTRIENT_PACKAGE_FORM_KEY: keyof OrderForm = 'nutrientAnalysisPackageId';
const EXTERNAL_LAB_FORM_KEY: keyof OrderForm = 'externalLabKey';
const LABEL = 'Lab Package';

const AnalysisPackageSelect = ({ disabled }: Props) => {
  const form = useOrderFormContext();
  const allSkusQuery = useAllSkusQuery();

  const externalLabsQuery = useQuery({
    ...externalLabsQueryOptions,
    select: (data) => {
      return Object.entries(data).map(([identifier, values]) => ({ ...values, identifier }));
    },
  });

  const { data: externalLabs = [] } = externalLabsQuery;
  // TODO: implement when we get the okay for external labs
  const hasExternalLabs = false; // !!externalLabs.length;
  const analysisPackageInputProps = form.getInputProps(NUTRIENT_PACKAGE_FORM_KEY);
  const { nutrientAnalysisPackageId: selectedPackageId } = form.getValues();

  const handlePackageChange = (currentPackage: string | null) => {
    analysisPackageInputProps.onChange(Number(currentPackage));
    if (currentPackage && Number(currentPackage) !== EXTERNAL_LAB_SKU_ID) {
      form.setValues({ externalLabKey: '' });
    }
  };

  return (
    <>
      <SelectWithInlineLabel
        labelId="analysis-package-label"
        labelText={LABEL}
        rootFlexProps={{ flex: 1 }}
        selectProps={{
          key: form.key(NUTRIENT_PACKAGE_FORM_KEY),
          flex: 1,
          maw: 275,
          data: getAnalysisPackageOptions(hasExternalLabs, allSkusQuery.data || []),
          disabled,
          ...analysisPackageInputProps,
          onChange: handlePackageChange,
          value: String(form.getValues()[NUTRIENT_PACKAGE_FORM_KEY]),
        }}
      />
      {selectedPackageId === EXTERNAL_LAB_SKU_ID && (
        <SelectWithInlineLabel
          labelId="external-lab-label"
          labelText="External lab"
          rootFlexProps={{ flex: 1 }}
          selectProps={{
            key: form.key(EXTERNAL_LAB_FORM_KEY),
            flex: 1,
            data: externalLabs.map(({ name, identifier, tests }) => ({
              value: identifier,
              label: `${name} (${tests.join(' + ')})`,
            })),
            ...form.getInputProps(EXTERNAL_LAB_FORM_KEY),
            value: String(form.getValues()[EXTERNAL_LAB_FORM_KEY]),
          }}
        />
      )}
    </>
  );
};

export default AnalysisPackageSelect;
