import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Title, Tabs, Space } from '@mantine/core';
import PricingTable from './DefaultPricing/PricingTable';
import SkuTable from './Skus/SkuTable';
import { PRICING, PRICING_TABS, SKUS } from 'constants/pricing';
import { useQuery } from '@tanstack/react-query';
import { allSkusQueryOptions } from 'util/queryOptions';

const ProductPricingContainer = () => {
  const { viewId } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(viewId || SKUS);
  const skuQuery = useQuery(allSkusQueryOptions);

  const navigateToTab = (tab: string | null) => {
    if (!tab) return;
    setSelectedTab(tab);
    navigate(`/product/pricing/${tab}`);
  };

  return (
    <Stack>
      <Title order={2}>Sample Pricing</Title>

      <Tabs color="blue" onChange={navigateToTab} value={selectedTab}>
        <Tabs.List>
          {PRICING_TABS.map((tab) => (
            <Tabs.Tab value={tab} key={tab}>
              {tab.toUpperCase()}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Space h="lg" />
        <Tabs.Panel value={SKUS}>
          <SkuTable skuQuery={skuQuery} />
        </Tabs.Panel>
        <Tabs.Panel value={PRICING}>
          <PricingTable skuQuery={skuQuery} />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

export default ProductPricingContainer;
