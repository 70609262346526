import { ProductConnectionType } from 'store/cropPlans/types';
import { getString } from 'strings/translation';
import { BIOFERTILITY, CROP_PROTECTION, PATHOGENS } from './results';
import { ALL, NONE } from './variables';

export const BIOLOGICAL = 'biological';
export const BELOW_GROUND = 'below_ground';
export const FOLIAR = 'foliar';
export const IN_FURROW = 'in_furrow';
export const SEED_TREATMENT = 'seed_treatment';
export const BASE_TREATMENT = 'base_treatment';
export const SOIL_APPLIED = 'soil_applied';
export const SEED_DISEASE_RISKS = 'seed_disease_risks';

export const SEED = 'seed';

export const CATALOG_INPUT_TYPES = [SEED, CROP_PROTECTION, BIOFERTILITY];

export const SEEDS = 'seeds';
export const AGRONOMIC_PRODUCTS = 'agronomic_products';

export const NO_COVERAGE = 0;
export const LOW_COVERAGE = 1;
export const MODERATE_COVERAGE = 2;
export const HIGH_COVERAGE = 3;

export const TRAITS = 'traits';
export const TREATMENT = 'treatment';
export const OPEN_RISKS = 'openRisks';

export const PATTERN_PRODUCT_CROP_PROTECTION = 'crop_protection';
export const allCategories = [SEED, TRAITS, TREATMENT, IN_FURROW, SOIL_APPLIED, FOLIAR, OPEN_RISKS];
export const CROP_PLAN_HEADERS = {
  [PATHOGENS]: [SEED, TRAITS, TREATMENT, IN_FURROW, FOLIAR, OPEN_RISKS],
  [BIOFERTILITY]: [TREATMENT, IN_FURROW, SOIL_APPLIED, FOLIAR, OPEN_RISKS],
};

export const INPUTS_MAP = {
  [SEED]: SEED,
  [TRAITS]: TRAITS,
  [TREATMENT]: SEED_TREATMENT,
  [IN_FURROW]: IN_FURROW,
  [FOLIAR]: FOLIAR,
  [BASE_TREATMENT]: BASE_TREATMENT,
  [SOIL_APPLIED]: SOIL_APPLIED,
};

export const CHART_INPUTS_SELECTION_MAP = {
  [FOLIAR]: [FOLIAR],
  [IN_FURROW]: [IN_FURROW],
  [SEED_TREATMENT]: [TREATMENT],
  [BASE_TREATMENT]: [BASE_TREATMENT],
  [SOIL_APPLIED]: [SOIL_APPLIED],
};

export const CATEGORY_INPUT_MAP = {
  [TREATMENT]: SEED_TREATMENT,
  [IN_FURROW]: PATTERN_PRODUCT_CROP_PROTECTION,
  [FOLIAR]: PATTERN_PRODUCT_CROP_PROTECTION,
};

export const OUTSTANDING_RISK_COLOR = '#D72E3D66';

export const PRODUCT_NONE_ID = 20643;

export const getNoneProduct = (language: string): ProductConnectionType => ({
  name: getString('none', language),
  id: PRODUCT_NONE_ID,
  crop: ALL,
  pattern_product_type: NONE,
  registrant: NONE,
  ratings: [],
});
