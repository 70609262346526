import {
  CANCELLED,
  COMPLETED,
  CONFIRM_ANALYSIS,
  CREATED,
  CUSTOM_POINTS,
  UPLOAD_ZONES,
  DEM_ZONES,
  GRID_POINTS,
  GRID_ZONES,
  POINT_BASED,
  SSURGO_ZONES,
  SPLIT_DENSITY,
  ZONE_BY_ZONE,
  PARTIALLY_SAMPLED,
  PARTIALLY_SHIPPED,
  SAMPLED,
  SHIPPED,
  PARTIALLY_RECEIVED,
  RECEIVED,
  PARTIAL,
  ALL_ASSIGNMENTS,
  UNASSIGNED,
  ACCEPTED,
  DECLINED,
  ALL_READINESS,
  READY,
  NOT_READY,
  CRW,
  RKN,
  PER_ACRE_SAMPLING_KEYS,
  UNASSIGNED_PATTERN,
  UNASSIGNED_EO,
  splitDensities,
  SAMPLES_NOT_COMPLETE,
  EMI_NEEDED_NOT_COMPLETE,
  ADCP_NEEDED_NOT_COMPLETE,
} from 'constants/samplePlanning';
import {
  boronIds,
  bufferPhIds,
  calciumIds,
  CEC_ID,
  copperIds,
  ironIds,
  magnesiumIds,
  manganeseIds,
  NITROGEN_ID,
  phosphorusIds,
  PH_ID,
  potassiumIds,
  PRO_NUTRIENT_ANALYTIC_IDS,
  SOIL_ATTRIBUTES,
  SULFUR_ID,
  zincIds,
} from 'constants/results';
import {
  DISCOUNT,
  NITRATE_PANEL,
  NUTRIENT_PANEL,
  PERFORMANCE_PANEL,
  PRESSURE_PANEL,
  splitDensityProducts,
} from 'constants/products';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { SamplingPlanType, SampleType, FieldPropertiesType, FieldType } from 'store/fields/types';
import { PriceSummaryType, SamplePlanTrackingType, SAMPLE_STATUSES } from 'store/samplePlans/types';
import { getString } from 'strings/translation';
import { ZoneAnalysisStateType as ZoneAnalysisStateTypeV2 } from 'store/zoneAnalysisV2/reducer';
import { TimingsType } from 'store/samplingTimings/types';
import { ALL } from 'constants/variables';
import {
  featureCollection,
  Feature,
  FeatureCollection,
  Polygon,
  Point,
  MultiPolygon,
  feature,
} from '@turf/helpers';
import { GeoJsonProperties } from 'geojson';
import { v4 as uuid } from 'uuid';
import { BR, US } from 'constants/countries';
import { DateAttributesType, SampleGeoJSON } from './generalTypes';
import {
  defaultPlanDate,
  formatCalendarMonthDayYear,
  localDate,
  sortByCreatedAt,
  sortByDateAttribute,
} from './date';
import { getWhiteLabelTranslation, joinStrings } from './stringUtils';
import { getDefaultTimingOptionIndex, isNutrientPanel } from './product';
import {
  acresToFeetSideLength,
  convertToDisplayPoint,
  convertToPolygon,
  isPointZone,
  processSingleZoneFeature,
  processZoneFeatureCollection,
} from './geospatial';
import { roundTwoDecimal } from './numUtils';
import { OperationType } from 'store/operation/types';
import { POINT, POLYGON, ZONE_SELECTED, ZONE_TYPES } from 'constants/mapbox';
import { getActiveSamples, getSamples } from 'store/samples/requests';
import center from '@turf/center';
import { ANALYSIS_TYPES } from 'store/zoneAnalysisV2/types';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { haToAcres } from './units';
import buffer from '@turf/buffer';
import pointGrid from '@turf/point-grid';
import squareGrid from '@turf/square-grid';
import turfBbox from '@turf/bbox';
import { AnalyticType } from 'store/analytics/types';
import { WISCONSIN } from 'constants/states';
import { ALL_PLANS, ALL_PRO_OR_TILL, NON_PRO_TILL, PRO_ONLY, TILL_ONLY } from 'constants/alerts';
import { SELF_SCANNING, SELF_SAMPLING } from 'constants/agency';
import { User } from 'store/user/types';
import { postDensityGroupings } from 'store/samplePlans/requests';
import { CreationOption } from 'store/pricing/types';

export const getSamplePlanStatuses = (language: string) => [
  { label: getString(CREATED, language), id: 1, value: CREATED },
  { label: getString(PARTIALLY_SAMPLED, language), id: 2, value: PARTIALLY_SAMPLED },
  { label: getString(SAMPLED, language), id: 3, value: SAMPLED },
  { label: getString(PARTIALLY_SHIPPED, language), id: 4, value: PARTIALLY_SHIPPED },
  { label: getString(SHIPPED, language), id: 5, value: SHIPPED },
  { label: getString(PARTIALLY_RECEIVED, language), id: 6, value: PARTIALLY_RECEIVED },
  { label: getString(RECEIVED, language), id: 7, value: RECEIVED },
  { label: getString(PARTIAL, language), id: 8, value: PARTIAL },
  { label: getString(COMPLETED, language), id: 9, value: COMPLETED },
];

export const getAssignmentOptions = (language: string) => [
  { label: getString(ALL_ASSIGNMENTS, language), id: 0, value: ALL_ASSIGNMENTS },
  { label: getString('unassigned_all', language), value: UNASSIGNED },
  { label: getString('unassigned_pattern', language), value: UNASSIGNED_PATTERN },
  { label: getString('unassigned_eo', language), value: UNASSIGNED_EO },
  { label: getString(ACCEPTED, language), value: ACCEPTED },
  { label: getString(DECLINED, language), value: DECLINED },
];

export const getReadinessOptions = (language: string) => [
  { label: getString(ALL_READINESS, language), id: 0, value: ALL_READINESS },
  { label: getString(READY, language), id: 1, value: READY },
  { label: getString(NOT_READY, language), id: 2, value: NOT_READY },
];

export const getProSelectionOptions = (language: string) => [
  { label: getString(ALL_PLANS, language), id: 0, value: ALL_PLANS },
  { label: getString(ALL_PRO_OR_TILL, language), id: 1, value: ALL_PRO_OR_TILL },
  { label: getString(PRO_ONLY, language), id: 2, value: PRO_ONLY },
  { label: getString(TILL_ONLY, language), id: 3, value: TILL_ONLY },
  { label: getString(NON_PRO_TILL, language), id: 4, value: NON_PRO_TILL },
];

export const getPlanCompletenessOptions = (language: string) => [
  { label: getString(SAMPLES_NOT_COMPLETE, language), value: SAMPLES_NOT_COMPLETE },
  { label: getString(EMI_NEEDED_NOT_COMPLETE, language), value: EMI_NEEDED_NOT_COMPLETE },
  { label: getString(ADCP_NEEDED_NOT_COMPLETE, language), value: ADCP_NEEDED_NOT_COMPLETE },
];

export const isPlanCancelled = (plan: SamplingPlanType) => plan.sampling_status === CANCELLED;
export const isPlanCompleted = (plan: SamplingPlanType) => plan.sampling_status === COMPLETED;
export const isPlanCreated = (plan: SamplingPlanType) => plan.sampling_status === CREATED;
export const sortPlansByDate = (plans: SamplingPlanType[]) => sortByCreatedAt(plans);

export const getMonthString = (month: number) => {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    default:
      return 'Dec';
  }
};

export const getPlanName = (plan: SamplingPlanType | SamplePlanTrackingType) => {
  const planDate = localDate((plan as SamplingPlanType).sampled_at || plan.created_at);
  const month = getMonthString(planDate.getUTCMonth());
  const year = planDate.getUTCFullYear();
  const day = planDate.getUTCDate();
  return `${month} ${day}, ${year}`;
};

export const getRecentDateByStatus = (
  samples: SampleType[],
  status: SamplingPlanType['sampling_status'],
): string | null => {
  // Map from sampling status to sample attribute
  const dateAttributes = {
    created: 'created_at',
    sampled: 'sampled_at',
    received: 'received_at',
    partial: 'processed_at',
    completed: 'processed_at',
    cancelled: 'created_at',
    ready: 'updated_at',
    unassigned: 'updated_at',
  };
  const samplesByStatus = samples.filter(
    (sample) => sample[dateAttributes[status] as DateAttributesType],
  );

  const lastSample = sortByDateAttribute(
    samplesByStatus,
    dateAttributes[status] as DateAttributesType,
  )[0];
  if (!lastSample) {
    return null;
  }
  return lastSample[dateAttributes[status] as Partial<keyof SampleType>] as string | null;
};

export const getSortedAndActivePlans = (fieldProps: FieldPropertiesType) => {
  return sortPlansByDate(fieldProps.sampling_plans || []).filter((p) => !isPlanCancelled(p));
};

export const getSortedPlansWithResults = (
  fieldProps: FieldPropertiesType,
  analyticCategories: string[],
) => {
  return sortPlansByDate(fieldProps.sampling_plans || []).filter((p) =>
    hasResultsByCategory(p, analyticCategories),
  );
};

export const hasResultsByCategory = (
  samplingPlan: SamplingPlanType,
  analyticCategories: string[],
) =>
  !isPlanCreated(samplingPlan) &&
  analyticCategories.some((category) => {
    const results = samplingPlan.analytics?.[category];
    return results && Object.keys(results).length;
  });

export const getAllPlansWithResultsForFields = (
  fieldPropsArray: FieldPropertiesType[],
  analyticCategories: string[],
) => {
  return fieldPropsArray.flatMap((fieldProps) =>
    sortPlansByDate(fieldProps.sampling_plans || []).filter((p) =>
      hasResultsByCategory(p, analyticCategories),
    ),
  );
};

export const getLastSamplingPlan = (fieldProps: FieldPropertiesType): SamplingPlanType | null => {
  const [lastSamplingPlan] = getSortedAndActivePlans(fieldProps);
  return lastSamplingPlan;
};

// Mainly for plans that were done twice in the same year (Earth Optics)
export const getSamplingPlanWithResultsForAnalytics = (
  plans: SamplingPlanType[] | undefined,
  analyticIds: number[],
) =>
  plans?.find((plan) => {
    const allPlanAnalytics = Object.keys(plan.analytics)
      .map((key) => Object.keys(plan.analytics[key]))
      .flat(1);
    return allPlanAnalytics?.some((key) => analyticIds.includes(Number(key)));
  });

export const getLastSamplingPlanWithResults = (
  fieldProps: FieldPropertiesType,
  analyticCategories: string[],
): SamplingPlanType | null => {
  const [lastSamplingPlan] = getSortedPlansWithResults(fieldProps, analyticCategories);
  return lastSamplingPlan;
};

export const isAgronomicOption = (creationOption: string) =>
  [SSURGO_ZONES, DEM_ZONES].includes(creationOption);
export const isGridsOption = (creationOption: string) =>
  [GRID_ZONES, GRID_POINTS].includes(creationOption);
export const isUploadOption = (creationOption: string) => creationOption === UPLOAD_ZONES;
export const isCustomPointsOption = (creationOption: string) => creationOption === CUSTOM_POINTS;
export const isPointsOption = (creationOption: string) =>
  [CUSTOM_POINTS, GRID_POINTS].includes(creationOption);

export const unlockingButtonText = (
  creationOption: string,
  isLoading: boolean,
  language: string,
) => {
  if (isAgronomicOption(creationOption)) {
    return isLoading
      ? `${getString('generating', language)}...`
      : getString('reselectDensity', language);
  }
  if (isUploadOption(creationOption) && isLoading) {
    return `${getString('uploading', language)}...`;
  }
  if (isGridsOption(creationOption)) {
    return getString('editZones', language);
  }
  return getString('resetMap', language);
};

export const lockingButtonText = (creationOption: string, isLoading: boolean, language: string) => {
  if (isAgronomicOption(creationOption)) {
    return isLoading
      ? `${getString('generating', language)}...`
      : getString('generateZones', language);
  }
  if (isUploadOption(creationOption)) {
    return getString('upload_zones', language);
  }
  return getString('lockZones', language);
};

export const getStatusDate = (
  samplingPlan: SamplingPlanType,
  status: SamplingPlanType['sampling_status'],
) => {
  if (samplingPlan) {
    const lastDate = getRecentDateByStatus(samplingPlan.samples, status);
    return lastDate ? formatCalendarMonthDayYear(lastDate) : '';
  }
  return NOT_APPLICABLE;
};

export const getIncompleteProducts = (
  selectedPackages: { [product: string]: string[] },
  totalZones: number,
) =>
  Object.keys(selectedPackages).filter((product) => {
    const uuidCount = selectedPackages[product].length;
    return uuidCount > 0 && uuidCount < totalZones;
  });

export const hasSoilAttributes = (samplingPlan: SamplingPlanType) =>
  Object.keys(samplingPlan.analytics[SOIL_ATTRIBUTES]).length;

export const isPointBased = (samplingPlan: SamplingPlanType) =>
  samplingPlan.zone_type && POINT_BASED.includes(samplingPlan.zone_type);

export const pricePerAcre = (totalPrice: number, acreage: number) => totalPrice / acreage;

export const getDensity = (zones: number, acreage: number) =>
  zones ? Number((acreage / zones).toFixed(1)) : 0;

export const getPriceDisplay = (prod: string, totalPrice: number) => {
  const price = roundTwoDecimal(totalPrice) || 0;
  if (prod.includes(DISCOUNT)) {
    return `-$${price * -1}`;
  }
  return `$${price}`;
};

export const countryTabs = (language: string) => [
  {
    displayName: 'USA',
    value: US,
    label: 'USA',
  },
  {
    displayName: getString('all', language),
    value: ALL,
    label: getString('all', language),
  },
  {
    displayName: 'BR',
    value: BR,
    label: 'BR',
  },
];

export const mergeAnalysisProZones = (
  currentZonesFeatureCollection: ZoneAnalysisStateTypeV2['plan']['zones'],
  newZonesFeatureCollection: FeatureCollection<MultiPolygon | Polygon | Point>,
) =>
  featureCollection([
    ...(currentZonesFeatureCollection?.features || []),
    ...(newZonesFeatureCollection?.features.map((feat, idx) =>
      processSingleZoneFeature(
        feature(convertToDisplayPoint(feat as Feature<Polygon>)),
        idx,
        ZONE_TYPES.POINT,
      ),
    ) || []),
  ]);

const getProductsForAnalyis = (
  products: string[],
  analysis: ZoneAnalysisStateTypeV2['plan'],
  feat: Feature<Polygon | Point>,
) => {
  if (analysis.isProScan && feat.properties?.zone_type === ZONE_TYPES.POINT) {
    return analysis.products.includes(NITRATE_PANEL)
      ? [NUTRIENT_PANEL, NITRATE_PANEL]
      : [NUTRIENT_PANEL];
  }
  if (
    analysis.isTillRx &&
    !analysis.products.length &&
    feat.properties?.zone_type === ZONE_TYPES.POINT
  ) {
    return [];
  }
  if (analysis.isProScan) {
    return products.filter((product) => ![NUTRIENT_PANEL, NITRATE_PANEL].includes(product));
  }
  if (analysis.isTillRx && feat.properties?.zone_type === ZONE_TYPES.POINT) {
    return products.includes(NUTRIENT_PANEL)
      ? products.filter((product) => [NUTRIENT_PANEL, NITRATE_PANEL].includes(product))
      : [];
  }
  return products;
};

export const setupSamplesToSubmitV2 = (
  drawnFeatures: FeatureCollection<Polygon | Point, GeoJsonProperties>,
  analysis: ZoneAnalysisStateTypeV2['plan'],
  field: FieldType,
) =>
  drawnFeatures?.features
    .filter(
      (feat) =>
        feat.properties?.zone_type !== ZONE_TYPES.POINT ||
        booleanPointInPolygon(center(feat.geometry), field.features[0]),
    )
    .map((feat) => {
      const { properties } = feat;
      delete properties?.analytics;
      const isProOrTillRx = analysis.isProScan || analysis.isTillRx;
      return {
        ...feat,
        geometry: convertToPolygon(
          feat,
          isProOrTillRx ? feat.geometry.type : analysis.zoneGeomType,
        ),
        properties: {
          ...properties,
          products: getProductsForAnalyis(analysis.products, analysis, feat),
          sample_uuid: uuid(),
        },
      } as Feature<Polygon>;
    });

export const operationAllowsMultiplePlans = (operationUsers: User[] | undefined) => {
  return Boolean(
    operationUsers?.some((user) =>
      user.agencies?.some((agency) => agency.allow_multiple_plans === true),
    ),
  );
};

export const getDefaultAnalysis = (
  operation?: OperationType,
  operationUsers?: User[],
  currentTiming?: string,
  fieldGeometry?: FieldType,
): ZoneAnalysisStateTypeV2['plan'] => {
  const billingAgencyId = operation?.default_bill_to_agency_id || operation?.billing_agency_id;
  const country_code = fieldGeometry?.features[0].properties.country_code;
  const county = fieldGeometry?.features[0].properties.county;

  return {
    tempPlan: null,
    existingSamples: [],
    rnd: false,
    name: defaultPlanDate(),
    isSplitDensity: false,
    readyToSample: false,
    samplerIndex: getSamplerScannerIndexFromPlan(SELF_SAMPLING, operation, operationUsers, null),
    scannerIndex: getSamplerScannerIndexFromPlan(SELF_SCANNING, operation, operationUsers, null),
    sampleTimingIndex: getDefaultTimingOptionIndex(currentTiming),
    products: splitDensityProducts,
    analysisMode: ZONE_BY_ZONE,
    gridAngle: 0,
    disableMapTools: false,
    enableButtonSpinner: false,
    operationUsers: [],
    priceSummary: {},
    isOrderButtonDisabled: false,
    zones: null,
    previewZones: null,
    zoneGeomType: POLYGON,
    zonesLocked: false,
    isConfirmationVisible: false,
    editScanPoints: false,
    existingPlan: null,
    splitIndex: 0,
    isSplitDensityNitrate: false,
    notes: '',
    pressureVersion: country_code === BR || county?.restricted ? RKN : CRW,
    pressurePlus: false,
    isProScan: false,
    isTillRx: false,
    creationOption: GRID_ZONES,
    proPointCreationOption: GRID_POINTS,
    density: 10,
    accordionLocation: CONFIRM_ANALYSIS,
    scanDensity: 10,
    agencyId: billingAgencyId,
    zoneCreationType: ANALYSIS_TYPES.CREATION_OPTION,
    is590Analysis: false,
    nutrientExternalLabIdentifier: null,
  };
};

export const resetPreviewGrid = (
  creationType: string,
  density: number,
  fieldGeometry: FieldType,
) => {
  const { geometry, properties } = fieldGeometry.features[0];
  const cellWidth = acresToFeetSideLength(haToAcres(density, properties.acreage_unit));
  const units = 'feet';

  const bufferedBbox = turfBbox(buffer(geometry, cellWidth * 2, { units }));
  if (creationType === GRID_POINTS) {
    const gridPoints = pointGrid(bufferedBbox, cellWidth, { units });
    return processZoneFeatureCollection(gridPoints, POINT) as FeatureCollection<Point>;
  }
  const gridPolys = squareGrid(bufferedBbox, cellWidth, { units });
  return processZoneFeatureCollection(gridPolys, POLYGON) as FeatureCollection<Polygon>;
};

export const prepareResetZones = (
  previewZones: FeatureCollection<Polygon | Point> | null,
  density: number,
  creationOption: string | CreationOption,
  fieldGeometry: FieldType,
) => {
  return {
    previewZones: isGridsOption(creationOption)
      ? resetPreviewGrid(creationOption, density, fieldGeometry)
      : previewZones,
    zoneGeomType: creationOption.toLowerCase().includes(ZONE_TYPES.POINT) ? POINT : POLYGON,
  };
};

export const getNewAnalysisFromUpdate = (
  analysis: ZoneAnalysisStateTypeV2['plan'],
  fieldGeometry: FieldType,
) => {
  const { products, isProScan, isTillRx } = analysis;
  const isProOrTillRx = isProScan || isTillRx;

  const existingZones = analysis.existingSamples.reduce(
    (zonesPoints: { points: SampleGeoJSON[]; polygons: SampleGeoJSON[] }, sample) => {
      if (
        sample.properties.zone_type === ZONE_TYPES.POINT ||
        (!sample.properties.zone_type && isPointZone(sample))
      ) {
        return { ...zonesPoints, points: [...zonesPoints.points, sample] };
      }
      return { ...zonesPoints, polygons: [...zonesPoints.polygons, sample] };
    },
    { points: [], polygons: [] },
  );

  const isNutrientOnly = isNutrientPanel(products);
  const noBioZones = !products.includes(PRESSURE_PANEL) && !products.includes(PERFORMANCE_PANEL);

  const noPanels = !isNutrientOnly && noBioZones;

  const isNutrientOnlyOrTillRxNoBio = isNutrientOnly || (isTillRx && noPanels);
  if (isProOrTillRx) {
    if (isNutrientOnlyOrTillRxNoBio && existingZones.points.length) {
      return {
        ...analysis,
        creationOption: GRID_POINTS,
        zoneGeomType: POINT,
        zones: featureCollection(existingZones.points),
        previewZones: null,
        zonesLocked: true,
      };
    }
    if (!isNutrientOnlyOrTillRxNoBio && existingZones.polygons.length) {
      return {
        ...analysis,
        creationOption: GRID_ZONES,
        zoneGeomType: POLYGON,
        zones: featureCollection(existingZones.polygons),
        previewZones: null,
        zonesLocked: true,
      };
    }
    if ((!isTillRx && isNutrientOnly) || (isTillRx && noPanels)) {
      return {
        ...analysis,
        creationOption: GRID_POINTS,
        zoneGeomType: POINT,
        zones: existingZones.points.length ? featureCollection(existingZones.points) : null,
        previewZones: !existingZones.points.length
          ? resetPreviewGrid(GRID_POINTS, analysis.density, fieldGeometry)
          : null,
        zonesLocked: true,
      };
    }
    return {
      ...analysis,
      creationOption: noBioZones ? GRID_POINTS : GRID_ZONES,
      zoneGeomType: noBioZones ? POINT : POLYGON,
      zones: null,
      previewZones: resetPreviewGrid(
        noBioZones ? GRID_POINTS : GRID_ZONES,
        analysis.density,
        fieldGeometry,
      ),
      zonesLocked: false,
      editScanPoints: true,
    };
  }
  if (analysis.existingPlan) {
    const newCreation = analysis.existingPlan.zone_type || GRID_ZONES;
    return {
      ...analysis,
      creationOption: newCreation,
      zones: getZonesBasedOnAnalysis(
        analysis.isProScan || analysis.isTillRx,
        analysis.existingPlan,
        analysis.existingSamples,
      ),
      zoneGeomType: newCreation.includes(ZONE_TYPES.POINT) ? POINT : POLYGON,
      previewZones: null,
      zonesLocked: true,
    };
  }
  return {
    ...analysis,
    creationOption: GRID_ZONES,
    zones: null,
    zonesLocked: false,
    ...prepareResetZones(analysis.previewZones, analysis.density, GRID_ZONES, fieldGeometry),
  };
};

export const canAddAllUuidsToAnalysis = (fieldGeometry: FieldType, product: string) => {
  const { properties } = fieldGeometry.features[0];
  if (properties.county?.restricted && [NUTRIENT_PANEL, NITRATE_PANEL].includes(product)) {
    return false;
  }
  return true;
};

export const getSplitDensityOptions = (totalZones: number) =>
  splitDensities
    .reduce((list, percent) => {
      const split = Math.floor(percent * totalZones);
      return split && !list.includes(split) ? list.concat([split]) : list;
    }, [] as number[])
    .map((split, index) => {
      return {
        id: index,
        displayName: split,
        value: split,
      };
    });

export const getZoneType = (sample: SampleGeoJSON, samplingPlan: SamplingPlanType) => {
  if (sample.properties?.zone_type) {
    return sample.properties.zone_type;
  }
  if (isPointBased(samplingPlan)) {
    return ZONE_TYPES.POINT;
  }
  return ZONE_TYPES.POLYGON;
};

export const getPerProduct = (
  fieldGeometry: FieldType,
  uncancelledSamples: SampleGeoJSON[],
  existingPlan: SamplingPlanType,
) => {
  const polygonGeometries = uncancelledSamples
    .filter((sample: SampleGeoJSON) =>
      sample.geometry?.geometries.find((geom) => geom.type === POLYGON),
    )
    .map((sample: SampleGeoJSON) => ({
      ...sample,
      geometry: sample.geometry?.geometries.find((geom) => geom.type === POLYGON),
      properties: {
        products: sample.properties.products.filter(
          (product) =>
            existingPlan.is_pro ||
            existingPlan.is_till_rx ||
            canAddAllUuidsToAnalysis(fieldGeometry, product),
        ),
        sample_grouping_id: sample.properties.sample_grouping_id,
        sample_uuid: sample.properties.sample_uuid, // we create a new uuid on submit
        id: sample.properties.id,
        field_id: sample.properties.field_id,
        sampling_plan_id: sample.properties.sampling_plan_id,
        status: sample.properties.status,
        zone_type: getZoneType(sample, existingPlan),
      },
    })) as SampleGeoJSON[];

  const existingZones = featureCollection(
    polygonGeometries.map((sample: SampleGeoJSON, index: number) => ({
      ...sample,
      geometry:
        getZoneType(sample, existingPlan) === ZONE_TYPES.POINT
          ? // @ts-ignore
            center(sample.geometry).geometry
          : sample.geometry,
      properties: {
        ...sample.properties,
        zone_number: index + 1,
        sample_group: sample.properties.sample_grouping_id,
        zone_type: getZoneType(sample, existingPlan),
      },
    })),
  ) as FeatureCollection<Polygon | Point>;

  return {
    zones: existingZones,
    analysisMode: existingZones.features.some((sample) => sample.properties?.sample_group)
      ? SPLIT_DENSITY
      : ZONE_BY_ZONE,
  };
};

const getPlanDetailsFromExisting = async (
  plan: SamplingPlanType,
  sampleFeatures: SampleGeoJSON[],
  fieldGeometry: FieldType,
  analysis: ZoneAnalysisStateTypeV2['plan'],
) => {
  const uncancelledSamples = sampleFeatures.filter(
    (sample: SampleGeoJSON) => sample.properties.status !== SAMPLE_STATUSES.CANCELLED,
  );

  const polygonGeometries = getSamplePolygonGeometries(uncancelledSamples, fieldGeometry, plan);
  analysis.existingSamples = polygonGeometries;

  analysis.zoneGeomType = isPointBased(plan) ? POINT : POLYGON;

  let existingZones = getZonesForDisplay(polygonGeometries, plan);
  let splitDensityIdx = analysis.splitIndex;
  let { disableMapTools } = analysis;

  if (plan.plan_type === SPLIT_DENSITY) {
    const numSplitGroups = plan.samples.filter((sample) => sample.biological_subsample).length;
    const actualSamplesTaken = plan.samples.filter((sample) => !sample.biological_subsample).length;
    const splitDensityOptions = getSplitDensityOptions(actualSamplesTaken);
    splitDensityIdx = splitDensityOptions.findIndex((option) => option.value === numSplitGroups);
    const groupedZones = await postDensityGroupings(
      splitDensityOptions[splitDensityIdx].value,
      featureCollection(existingZones.features),
    );
    existingZones = groupedZones as FeatureCollection<Polygon | Point>;
    disableMapTools = true;
  }

  const updatedPlan: ZoneAnalysisStateTypeV2['plan'] = {
    ...analysis,
    analysisMode: plan.plan_type,
    disableMapTools: disableMapTools || plan.is_pro || plan.is_till_rx,
    products: plan.products,
    zones: existingZones,
    isProScan: plan.is_pro,
    isTillRx: plan.is_till_rx,
    splitIndex: splitDensityIdx || 0,
    nutrientExternalLabIdentifier: plan.nutrient_external_lab_identifier,
  };

  return updatedPlan;
};

export const getSamplePolygonGeometries = (
  samples: SampleGeoJSON[],
  fieldGeometry: FieldType,
  samplingPlan: SamplingPlanType,
) =>
  samples
    .filter((sample: SampleGeoJSON) =>
      sample.geometry?.geometries.find((geom) => geom.type === POLYGON),
    )
    .map((sample: SampleGeoJSON) => ({
      ...sample,
      geometry: sample.geometry?.geometries.find((geom) => geom.type === POLYGON),
      properties: {
        products: sample.properties.products.filter((product) =>
          canAddAllUuidsToAnalysis(fieldGeometry, product),
        ),
        sample_grouping_id: sample.properties.sample_grouping_id,
        sample_uuid: sample.properties.sample_uuid, // we create a new uuid on submit
        id: sample.properties.id,
        field_id: sample.properties.field_id,
        sampling_plan_id: sample.properties.sampling_plan_id,
        status: sample.properties.status,
        zone_type: getZoneType(sample, samplingPlan),
      },
    })) as SampleGeoJSON[];

export const getZonesForDisplay = (samples: SampleGeoJSON[], plan: SamplingPlanType) =>
  featureCollection(
    samples.map((sample: SampleGeoJSON, index: number) => ({
      ...sample,
      geometry:
        getZoneType(sample, plan) === ZONE_TYPES.POINT
          ? // @ts-ignore
            center(sample.geometry).geometry
          : sample.geometry,
      properties: {
        ...sample.properties,
        zone_number: index + 1,
        sample_group: sample.properties.sample_grouping_id,
        zone_type: getZoneType(sample, plan),
      },
    })),
  ) as FeatureCollection<Polygon | Point>;

export const setupCompletedAnalysis = async (
  operation: OperationType,
  operationUsers: User[],
  fieldGeometry: FieldType,
  completedPlan: SamplingPlanType,
  currentTiming: string,
) => {
  let newAnalyis = getDefaultAnalysis(operation, operationUsers, currentTiming, fieldGeometry);

  newAnalyis = {
    ...newAnalyis,
    creationOption: completedPlan.zone_type || GRID_ZONES,
    name: defaultPlanDate(),
    rnd: completedPlan.rnd,
  };

  const samples = await getSamples(fieldGeometry.features[0].properties.id);

  newAnalyis = await getPlanDetailsFromExisting(
    completedPlan,
    samples.features.filter((sample) => sample?.properties?.sampling_plan_id === completedPlan.id),
    fieldGeometry,
    newAnalyis,
  );

  return {
    ...newAnalyis,
    existingPlan: completedPlan,
    zonesLocked: true,
    samplerIndex: getSamplerScannerIndexFromPlan(
      SELF_SAMPLING,
      operation,
      operationUsers,
      completedPlan.assigned_to_id,
    ),
    scannerIndex: getSamplerScannerIndexFromPlan(
      SELF_SCANNING,
      operation,
      operationUsers,
      completedPlan.assigned_to_scan_id,
    ),
    pressureVersion: samples.features.some(
      (sample) => sample?.properties?.pressure_panel_analyze_rkn,
    )
      ? RKN
      : CRW,
  };
};

export const getSamplerScannerIndexFromPlan = (
  sampleOrScan: typeof SELF_SAMPLING | typeof SELF_SCANNING,
  operation?: OperationType,
  operationUsers?: User[],
  assignedId?: number | null,
) => {
  if (!operationUsers?.length || !operation) {
    return 0;
  }
  const userOptions =
    sampleOrScan === SELF_SAMPLING
      ? getSamplerOptions({ operationUsers })
      : getScannerOptions({ operationUsers });
  const assignedUserIdx = userOptions.findIndex((user) => user.id === assignedId) + 1;
  const { billing_agency_id, billing_user_id } = operation;
  if (assignedId || !billing_agency_id) {
    return assignedUserIdx;
  }
  const agencyUserDefaultIdx =
    userOptions.findIndex(
      (user) => user.id === billing_user_id && user.agencies?.[0]?.[sampleOrScan],
    ) + 1;
  return agencyUserDefaultIdx;
};

export const setupExistingAnalysis = async (
  operation: OperationType,
  operationUsers: User[],
  fieldGeometry: FieldType,
  existingPlan: SamplingPlanType,
  currentTiming: string,
  timings: TimingsType[],
) => {
  const defaultAnalysis = getDefaultAnalysis(
    operation,
    operationUsers,
    currentTiming,
    fieldGeometry,
  );

  const sampleResponse = await getActiveSamples(fieldGeometry.features[0].properties.id);
  const { samples } =
    sampleResponse.sampling_plans.find((sp) => sp.id === existingPlan.id) ||
    sampleResponse.sampling_plans[0];

  const scannerIdx = existingPlan.assigned_to_scan_id
    ? getScannerOptions(operation).findIndex((user) => user.id === existingPlan.assigned_to_scan_id)
    : 0;

  const analysisFromExisting = await getPlanDetailsFromExisting(
    existingPlan,
    samples.features,
    fieldGeometry,
    defaultAnalysis,
  );
  return {
    ...defaultAnalysis,
    ...getPerProduct(fieldGeometry, samples.features, existingPlan),
    ...analysisFromExisting,
    existingPlan,
    name: existingPlan.name,
    rnd: existingPlan.rnd,
    notes: existingPlan.notes || '',
    zonesLocked: true,
    agencyId: existingPlan.agency_id,
    sampleTimingIndex:
      timings.findIndex((timing) => timing.value === existingPlan.sample_timing) || 0,
    isSplitDensityNitrate: existingPlan.products.includes(NITRATE_PANEL),
    samplerIndex: operationUsers.findIndex((user) => user.id === existingPlan.assigned_to_id) + 1,
    scannerIndex: scannerIdx,
    pressureVersion: samples.features.some((sample) => sample.properties.pressure_panel_analyze_rkn)
      ? RKN
      : CRW,
    readyToSample: existingPlan.ready_to_sample,
    creationOption: existingPlan.zone_type || GRID_ZONES,
    zoneGeomType: isPointBased(existingPlan) ? POINT : POLYGON,
    is590Analysis: (samples.features || []).some((sample) => sample.properties?.is_590_analysis),
  };
};

export const getZonesBasedOnAnalysis = (
  isProOrTillRx: boolean,
  existingPlan: ZoneAnalysisStateTypeV2['plan']['existingPlan'],
  existingSamples: ZoneAnalysisStateTypeV2['plan']['existingSamples'],
) => {
  if (!isProOrTillRx && existingPlan) {
    const existingZones = existingSamples.filter(
      (sample) => sample.properties?.zone_type !== ZONE_TYPES.POINT,
    );
    const existingPointZones = existingSamples.filter(
      (sample) => sample.properties?.zone_type === ZONE_TYPES.POINT,
    );
    return getZonesForDisplay(
      existingZones.length ? existingZones : existingPointZones,
      existingPlan,
    );
  }
  if (isProOrTillRx && existingPlan && !isPointBased(existingPlan)) {
    return getZonesForDisplay(existingSamples, existingPlan);
  }
  return null;
};

export const getSamplerOptions = (operation: { operationUsers?: User[] }) =>
  operation.operationUsers?.map((user) => ({
    ...user,
    id: user.id,
    label: getWhiteLabelTranslation(joinStrings([user.first_name, user.last_name], ' ')),
    value: String(user.id),
    can_scan: user.can_scan,
  })) || [];

export const getScannerOptions = (operation: { operationUsers?: User[] }) =>
  operation.operationUsers
    ?.filter((user) => user.id === 0 || user.agencies?.[0]?.self_scanning)
    .map((user) => ({
      ...user,
      id: user.id,
      label: getWhiteLabelTranslation(joinStrings([user.first_name, user.last_name], ' ')),
      value: String(user.id),
      can_scan: true,
    })) || [];

export const getMapSelectedUuids = (mapRef: any) => {
  try {
    // dangling _ otherwise, just catch it and return []
    const { _data } = mapRef.current?.getSource(ZONE_SELECTED) as any;
    return (_data as FeatureCollection).features.reduce(
      (list, feat) =>
        feat.properties?.sample_uuid ? [...list, feat.properties?.sample_uuid] : list,
      [] as string[],
    );
  } catch (err) {
    return [];
  }
};

export const isProScanPoint = (
  sample: Feature<Polygon | Point, GeoJsonProperties>,
  isProScan: boolean,
) => sample.properties?.zone_type === ZONE_TYPES.POINT && isProScan;

export const getZoneCreationOptions = (language: string, isProScan?: boolean) =>
  [
    {
      id: 2,
      label: getString(SSURGO_ZONES, language),
      value: SSURGO_ZONES,
    },
    {
      id: 4,
      label: getString(GRID_ZONES, language),
      value: GRID_ZONES,
    },
    {
      id: 5,
      label: getString(GRID_POINTS, language),
      value: GRID_POINTS,
    },
    {
      id: 6,
      label: getString(CUSTOM_POINTS, language),
      value: CUSTOM_POINTS,
    },
    {
      id: 1,
      label: getString(UPLOAD_ZONES, language),
      value: UPLOAD_ZONES,
    },
  ].filter((option) => !isProScan || ![GRID_POINTS, CUSTOM_POINTS].includes(option.value));

export const getProPointCreationOptions = (language: string) => [
  {
    label: getString(GRID_POINTS, language),
    value: GRID_POINTS,
  },
  {
    label: getString(CUSTOM_POINTS, language),
    value: CUSTOM_POINTS,
  },
];

export const getProScanGrid = (analysis: ZoneAnalysisStateTypeV2['plan']) => {
  const { isProScan, existingPlan, products } = analysis;
  if (!isProScan && existingPlan?.zone_type) {
    return existingPlan.zone_type;
  }
  if (isProScan && isNutrientPanel(products)) {
    return GRID_POINTS;
  }
  return GRID_ZONES;
};

export const getTargetNumberOfZones = (acreage: number, density: number) => {
  return Math.round((Math.floor(acreage / density) * density) / density) || 1;
};

export const getBioZonesCount = (
  isProOrTillRx: boolean,
  zones: ZoneAnalysisStateTypeV2['plan']['zones'],
) => {
  if (isProOrTillRx) {
    return zones?.features.filter((feat) => feat.properties?.zone_type === ZONE_TYPES.POLYGON)
      .length;
  }
  return zones?.features.length;
};

export const getBioZonesDensity = (
  isProOrTillRx: boolean,
  zones: ZoneAnalysisStateTypeV2['plan']['zones'],
  field: FieldType,
) => {
  const bioCount = getBioZonesCount(isProOrTillRx, zones);
  return bioCount ? getDensity(bioCount, field.features[0].properties.acreage) : 0;
};

export const getProNutrientTillRxPointsCount = (
  isProOrTillRx: boolean,
  zones: ZoneAnalysisStateTypeV2['plan']['zones'],
) => {
  if (isProOrTillRx) {
    return (
      zones?.features.filter((feat) => feat.properties?.zone_type === ZONE_TYPES.POINT).length || 0
    );
  }
  return 0;
};

// const MIN_TILL_MAPPER_DENSITY = 8;
const MIN_DENSITY_PRO = 10.5;
const MIN_COUNT_PRO = 4;

export const getSubmitPointsMsg = (
  isProScan: ZoneAnalysisStateTypeV2['plan']['isProScan'],
  isTillRx: ZoneAnalysisStateTypeV2['plan']['isTillRx'],
  zones: ZoneAnalysisStateTypeV2['plan']['zones'],
  field: FieldType,
) => {
  if (isProScan) {
    const isProOrTillRx = isProScan || isTillRx;
    const proTillCount = getProNutrientTillRxPointsCount(isProOrTillRx, zones);

    if (proTillCount < MIN_COUNT_PRO) {
      return 'minimalProPointsMsg';
    }
    if (getProNutrientDensity(isProOrTillRx, zones, field) > MIN_DENSITY_PRO) {
      return 'minimalNumberPointsMsg';
    }
  }
  return null;
};

export const getProNutrientDensity = (
  isProOrTillRx: boolean,
  zones: ZoneAnalysisStateTypeV2['plan']['zones'],
  field: FieldType,
) => {
  const proTillCount = getProNutrientTillRxPointsCount(isProOrTillRx, zones);
  return proTillCount ? getDensity(proTillCount, field.features[0].properties.acreage) : 0;
};

export const mergePlanAnalytics = (
  selectedPlan: SamplingPlanType | undefined,
  secondPlan: SamplingPlanType | undefined,
) => {
  if (!selectedPlan) {
    return {};
  }
  if (!secondPlan) {
    return selectedPlan?.analytics;
  }

  return Object.keys(selectedPlan.analytics).reduce((all, key) => {
    all[key] = {
      ...secondPlan.analytics[key],
      ...selectedPlan.analytics[key],
    };
    return all;
  }, {});
};

/**
 * IMPORTANT: keep this list up to date as new nutrient layers arrive, otherwise there will not be a
 * Pro layer available for the new nutrient.
 */
const getProNutrientLayerNameByAnalyticId = (id: number): string | undefined => {
  if (id === PH_ID) {
    return 'ph';
  }
  if (id === CEC_ID) {
    return 'cec';
  }
  if (id === SULFUR_ID) {
    return 'sulfur';
  }
  if (id === NITROGEN_ID) {
    return 'nitrogen';
  }
  if (bufferPhIds.includes(id)) {
    return 'buffer_ph';
  }
  if (potassiumIds.includes(id)) {
    return 'potassium';
  }
  if (calciumIds.includes(id)) {
    return 'calcium';
  }
  if (magnesiumIds.includes(id)) {
    return 'magnesium';
  }
  if (boronIds.includes(id)) {
    return 'boron';
  }
  if (ironIds.includes(id)) {
    return 'iron';
  }
  if (manganeseIds.includes(id)) {
    return 'manganese';
  }
  if (copperIds.includes(id)) {
    return 'copper';
  }
  if (zincIds.includes(id)) {
    return 'zinc';
  }
  if (phosphorusIds.includes(id)) {
    return 'phosphorus';
  }
};

export const getProNutrientMapLayer = (
  samplePlan: SamplingPlanType,
  activeAnalytic: AnalyticType,
) => {
  if (!(samplePlan.is_pro && PRO_NUTRIENT_ANALYTIC_IDS.includes(activeAnalytic.id))) {
    return null;
  }

  const layerName = getProNutrientLayerNameByAnalyticId(activeAnalytic.id);

  if (layerName) {
    return samplePlan.nutrient_layers.find((layer) => layer.layer_name === `${layerName}_polys`);
  }
};

export const getAnalysisOverviewFromSummary = (summary: PriceSummaryType) => {
  return Object.keys(summary).reduce(
    (all, key) => {
      const { total, num_zones } = summary[key];
      return {
        total: all.total + total,
        num_zones:
          num_zones > all.num_zones && !PER_ACRE_SAMPLING_KEYS.includes(key)
            ? num_zones
            : all.num_zones,
      };
    },
    { total: 0, num_zones: 0 },
  );
};

const MIN_DENSITY_590_WI = 5;
const MIN_DENSITY_590_IA_MN = 10;

export const is590DensityValid = (
  is590Analysis: ZoneAnalysisStateTypeV2['plan']['is590Analysis'],
  zones: ZoneAnalysisStateTypeV2['plan']['zones'],
  isProScan: ZoneAnalysisStateTypeV2['plan']['isProScan'],
  field: FieldType,
) => {
  if (is590Analysis && zones) {
    const { acreage, state } = field.features[0].properties;
    const targetDensity = state === WISCONSIN ? MIN_DENSITY_590_WI : MIN_DENSITY_590_IA_MN;

    const nutrientZones = isProScan
      ? zones.features.filter((zone) => zone.properties?.zone_type === ZONE_TYPES.POINT)
      : zones.features;
    const planDensity = getDensity(nutrientZones.length, acreage);

    return planDensity <= targetDensity;
  }
  return true;
};
