import { GroupedCollectionsListPayload } from 'store/eoCollections/types';
import { SkuItemPayload } from 'store/pricing/types';

export const DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS: GroupedCollectionsListPayload = {
  groupBy: 'operation',
  sortBy: 'name',
  page: 1,
  status: 'pending',
};

export const samplingPlansQueryKeys = {
  externalLab: (samplingPlanId: number) => ['externalLab', samplingPlanId],
  activeSamplingPlans: (fieldId: number) => ['activeSamplingPlans', fieldId],
  sampleTimings: ['sampleTimings'],
};

export const fieldsQueryKeys = {
  field: (fieldId: number) => ['field', fieldId],
};

export const operationsQueryKeys = {
  operations: ['operations'],
  operation: (operationId: number) => [...operationsQueryKeys.operations, operationId],
  operationUsers: (operationId: number) => ['operationUsers', operationId],
};

export const agenciesQueryKeys = {
  operationAgencies: ['operationAgencies'],
};

export const dataReviewQueryKeys = {
  groupedCollections: () => ['groupedCollections'],
  filteredGroupedCollections: (params = DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS) => [
    ...dataReviewQueryKeys.groupedCollections(),
    { ...params },
  ],
  collectionsByField: (fieldId: number, status: GroupedCollectionsListPayload['status']) => [
    ...dataReviewQueryKeys.groupedCollections(),
    fieldId,
    status,
  ],
};

export const pricingQueryKeys = {
  allSkus: ['allSkus'],
  defaultPricing: ['defaultPricing'],
  externalLabs: ['externalLabs'],
  contractsByAgency: (agencyId: string) => ['contractsByAgency', agencyId],
  priceEstimate: (estimatePayload: {
    fieldId: number;
    skuPrices: SkuItemPayload[];
    isSplitDensity: boolean;
    sampledById: number;
    scannedById: number;
    primaryProductId: number;
    nutrientAnalysisPackageId: number | null;
  }) => ['priceEstimate', estimatePayload],
};

export const resultsQueryKeys = {
  /** List of available layers */
  samplePlanTillageLayers: (samplingPlanId?: number) => ['tillageLayers', samplingPlanId],
};
