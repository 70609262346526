import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Menu, Button } from '@mantine/core';
import { FiAlertCircle } from 'react-icons/fi';
import { SamplingPlanType } from 'store/fields/types';
import { getFieldGeometry } from 'store/fields/thunks';
import { AdminAccess } from 'common';
import showToast, { type ToastType } from 'actions/toastActions';
import { downloadFieldShapefile } from 'store/fields/requests';
import { CREATED, PARTIALLY_SAMPLED, READY, UNASSIGNED } from 'constants/samplePlanning';
import { deleteSamplePlan, putSamplePlan } from 'store/samplePlans/requests';
import { userCanEditPlans } from 'store/user/selectors';
import { RootState } from 'store';
import { updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';
import CancelModal from './CancelModal';
import { operationAllowsMultiplePlans } from 'util/samplePlan';

import styles from './Actions.module.css';
import LabIntakeMenuItem from './LabIntakeMenuItem';

type ActionsPropsType = {
  operationId: number;
  fieldId?: number;
  samplingPlan: SamplingPlanType | null;
  pastSamplingPlans?: SamplingPlanType[];
  toggleShowPastSamplingPlans?: () => void;
  hasGeom?: boolean;
  hideEdit?: boolean;
};

const Actions = ({
  operationId,
  fieldId,
  samplingPlan,
  pastSamplingPlans,
  toggleShowPastSamplingPlans,
  hasGeom,
  hideEdit,
}: ActionsPropsType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const [showCancelModal, toggleShowCancelModal] = useState(false);
  const [showMarkNotReadyModal, toggleMarkNotReadyModal] = useState(false);
  const [isLoading, toggleIsLoading] = useState(false);
  const fetchFieldGeometry = useCallback((id) => dispatch(getFieldGeometry(id)), [dispatch]);

  const { userCanEdit, timings, operationUsers, operation } = useSelector((state: RootState) => ({
    userCanEdit: userCanEditPlans(state, operationId),
    timings: state.timings.timings,
    operationUsers: state.operations.operationsById[operationId]?.operationUsers,
    operation: state.operations.operationsById[operationId],
  }));

  const setToastMessage = (message: string, type?: ToastType, timeout?: number) =>
    showToast(message, type, timeout);

  const handleSuccess = useCallback(() => {
    if (samplingPlan && samplingPlan.field_id) {
      fetchFieldGeometry(samplingPlan.field_id);
      setToastMessage(getString('samplePlanCancelledSuccess', language));
    }
  }, [samplingPlan, fetchFieldGeometry, setToastMessage, language]);

  const handleFailure = useCallback(() => {
    setToastMessage(getString('errorCancellingPlan', language), 'error');
  }, [setToastMessage, language]);

  const fieldPlans = (pastSamplingPlans || []).concat(samplingPlan || []);

  const cancelSamples = useCallback(async () => {
    if (!samplingPlan) {
      return;
    }

    try {
      toggleIsLoading(true);
      await deleteSamplePlan(samplingPlan.id);
      handleSuccess();
    } catch (e) {
      handleFailure();
    } finally {
      toggleIsLoading(false);
    }
  }, [handleFailure, handleSuccess, samplingPlan]);

  const markNotReady = async () => {
    if (!samplingPlan) {
      return;
    }
    try {
      const body = {
        ready_to_sample: false,
      };
      toggleIsLoading(true);
      const planIds = fieldPlans.map((plan) => plan.id);
      await Promise.all(planIds.map((id) => putSamplePlan(body, id)));
      setToastMessage(getString('markNotReadySuccess', language));
      dispatch(getFieldGeometry(samplingPlan.field_id));
    } catch (e) {
      handleFailure();
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleDownload = useCallback(async () => {
    try {
      if (fieldId) {
        downloadFieldShapefile(fieldId);
      }
      setToastMessage(getString('shapefileDownloadSuccess', language), 'success');
    } catch (e) {
      setToastMessage(getString('errorDownloadingShpfile', language), 'error');
    }
  }, [fieldId, setToastMessage, language]);

  const openConfirmationModal = () => {
    if (samplingPlan) {
      const existingTiming =
        timings.findIndex((option) => option.value === samplingPlan.sample_timing) || 0;
      const samplerIndex = operationUsers?.findIndex(
        (sampler) => sampler.id === samplingPlan.assigned_to_id,
      );
      dispatch(
        updateZoneAnalysis({
          analysisMode: samplingPlan.plan_type,
          samplerIndex: samplerIndex,
          sampleTimingIndex: existingTiming,
          isConfirmationVisible: true,
          tempPlan: samplingPlan,
          priceSummary: samplingPlan.price_summary,
        }),
      );
    }
  };

  const isSampling = useMemo(() => {
    return (
      samplingPlan?.sampling_status === CREATED ||
      samplingPlan?.sampling_status === UNASSIGNED ||
      samplingPlan?.sampling_status === READY
    );
  }, [samplingPlan]);

  const isReady = isSampling && samplingPlan?.ready_to_sample;
  const canOrderAdditionalPlans = operationAllowsMultiplePlans(operation?.operationUsers);

  const hasMultipleOpenPlans = (() => {
    // don't bother checking if operation doesn't support multiple plans
    if (!canOrderAdditionalPlans) {
      return false;
    }
    const openPlans = fieldPlans.filter((plan) =>
      [CREATED, PARTIALLY_SAMPLED].includes(plan.sampling_status),
    );
    return openPlans.length > 1;
  })();

  return (
    <>
      <CancelModal
        cancelTitle={getString('cancelSamplePlan', language)}
        cancel={cancelSamples}
        isFetching={false}
        hideModal={() => toggleShowCancelModal(false)}
        opened={showCancelModal}
        isDisabled={isLoading}
      />
      <CancelModal
        cancelTitle={getString('markNotReady', language)}
        cancel={markNotReady}
        cancelText={getString('sureMarkNotReady', language)}
        isFetching={false}
        hideModal={() => toggleMarkNotReadyModal(false)}
        opened={showMarkNotReadyModal}
        isDisabled={isLoading}
      />
      <Menu shadow="md">
        <Menu.Target data-test-id="action-button">
          <Button variant="outline">
            {getString('actions', language)}
            {hasMultipleOpenPlans && <FiAlertCircle className={styles.ActionIcon} color="red" />}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {userCanEdit && !samplingPlan && hasGeom && (
            <Menu.Item
              data-test-id="actions-order-sampling-plan"
              onClick={() => {
                navigate(`/zone-analysis/${operationId}/${fieldId}`);
              }}
            >
              {getString('orderSamplePlan', language)}
            </Menu.Item>
          )}
          {userCanEdit && samplingPlan && hasGeom && (
            <>
              {!hideEdit && (
                <Menu.Item
                  data-test-id="actions-edit-sampling-plan"
                  onClick={() => {
                    navigate(
                      `/zone-analysis/${operationId}/${samplingPlan.field_id}/${samplingPlan.id}`,
                    );
                  }}
                  disabled={!operation.billing_user_id}
                >
                  {isSampling
                    ? getString('editSamplePlan', language)
                    : getString('orderSample', language)}
                </Menu.Item>
              )}
              {canOrderAdditionalPlans && (
                <Menu.Item
                  data-test-id="actions-order-additional-sampling-plan"
                  onClick={() => {
                    navigate(`/zone-analysis/${operationId}/${samplingPlan.field_id}/additional`);
                  }}
                  disabled={!operation.billing_user_id}
                >
                  {getString('orderAdditionalSamplePlan', language)}
                </Menu.Item>
              )}
              <Menu.Item data-test-id="actions-view-order-summary" onClick={openConfirmationModal}>
                {getString('viewOrderSummary', language)}
              </Menu.Item>
              {userCanEdit && isSampling && (
                <Menu.Item
                  data-test-id="actions-cancel-sampling-plan"
                  onClick={() => toggleShowCancelModal(true)}
                >
                  {getString('cancelOrder', language)}
                </Menu.Item>
              )}
              {isReady && (
                <Menu.Item
                  data-test-id="actions-mark-sampling-plan-not-ready"
                  onClick={() => toggleMarkNotReadyModal(true)}
                >
                  {getString('markNotReady', language)}
                </Menu.Item>
              )}
              {toggleShowPastSamplingPlans && (
                <Menu.Item
                  onClick={toggleShowPastSamplingPlans}
                  disabled={!pastSamplingPlans || pastSamplingPlans.length < 1}
                >
                  {getString('showPastPlans', language)}
                  {hasMultipleOpenPlans && (
                    <FiAlertCircle className={styles.ActionIcon} color="red" />
                  )}
                </Menu.Item>
              )}
            </>
          )}
          {!hideEdit && (
            <>
              <Menu.Item
                onClick={() => {
                  navigate(`/field-boundary/${operationId}/${fieldId}`);
                }}
              >
                {getString('editFieldBoundary', language)}
              </Menu.Item>
              <Menu.Item onClick={handleDownload}>
                {getString('downloadShpfile', language)}
              </Menu.Item>
            </>
          )}
          {samplingPlan && (
            <AdminAccess>
              <Menu.Item
                data-test-id="actions-view-sample-status"
                onClick={() => {
                  navigate(`/view-sample-status/${operationId}/${fieldId}/${samplingPlan.id}`);
                }}
              >
                {getString('viewSampleStatus', language)}
              </Menu.Item>
            </AdminAccess>
          )}
          {samplingPlan?.nutrient_external_lab_identifier && (
            <LabIntakeMenuItem language={language} samplingPlanId={samplingPlan.id} />
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export default Actions;
