import {
  BINARY,
  BINARY_REVERSE,
  HIGH_RISK,
  LOW_RISK,
  MODERATE_RISK,
  RISK_AREA_TYPES,
} from 'constants/fieldRisks';
import { RiskRangesType, SingleAnalyticType } from 'store/analytics/types';
import { v4 as uuid } from 'uuid';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import { RISK_FILL_COLORS, WHITE } from './mapImageryColors';
import { getRiskSetFromQuantity } from './results';
import { sortByFieldName } from './sortByName';

export const defineRiskAreas = (
  risk_level_order: string | undefined,
  analyticValue: SingleAnalyticType,
) => {
  const riskLevelAreas: {
    lowBound: number;
    highBound: number;
    color: string;
  }[] = [];

  const addToAreaArray = (lowBound: number, highBound: number, color: string) => {
    riskLevelAreas.push({ lowBound, highBound, color });
  };

  const { low, moderate, high } = analyticValue.risk_summary?.risk_ranges;

  switch (risk_level_order) {
    case RISK_AREA_TYPES.BINARY:
      addToAreaArray(0, 0, RISK_FILL_COLORS.LOW_RISK);
      addToAreaArray(0, 0, RISK_FILL_COLORS.HIGH_RISK);
      break;
    case RISK_AREA_TYPES.BINARY_REVERSE:
      addToAreaArray(0, 0, RISK_FILL_COLORS.HIGH_RISK);
      addToAreaArray(0, 0, RISK_FILL_COLORS.LOW_RISK);
      break;
    case RISK_AREA_TYPES.MODERATE_LOW_MODERATE:
      if (low && moderate) {
        addToAreaArray(moderate[0][0], moderate[0][1], RISK_FILL_COLORS.MODERATE_RISK);
        addToAreaArray(low[0][0], low[0][1], RISK_FILL_COLORS.LOW_RISK);
        addToAreaArray(moderate[1][0], moderate[1][1], RISK_FILL_COLORS.MODERATE_RISK);
      }
      break;

    case RISK_AREA_TYPES.LOW_MODERATE_HIGH:
      if (low && moderate && high) {
        addToAreaArray(low[0][0], low[0][1], RISK_FILL_COLORS.LOW_RISK);
        addToAreaArray(moderate[0][0], moderate[0][1], RISK_FILL_COLORS.MODERATE_RISK);
        addToAreaArray(high[0][0], high[0][1], RISK_FILL_COLORS.HIGH_RISK);
      }
      break;

    case RISK_AREA_TYPES.HIGH_MODERATE_LOW:
      if (low && moderate && high) {
        addToAreaArray(high[0][0], high[0][1], RISK_FILL_COLORS.HIGH_RISK);
        addToAreaArray(moderate[0][0], moderate[0][1], RISK_FILL_COLORS.MODERATE_RISK);
        addToAreaArray(low[0][0], low[0][1], RISK_FILL_COLORS.LOW_RISK);
      }
      break;

    case RISK_AREA_TYPES.LOW_MODERATE:
      if (low && moderate) {
        addToAreaArray(low[0][0], low[0][1], RISK_FILL_COLORS.LOW_RISK);
        addToAreaArray(moderate[0][0], moderate[0][1], RISK_FILL_COLORS.MODERATE_RISK);
      }
      break;

    case RISK_AREA_TYPES.MODERATE_LOW:
      if (low && moderate) {
        addToAreaArray(moderate[0][0], moderate[0][1], RISK_FILL_COLORS.MODERATE_RISK);
        addToAreaArray(low[0][0], low[0][1], RISK_FILL_COLORS.LOW_RISK);
      }
      break;

    default:
      addToAreaArray(0, 20000, WHITE);
      break;
  }
  return riskLevelAreas;
};

const getPercentOfLevel = (quantity: number, range: number[]) => {
  // Calculates percentage of quantity within a data range
  // Defaults to 1 if > 100%
  // Defaults to 0.1 if range is 0, 0
  // Defaults to 0 if quantity below range

  const quantityWithinBand = quantity - range[0];
  const rangeDelta = range[1] - range[0];

  if (quantityWithinBand < 0) {
    return 0;
  }

  if (quantityWithinBand === 0 && rangeDelta === 0) {
    return 0.1;
  }

  return Math.min(quantityWithinBand / rangeDelta, 1);
};

const getRangeForQuantity = (value: SingleAnalyticType | undefined | null) => {
  if (value?.quantity !== undefined && value?.quantity !== null && value?.data_summary) {
    const lowRange = value.data_summary.low;
    const modRange = value.data_summary.moderate;
    const highRange = value.data_summary.high;

    if (lowRange) {
      if (value.quantity <= lowRange[1]) {
        return { range: lowRange, index: 0 };
      }
    }

    if (modRange) {
      if (value.quantity >= modRange[0] && value.quantity <= modRange[1]) {
        return { range: modRange, index: 1 };
      }
    }

    if (highRange) {
      if (value.quantity >= highRange[0]) {
        return { range: highRange, index: 2 };
      }
    }
  }
  return { range: null, index: null };
};

const MAX_LEVEL_HEIGHT = 3;
// Get the estimated quantity for charts with scaled risk levels
export const getQuantityFromPercentInLevel = (value: SingleAnalyticType | undefined | null) => {
  const riskLevelOrder = value?.risk_summary?.risk_level_order;
  if (value?.quantity !== undefined && value?.quantity !== null) {
    if (riskLevelOrder === BINARY && value?.quantity) {
      return MAX_LEVEL_HEIGHT;
    }
    if (riskLevelOrder === BINARY_REVERSE && value?.quantity) {
      return MAX_LEVEL_HEIGHT;
    }
    if (!value?.data_summary) {
      // If there are NOT data ranges but still a quantity
      return value?.quantity;
    }

    const { range, index } = getRangeForQuantity(value);
    if (range && index !== null) {
      return getPercentOfLevel(value.quantity, range) + index;
    }
  }
  return 0;
};

export const getRiskColorFill = (riskLevel: string | undefined, isFoliarWithResult?: boolean) => {
  if (riskLevel === HIGH_RISK || isFoliarWithResult) {
    return RISK_FILL_COLORS.HIGH_RISK;
  }
  if (riskLevel === MODERATE_RISK) {
    return RISK_FILL_COLORS.MODERATE_RISK;
  }
  return RISK_FILL_COLORS.LOW_RISK;
};

interface ChartProps {
  key: string;
  stackId: string;
  strokeDasharray: string;
  type: 'monotone' | undefined;
  yAxisId: number;
  isAnimationActive: boolean;
}

export const getChartAreaProps = (): ChartProps => {
  return {
    key: uuid(),
    stackId: '2',
    strokeDasharray: '3 3',
    type: 'monotone',
    yAxisId: 2,
    isAnimationActive: false,
  };
};

export interface ChartBarType {
  barIndex: number;
  quantity: number;
  actual: number;
  raw: number;
  label: string;
  color: string;
}

const sortByQuantity = (barData: ChartBarType[]) =>
  barData.sort((a, b) => ((a.quantity || 0) < (b.quantity || 0) ? 1 : -1));

/**
 * Get the highest risk level from the order string, e.g. `low` from `low_moderate_high`, or
 * undefined if the order is not supplied.
 */
export const getHighestRiskFromOrder = (riskLevelOrder: string | undefined): string | undefined => {
  if (riskLevelOrder === RISK_AREA_TYPES.BINARY_REVERSE) {
    return HIGH_RISK;
  }
  if (riskLevelOrder === RISK_AREA_TYPES.BINARY) {
    return LOW_RISK;
  }
  return riskLevelOrder?.split('_')[0];
};

// Sort bar data based on where it falls in our risk ranges instead of by quantity alone
export const sortQuantityBasedOnRiskLevels = (
  barData: ChartBarType[],
  riskRanges: RiskRangesType,
  riskLevelOrder: string | undefined,
) => {
  const { low, moderate, high } = riskRanges;
  const highestRisk = getHighestRiskFromOrder(riskLevelOrder);

  const groupedChartBars = barData.reduce(
    (
      all: {
        highRange: ChartBarType[];
        moderateRange: ChartBarType[];
        lowRange: ChartBarType[];
      },
      singleBar: ChartBarType,
    ) => {
      if (getRiskSetFromQuantity(low, singleBar.quantity, highestRisk === LOW_RISK)) {
        all.lowRange.push(singleBar);
      } else if (
        getRiskSetFromQuantity(moderate, singleBar.quantity, highestRisk === MODERATE_RISK)
      ) {
        all.moderateRange.push(singleBar);
      } else if (getRiskSetFromQuantity(high, singleBar.quantity, highestRisk === HIGH_RISK)) {
        all.highRange.push(singleBar);
      }
      return all;
    },
    { highRange: [], moderateRange: [], lowRange: [] },
  );
  return [
    ...sortByQuantity(groupedChartBars.highRange),
    ...sortByQuantity(groupedChartBars.moderateRange),
    ...sortByQuantity(groupedChartBars.lowRange),
  ];
};

export const getAnalyticsForCategory = (
  planAnalytics: SamplingPlanType['analytics'],
  analyticIds: number[],
) =>
  Object.keys(planAnalytics)
    .map((key) => Object.keys(planAnalytics[key]))
    .flat(1)
    ?.filter((key) => analyticIds.includes(Number(key)));

const fieldHasResultsForAnalytics = (
  samplingPlans: SamplingPlanType[],
  analyticIds: number[],
  field: FieldType,
) => {
  const plans = samplingPlans.filter(
    (samplePlan) => samplePlan.field_id === field.features[0].properties.id,
  );

  // Combine the analytics results for the plans so we're showing the field if any plans have results
  const allPlanAnalytics = plans
    .map((plan) =>
      Object.keys(plan.analytics)
        .map((key) => Object.keys(plan.analytics[key]))
        .flat(1),
    )
    .flat(1);
  return allPlanAnalytics?.some((key) => analyticIds.includes(Number(key)));
};

export const getFieldsWithPlansForAnalyticCatV2 = (
  samplingPlans: SamplingPlanType[],
  fieldGeometries: FieldType[],
  analyticIds: number[],
) =>
  sortByFieldName(
    fieldGeometries.filter((field) =>
      fieldHasResultsForAnalytics(samplingPlans, analyticIds, field),
    ),
  );

export const getBarHeightForLevel = (
  level: number,
  newQuantity: number,
  isFoliarWithResults?: boolean,
) => {
  if (isFoliarWithResults) {
    return 100;
  }
  if (level === 1 && newQuantity * 100 < 15) {
    return 15;
  }

  if (newQuantity >= level) {
    return 100;
  }
  if (newQuantity < level && newQuantity > level - 1) {
    return (newQuantity - (level - 1)) * 100;
  }
  return 0;
};

const PX_IN_REM = 48;
// Recharts likes pixels instead of rems
export const remToPx = (rem: number) => rem * PX_IN_REM;
