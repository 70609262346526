import { SamplingPlanType } from 'store/fields/types';
import { SampleFeatureCollection, SampleGeoJSON } from 'util/generalTypes';
import {
  handleJsonResponse,
  QR_CODE_URL,
  requestDelete,
  requestGet,
  requestPut,
  SERVICE_URL,
} from 'util/request';

export const deleteSample = async (id: number, body: any) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/sample/${id}`, { body }));

export const getSampleByBarcode = async (barcode: string) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/samples_search/${barcode}`));

export const getSamples = async (
  id: number,
): Promise<{
  type: 'FeatureCollection';
  features: SampleGeoJSON[];
}> => handleJsonResponse(await requestGet(`${SERVICE_URL}/samples/${id}`));

export const putSample = async (id: number, body: any) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/sample/${id}`, { body }));

export const getActiveSamples = async (
  fieldId: number,
): Promise<{
  sampling_plans: Array<
    Omit<SamplingPlanType, 'samples'> & {
      samples: SampleFeatureCollection;
    }
  >;
}> => handleJsonResponse(await requestGet(`${SERVICE_URL}/active_sampling_plans/${fieldId}`));

export const markNotAnalyzed = async (sample_uuid: string, product: string) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/mark_not_analyzed/${sample_uuid}`, {
      body: { product },
    }),
  );

export const requestQRCode = async (inputString: string) =>
  fetch(`${QR_CODE_URL}/create-qr-code/?data=${encodeURIComponent(inputString)}`);
