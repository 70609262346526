import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group, Text, Stack, Button, Input, Modal, Loader, Center, Select } from '@mantine/core';

import { Accordion, Selector } from 'common';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import getZonesForPrescription, { getProPrescriptions } from 'store/prescriptions/thunks';
import { FieldType } from 'store/fields/types';
import { getFieldGeometry } from 'store/fields/thunks';
import { InputType } from 'store/inputs/types';
import { PrescriptionType, PrescriptionZoneType } from 'store/prescriptions/types';
import {
  putPrescription,
  downloadPrescriptionShapefile,
  postPrescriptionExternalAccount,
} from 'store/prescriptions/requests';
import { SampleFeatureType } from 'store/samples/types';
import showToast, { type ToastType } from 'actions/toastActions';
import {
  convertCCEForEditing,
  convertCCEForSubmission,
  convertTargetValueForEditing,
  convertTargetValueForSubmission,
  getCropOptions,
  getExportTypes,
  getFormulaOptions,
  getInputOptions,
  getSampleValueForPrescriptionAnalytic,
  getTillageOptions,
  getTimingOptions,
} from 'util/prescription';
import {
  getUnitBuAc,
  getUnitLbsAc,
  sacaHaToBuAc,
  realMetricTonToDollarsTon,
  KgHaToLbsAc,
} from 'util/units';
import { RootState } from 'store';
import { convertDecimalToPercent, isNumber } from 'util/numUtils';
import {
  PHOSPHORUS_BRAZIL,
  SATURATION_BRAZIL,
  inputNames,
  rxUnitToSymbol,
  POTASSIUM_BRAZIL,
  REMOVAL_REC_FORMULAS,
} from 'constants/prescription';
import { PRO_EXPORT_ACRE_DENSITY_OPTIONS } from 'constants/results';

import styles from './Settings.module.css';

type SettingsProps = {
  existingZones: PrescriptionZoneType[];
  field: FieldType;
  inputs: InputType[];
  prescription: PrescriptionType;
  samples: SampleFeatureType[];
  setIsSubmitting: (value: boolean) => void;
};

const Settings = ({
  existingZones,
  field,
  inputs,
  prescription,
  samples,
  setIsSubmitting,
}: SettingsProps) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const { acreage_unit } = field.features[0].properties;

  const { currentUser, operation } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
    operation: state.operations.operationsById[field.features[0].properties.operation_id],
  }));

  const samplingPlan = field.features[0].properties.sampling_plans.find(
    (plan) => plan.id === prescription.sampling_plan_id,
  );

  const { composite_imagery_layers, harvest_data_files } = field.features[0].properties;
  const validHarvestYears = harvest_data_files.filter(
    (file) => file.start_time !== null && file.processed_geojson_uri !== null,
  );
  const validImageryYears = composite_imagery_layers.filter((file) => file.geojson_uri !== null);

  const proDensityOptions = useMemo(() => {
    if (
      REMOVAL_REC_FORMULAS.includes(prescription.formula_name) &&
      (validHarvestYears.length || validImageryYears.length)
    ) {
      return PRO_EXPORT_ACRE_DENSITY_OPTIONS;
    }
    return samplingPlan?.pro_densities.length
      ? PRO_EXPORT_ACRE_DENSITY_OPTIONS.filter((option) =>
          samplingPlan.pro_densities.includes(option.value),
        )
      : [];
  }, [validHarvestYears, samplingPlan, prescription]);

  const [activeDensity, setActiveDensity] = useState('');

  const listOutputFormats = getExportTypes(language, operation?.external_connected_accounts);
  const timingOptions = getTimingOptions(language);
  const cropOptions = getCropOptions(language);
  const tillageOptions = getTillageOptions(language);

  const formulaOptionsByCategory = getFormulaOptions(
    language,
    field,
    prescription.input.nutrient,
    currentUser?.id,
  );
  const { NUTRIENTS: nutrientOptions, REMOVAL: removalOptions } = formulaOptionsByCategory;
  const allFormulaOptions = prescription.machine_data_id ? removalOptions : nutrientOptions;

  const [showSettings, setShowSettings] = useState(true);
  const [triggerSave, setTriggerSave] = useState(false);
  const [reloadPrescription, setReloadPrescription] = useState(true);

  // @ts-ignore until prescripts return
  const [, setExportingScript] = useState(false);

  const [name, setName] = useState('');

  const [timingIndex, setTimingIndex] = useState(0);
  const [cropIndex, setCropIndex] = useState(0);
  const [activeFormula, setActiveFormula] = useState<string>(
    String(allFormulaOptions?.[0]?.value || ''),
  );
  const [tillageIndex, setTillageIndex] = useState(0);

  const [pricePerTon, setPricePerTon] = useState('0');
  const [customConcentration, setCustomConcentration] = useState('0');
  const [targetValue, setTargetValue] = useState('0');
  const [percentApplication, setPercentApplication] = useState('0');
  const [minimumRate, setMinimumRate] = useState('0');
  const [maximumRate, setMaximumRate] = useState('0');
  const [expectedYield, setExpectedYield] = useState('0');
  const [maxYield, setMaxYield] = useState('0');
  const [minYield, setMinYield] = useState('0');

  const [exportIndex, setExportIndex] = useState(0);
  const [inputIndex, setInputIndex] = useState(0);

  const [showProModal, setShowProModal] = useState(false);

  const inputOptions = useMemo(
    () =>
      getInputOptions(
        inputs,
        prescription.input.nutrient,
        activeFormula || prescription.formula_name,
      ),
    [inputs, prescription, allFormulaOptions, activeFormula],
  );

  useEffect(() => {
    if (reloadPrescription && prescription) {
      setName(prescription.name);
      setTillageIndex(prescription.no_till ? 1 : 0);
      setPricePerTon((prescription.cost_per_ton || 0).toString());
      setCustomConcentration(
        convertCCEForEditing(
          prescription.input,
          prescription.custom_input_concentration || 0,
        ).toString(),
      );
      setTargetValue(
        convertTargetValueForEditing(
          prescription.formula_name,
          prescription.target_value || 0,
        ).toString(),
      );
      setPercentApplication(
        convertDecimalToPercent(
          prescription.percent_target_application ? prescription.percent_target_application : 1,
        ).toString(),
      );
      setMinimumRate((prescription.minimum_rate || 0).toString());
      setMaximumRate((prescription.maximum_rate || 0).toString());
      setExpectedYield((prescription.expected_yield || 0).toString());
      setTimingIndex(timingOptions.findIndex((option) => option.value === prescription.timing));
      setCropIndex(cropOptions.findIndex((option) => option.value === prescription.crop));
      setActiveDensity(prescription.pro_density || '');
      setActiveFormula(
        allFormulaOptions.find((formula) => formula.value === prescription.formula_name)?.value ||
          prescription.formula_name,
      );
      setMaxYield((prescription.imagery_calculation_max || 0).toString());
      setMinYield((prescription.imagery_calculation_min || 0).toString());
      const newInputOptions = getInputOptions(
        inputs,
        prescription.input.nutrient,
        prescription.formula_name,
      );
      setInputIndex(newInputOptions.findIndex((option) => option.id === prescription.input_id));
      setReloadPrescription(false);
    }
  }, [prescription, reloadPrescription, timingOptions, cropOptions, allFormulaOptions]);

  const showMessage = (message: string, type?: ToastType, timeout = 5000) =>
    showToast(message, type, timeout);

  const getZones = useCallback(() => {
    if (!prescription.geojson_uri) {
      dispatch(getZonesForPrescription(prescription.id, field.features[0].properties.acreage_unit));
    }
  }, [dispatch, prescription, field]);

  useEffect(() => {
    if (triggerSave) {
      submit();
      setTriggerSave(false);
    }
  }, [
    triggerSave,
    cropIndex,
    activeFormula,
    inputIndex,
    pricePerTon,
    percentApplication,
    minimumRate,
    maximumRate,
    timingIndex,
    expectedYield,
    tillageIndex,
  ]);

  const submit = async () => {
    setIsSubmitting(true);
    try {
      // const isPro = proDensityIndex >= 0;
      if (activeDensity) {
        setShowProModal(true);
      }
      const { id: fieldId } = field.features[0].properties;

      // skip zone collection for payload if editing pro prescription
      const zones = activeDensity
        ? undefined
        : existingZones.map((zone) => {
            const zoneSample = samples.find(
              (sample) => sample.properties.sample_uuid === zone.properties.sample_uuid,
            );

            return {
              ...zone,
              properties: {
                id: zone.properties.id,
                sample_uuid: zoneSample?.properties.sample_uuid,
                amount:
                  getSampleValueForPrescriptionAnalytic(prescription, zoneSample)?.quantity || null,
              },
            };
          });

      // Make sure to convert to dollars, acres, lbs, tons before sending
      const payload = {
        name,
        crop: cropOptions[cropIndex].value,
        formula_name: activeFormula,
        input_id: inputOptions[inputIndex].id,
        cost_per_ton: realMetricTonToDollarsTon(Number(pricePerTon), acreage_unit),
        percent_target_application: Number(percentApplication) / 100,
        minimum_rate: KgHaToLbsAc(Number(minimumRate), acreage_unit),
        maximum_rate: isNumber(Number(maximumRate))
          ? KgHaToLbsAc(Number(maximumRate), acreage_unit)
          : undefined,
        timing: timingOptions[timingIndex].value,
        expected_yield: sacaHaToBuAc(Number(expectedYield), acreage_unit),
        no_till: tillageIndex === 1,
        custom_input_concentration: convertCCEForSubmission(
          inputOptions[inputIndex].value,
          Number(customConcentration),
        ),
        target_value: convertTargetValueForSubmission(activeFormula, Number(targetValue)),
        zones,
        pro_density: activeDensity || null,
        imagery_calculation_min: Number(minYield) || null,
        imagery_calculation_max: Number(maxYield) || null,
      };
      // @ts-ignore
      await putPrescription(prescription.id, payload);
      await Promise.all([
        dispatch(getFieldGeometry(fieldId)),
        dispatch(getProPrescriptions(fieldId)),
        getZones(),
      ]);
      showMessage(`${getString('prescriptionSaved', language)}: ${name}`);
      setReloadPrescription(true);
    } catch (error) {
      showToast(getString('updatePrescriptionErrorMsg', language), 'error');
    } finally {
      setIsSubmitting(false);
      setShowProModal(false);
    }
  };

  const exportPrescription = async () => {
    const selectedExternal = listOutputFormats[exportIndex];
    if (selectedExternal.leaf_user_uuid === null) {
      try {
        setExportingScript(true);
        await downloadPrescriptionShapefile(prescription.id, selectedExternal.value);
        showMessage(getString('prescriptionDownloadSuccessMsg', language));
      } catch (err) {
        showMessage(getString('prescriptionDownloadFailMsg', language), 'error');
      }
    } else {
      try {
        setExportingScript(true);
        await postPrescriptionExternalAccount(prescription.id, {
          external_user_uuid: selectedExternal.leaf_user_uuid,
          provider: selectedExternal.value,
        });
        showMessage(getString('exportInitiatedMsg', language));
      } catch (err) {
        showMessage(getString('initiatedExportFailMsg', language), 'error');
      }
    }
    setExportingScript(false);
  };

  const updateSelector = (stateFunc: Function, idx: number) => {
    stateFunc(idx);
    setTriggerSave(true);
  };

  const submitPercentApplication = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!prescription.percent_target_application && e.target.value) {
      setTriggerSave(true);
    }
    if (
      prescription.percent_target_application &&
      e.target.value !== String(convertDecimalToPercent(prescription.percent_target_application))
    ) {
      setTriggerSave(true);
    }
  };

  const submitTargetValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!prescription.target_value && e.target.value) {
      setTriggerSave(true);
    }
    if (
      prescription.target_value &&
      e.target.value !==
        String(convertTargetValueForEditing(prescription.formula_name, prescription.target_value))
    ) {
      setTriggerSave(true);
    }
  };

  const submitMaxYield = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!prescription.imagery_calculation_max && e.target.value) {
      setTriggerSave(true);
    }
    if (
      prescription.imagery_calculation_max &&
      e.target.value !==
        String(
          convertTargetValueForEditing(
            prescription.formula_name,
            prescription.imagery_calculation_max,
          ),
        )
    ) {
      setTriggerSave(true);
    }
  };

  const submitMinYield = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!prescription.imagery_calculation_min && e.target.value) {
      setTriggerSave(true);
    }
    if (
      prescription.imagery_calculation_min &&
      e.target.value !==
        String(
          convertTargetValueForEditing(
            prescription.formula_name,
            prescription.imagery_calculation_min,
          ),
        )
    ) {
      setTriggerSave(true);
    }
  };

  const updateInput = (
    stateFunc: Function,
    e: React.ChangeEvent<HTMLInputElement>,
    allowNull?: boolean,
  ) => {
    if (Number(e.target.value) >= 0 || (allowNull && e.target.value === '')) {
      stateFunc(e.target.value);
    }
  };

  const handleSetFormula = (value: string) => {
    const selectedFormula = allFormulaOptions.find((formula) => formula.value === value);
    if (selectedFormula) {
      const selectedInput = inputOptions[inputIndex]?.value;
      const newInputOptions = getInputOptions(
        inputs,
        prescription.input.nutrient,
        selectedFormula.value,
      );
      const newInputIndex = newInputOptions.findIndex((i) => i.value.id === selectedInput?.id);
      if (newInputIndex < 0) {
        setInputIndex(0);
      } else if (newInputIndex !== inputIndex) {
        setInputIndex(newInputIndex);
      }
      setActiveFormula(value);
      setTriggerSave(true);
    }
  };

  const handleSetInputIndex = (idx: number) => {
    const selectedInput = inputOptions[idx].value;
    if (selectedInput.customizeable) {
      setCustomConcentration(
        convertCCEForEditing(selectedInput, selectedInput.concentration || 0).toString(),
      );
    }
    setInputIndex(idx);
  };

  // TODO: abstract this into a common utility when rest of page is mantinified
  // the above updateSelector function expects index based selectors instead of mantine string
  // selects
  const changeDensity = (value: string) => {
    setActiveDensity(value);
    setTriggerSave(true);
  };

  const currentInput = inputOptions[inputIndex]?.value;

  return (
    <>
      <Accordion
        key="prescription-settings"
        open={showSettings}
        title={getString('settings', language)}
        toggleOpen={() => setShowSettings(!showSettings)}
      >
        <Stack gap="xs">
          <Group justify="space-between">
            <Text>{getString('name', language)}:</Text>
            <Input
              data-test-id="rx-name-single"
              className={styles.Selector}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              onBlur={(e) => e.target.value !== prescription.name && setTriggerSave(true)}
              value={name}
            />
          </Group>
          <Group justify="space-between">
            <Text>{getString('timing', language)}:</Text>
            <Selector
              activeIndex={timingIndex}
              className={styles.Selector}
              onChange={(idx) => updateSelector(setTimingIndex, idx)}
              options={timingOptions}
            />
          </Group>
          <Group justify="space-between">
            <Text>{getString(prescription.machine_data ? 'previousCrop' : 'crop', language)}:</Text>
            <Selector
              activeIndex={cropIndex}
              className={styles.Selector}
              onChange={(idx) => updateSelector(setCropIndex, idx)}
              options={cropOptions}
            />
          </Group>
          {proDensityOptions.length ? (
            <Group justify="space-between">
              <Text>{getString('density', language)}:</Text>
              <Select
                value={activeDensity}
                onChange={(val) => val && changeDensity(val)}
                data={proDensityOptions}
              />
            </Group>
          ) : null}
          <Group justify="space-between">
            <Text>{getString('formula', language)}:</Text>
            <Select
              className={styles.Selector}
              data={allFormulaOptions}
              onChange={(value) => value && handleSetFormula(value)}
              value={activeFormula}
            />
          </Group>
          {prescription.composite_imagery_id && (
            <>
              <Group justify="space-between">
                <Text>{getString('maxYield', language)}:</Text>
                <Input
                  className={styles.Input}
                  type="number"
                  onChange={(e) => updateInput(setMaxYield, e)}
                  onBlur={submitMaxYield}
                  value={maxYield}
                />
              </Group>
              <Group justify="space-between">
                <Text>{getString('minYield', language)}:</Text>
                <Input
                  className={styles.Input}
                  type="number"
                  onChange={(e) => updateInput(setMinYield, e)}
                  onBlur={submitMinYield}
                  value={minYield}
                />
              </Group>
            </>
          )}
          {[SATURATION_BRAZIL, PHOSPHORUS_BRAZIL, POTASSIUM_BRAZIL].includes(activeFormula) && (
            <Group justify="space-between">
              {activeFormula === SATURATION_BRAZIL && (
                <Text>{getString('targetBaseSaturation', language)}:</Text>
              )}
              {activeFormula === PHOSPHORUS_BRAZIL && (
                <Text>{getString('targetPhosphorus', language)}:</Text>
              )}
              {activeFormula === POTASSIUM_BRAZIL && (
                <Text>{getString('targetPotassium', language)}:</Text>
              )}
              <Input
                className={styles.Input}
                type="number"
                onChange={(e) => updateInput(setTargetValue, e)}
                onBlur={submitTargetValue}
                value={targetValue}
              />
            </Group>
          )}
          <Group justify="space-between">
            <Text>{getString('tillage', language)}:</Text>
            <Selector
              activeIndex={tillageIndex}
              className={styles.Selector}
              onChange={(idx) => updateSelector(setTillageIndex, idx)}
              options={tillageOptions}
            />
          </Group>
          <Group justify="space-between">
            <Text>{getString('input', language)}:</Text>
            <Selector
              activeIndex={inputIndex}
              className={styles.Selector}
              onChange={(idx) => updateSelector(handleSetInputIndex, idx)}
              options={inputOptions}
            />
          </Group>
          {currentInput?.customizeable && (
            <Group justify="space-between">
              <Text>
                {`${getString(inputNames[currentInput.id], language)} (${
                  currentInput.unit && rxUnitToSymbol[currentInput.unit]
                })`}
                :
              </Text>
              <Input
                className={styles.Input}
                type="number"
                onChange={(e) => updateInput(setCustomConcentration, e)}
                onBlur={(e) =>
                  e.target.value !==
                    String(
                      convertCCEForEditing(
                        prescription.input,
                        prescription.custom_input_concentration || prescription.input.concentration,
                      ),
                    ) && setTriggerSave(true)
                }
                value={customConcentration}
              />
            </Group>
          )}
          <Group justify="space-between">
            <Text>{getString('pricePerTon', language)}:</Text>
            <Input
              className={styles.Input}
              type="number"
              onChange={(e) => updateInput(setPricePerTon, e)}
              onBlur={(e) =>
                e.target.value !== String(prescription.cost_per_ton) && setTriggerSave(true)
              }
              value={pricePerTon}
            />
          </Group>
          <Group justify="space-between" data-test-id="percent-target">
            <Text>{getString('percentTargetApply', language)}:</Text>
            <Input
              className={styles.Input}
              type="number"
              onChange={(e) => updateInput(setPercentApplication, e)}
              onBlur={submitPercentApplication}
              value={percentApplication}
            />
          </Group>
          <Group justify="space-between">
            <Text>
              {getString('minimumRate', language)} ({getUnitLbsAc(acreage_unit)}):
            </Text>
            <Input
              className={styles.Input}
              type="number"
              onChange={(e) => updateInput(setMinimumRate, e)}
              onBlur={(e) =>
                e.target.value !== String(prescription.minimum_rate) && setTriggerSave(true)
              }
              value={minimumRate}
            />
          </Group>
          <Group justify="space-between">
            <Text>
              {getString('maximumRate', language)} ({getUnitLbsAc(acreage_unit)}):
            </Text>
            <Input
              className={styles.Input}
              type="number"
              onChange={(e) => updateInput(setMaximumRate, e, true)}
              onBlur={(e) =>
                e.target.value !== String(prescription.maximum_rate) && setTriggerSave(true)
              }
              value={maximumRate || ''}
            />
          </Group>
          <Group justify="space-between">
            <Text>
              {getString('expectedYield', language)} ({getUnitBuAc(acreage_unit)}):
            </Text>
            <Input
              className={styles.Input}
              type="number"
              onChange={(e) => updateInput(setExpectedYield, e)}
              onBlur={(e) =>
                e.target.value !== String(prescription.expected_yield) && setTriggerSave(true)
              }
              value={expectedYield}
            />
          </Group>
          <Group justify="space-between">
            <Text>{getString('outputFormula', language)}:</Text>
            <Selector
              activeIndex={exportIndex}
              className={styles.Selector}
              onChange={setExportIndex}
              options={listOutputFormats}
              menuClassName={styles.SelectorMenu}
            />
          </Group>
          <Group justify="flex-end">
            <Button data-test-id="export-rx" onClick={exportPrescription}>
              {getString('export', language)}
            </Button>
          </Group>
        </Stack>
      </Accordion>
      <Modal
        opened={showProModal}
        onClose={() => setShowProModal(false)}
        title={getString('generatingPrescription', language)}
        withCloseButton={false}
      >
        <Center>
          <Loader my="lg" />
        </Center>
      </Modal>
    </>
  );
};

export default Settings;
