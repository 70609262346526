import {
  handleFileAttachment,
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestGetFile,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { GeoJsonProperties, Geometry, FeatureCollection, MultiPolygon } from 'geojson';
import { FieldType } from './types';

export const deleteFieldGeometry = (id: number) =>
  requestDelete(`${SERVICE_URL}/field_geometry/${id}`).then(handleJsonResponse);

export const fetchFieldGeometry = (id: number): Promise<FieldType> =>
  requestGet(`${SERVICE_URL}/field/${id}`).then(handleJsonResponse);

export const fetchFieldGeometryNoSamples = (id: number) =>
  requestGet(`${SERVICE_URL}/field_no_samples/${id}`).then(handleJsonResponse);

export const putFieldGeometry = (
  id: number,
  body: FieldType | FeatureCollection<Geometry, GeoJsonProperties>,
) => requestPut(`${SERVICE_URL}/field_geometry/${id}`, { body }).then(handleJsonResponse);

export const deleteField = (id: number) =>
  requestDelete(`${SERVICE_URL}/field/${id}`).then(handleJsonResponse);

export const downloadFieldShapefile = async (id: number) =>
  handleFileAttachment(await requestGetFile(`${SERVICE_URL}/field_geometry_shapefile/${id}`));

export const requestClusResults = async (
  minx: number,
  maxx: number,
  miny: number,
  maxy: number,
): Promise<FeatureCollection<MultiPolygon>> =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/clus/?minx=${minx}&maxx=${maxx}&miny=${miny}&maxy=${maxy}`),
  );

export const requestMergeFields = async (parentFieldId: number, childFieldIds: number[]) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/merge_fields_into_field/${parentFieldId}`, {
      body: { field_ids: childFieldIds },
    }),
  );

export const requestSplitFields = async (parentFieldId: number, features: any[]) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/split_field_into_fields/${parentFieldId}`, {
      body: { features },
    }),
  );

export default fetchFieldGeometry;
