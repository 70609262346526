import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Group,
  Input,
  Modal,
  SegmentedControl,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { Feature, GeometryCollection } from '@turf/helpers';
import { generateZonesForPoints } from 'util/geospatial';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticType } from 'store/analytics/types';
import {
  BPH_SIKORA_ID,
  DEFAULT_RX_DENSITY,
  POTASSIUM_ID,
  PRO_EXPORT_ACRE_DENSITY_OPTIONS,
  SOIL_ATTRIBUTES,
} from 'constants/results';
import { BR } from 'constants/countries';
import { capitalize } from 'util/stringUtils';
import {
  BRAZIL_ONLY_INPUTS,
  DEFAULT_RX_VALUES_BR,
  DEFAULT_RX_VALUES_US,
  IMAGERY,
  REMOVAL_P_ISU,
  REMOVAL_REC_FORMULAS,
  RX_TYPE_BUILDUP,
  RX_TYPE_REMOVAL,
  SOIL_TEST,
  YIELD,
} from 'constants/prescription';
import { FieldType } from 'store/fields/types';
import { formatToYear, sortByCreatedAt, sortByStartTime } from 'util/date';
import { getFieldGeometry } from 'store/fields/thunks';
import {
  getFormulaOptions,
  getGenerateFromOptions,
  getInputOptions,
  getNutrientSelectorOptions,
} from 'util/prescription';
import {
  getPlanName,
  getProNutrientMapLayer,
  hasSoilAttributes,
  isPlanCreated,
  isPointBased,
  sortPlansByDate,
} from 'util/samplePlan';
import { getProPrescriptions } from 'store/prescriptions/thunks';
import { getString } from 'strings/translation';
import { isProduction } from 'util/request';
import { NUTRIENT_PANEL } from 'constants/products';
import { P, PH } from 'constants/chemistry';
import { POLYGON } from 'constants/mapbox';
import { postPrescription } from 'store/prescriptions/requests';
import { RootState } from 'store';
import { SampleFeatureType } from 'store/samples/types';
import showToast from 'actions/toastActions';
import useBroswerLanguage from 'util/hooks/useLanguage';

import MapThumbnail from './MapThumbnail';
import MissingDataMessage from './MissingDataMessage';

import styles from './CreatePrescriptionModal.module.css';
import { useForm } from '@mantine/form';
import { CORN, SOYBEANS } from 'constants/variables';

type CreatePrescriptionModalProps = {
  analytics: AnalyticType[];
  field: FieldType;
  onClose: () => void;
  onSubmit: (id: number) => void;
  samples: SampleFeatureType[];
  opened: boolean;
};

type PrescriptionStateType = {
  crop: string;
  formula: string;
  generateFrom: string;
  harvestLayer: string | null;
  imageryLayer: string | null;
  input: string | null;
  isSubmitting: boolean;
  maxRate: number | undefined;
  maxYield: number | undefined;
  minYield: number | undefined;
  nutrient: string;
  proDensity: string | null;
  rxType: typeof RX_TYPE_BUILDUP | typeof RX_TYPE_REMOVAL;
  samplePlan: string;
};

const CreatePrescriptionModal = ({
  analytics,
  field,
  onClose,
  onSubmit,
  samples,
  opened,
}: CreatePrescriptionModalProps) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const { inputs } = useSelector((state: RootState) => ({
    inputs: state.inputs.inputs,
  }));

  const { composite_imagery_layers, harvest_data_files } = field.features[0].properties;

  const prescriptionForm = useForm<PrescriptionStateType>({
    mode: 'uncontrolled',
    initialValues: {
      crop: '',
      formula: '',
      generateFrom: SOIL_TEST,
      harvestLayer: null,
      imageryLayer: null,
      input: null,
      isSubmitting: false,
      maxRate: undefined,
      maxYield: undefined,
      minYield: undefined,
      nutrient: '',
      proDensity: null,
      rxType: RX_TYPE_BUILDUP,
      samplePlan: '',
    },
  });

  const {
    crop,
    formula,
    generateFrom,
    harvestLayer,
    imageryLayer,
    input,
    isSubmitting,
    maxRate,
    maxYield,
    minYield,
    nutrient,
    proDensity,
    rxType,
    samplePlan,
  } = prescriptionForm.getValues();

  // Rx type and generate from
  const isRemoval = rxType === RX_TYPE_REMOVAL;
  const generateFromOptions = getGenerateFromOptions(rxType, language);

  const handleGenerateFromChange = (val: string) => {
    prescriptionForm.setFieldValue('generateFrom', val);
    if (val === SOIL_TEST) {
      prescriptionForm.setValues({
        harvestLayer: null,
        imageryLayer: null,
      });
    } else if (val === YIELD) {
      prescriptionForm.setFieldValue('imageryLayer', null);
      if (!harvestLayer) {
        prescriptionForm.setFieldValue('harvestLayer', harvestYearOptions[0]?.value);
      }
    } else if (val === IMAGERY) {
      prescriptionForm.setFieldValue('harvestLayer', null);
      if (!imageryLayer) {
        prescriptionForm.setFieldValue('imageryLayer', imageryYearOptions[0]?.value);
      }
    }
  };

  const handleChangeRxType = (val: string) => {
    // typescript doesn't like [RX_TYPE_BUILDUP, RX_TYPE_REMOVAL].includes(val) for some reason
    if (val === RX_TYPE_BUILDUP || val === RX_TYPE_REMOVAL) {
      prescriptionForm.setValues({
        rxType: val,
        generateFrom: getGenerateFromOptions(val, language)[0]?.value || generateFrom,
      });
    }
    // when switching to removal from buildup lime, force nutrient to phosphorus
    if (val === RX_TYPE_REMOVAL && nutrient === PH) {
      const pInputs = getAvailableInputs(P);
      prescriptionForm.setValues({
        formula: REMOVAL_P_ISU,
        input: String(pInputs[0]?.value.id || ''),
        nutrient: P,
      });
    }
    const formulaOptions = getFormulaOptions(language, field, nutrient);
    if (val === RX_TYPE_BUILDUP && REMOVAL_REC_FORMULAS.includes(formula)) {
      prescriptionForm.setFieldValue('formula', formulaOptions.NUTRIENTS[0]?.value || formula);
    }
    if (val === RX_TYPE_REMOVAL && !REMOVAL_REC_FORMULAS.includes(formula)) {
      prescriptionForm.setFieldValue('formula', formulaOptions.REMOVAL[0]?.value || formula);
    }
  };

  // Soil test selector
  const samplingPlanOptions = (() => {
    const { sampling_plans } = field.features[0].properties;
    const plansWithAttributes = sortPlansByDate(
      sampling_plans.filter((plan) => hasSoilAttributes(plan) && !isPlanCreated(plan)),
    );
    return plansWithAttributes.map((plan) => ({
      id: plan.id,
      displayName: getPlanName(plan),
      label: getPlanName(plan),
      value: String(plan.id),
      plan,
    }));
  })();

  const selectedPlan = samplingPlanOptions.find((plan) => plan.id === Number(samplePlan));
  const samplingPlanSamples = (() => {
    if (selectedPlan) {
      const planSamples = samples.filter(
        (sample) =>
          sample.properties.sampling_plan_id === selectedPlan.id &&
          !sample.properties.biological_subsample,
      );
      if (!isPointBased(selectedPlan.plan)) {
        return planSamples;
      }
      return generateZonesForPoints(
        planSamples as Feature<GeometryCollection>[],
        field,
      ) as SampleFeatureType[];
    }
  })();

  // Nutrient and input selectors
  const nutrientSelectorOptions = getNutrientSelectorOptions(rxType, language);
  const planNutrientOptions = selectedPlan
    ? nutrientSelectorOptions
        .filter((option) => selectedPlan.plan.analytics[SOIL_ATTRIBUTES][option.id])
        .map((option) => ({
          id: option.id,
          label: option.displayName,
          value: option.value,
        }))
    : [];
  const nutrientOptionSelected = planNutrientOptions?.find((option) => option.value === nutrient);
  const selectedNutrient = analytics.find((analytic) => analytic.id === nutrientOptionSelected?.id);
  const proLayer =
    selectedPlan && selectedNutrient
      ? getProNutrientMapLayer(selectedPlan.plan, selectedNutrient)
      : null;
  const isBrazilK =
    field.features[0].properties?.country_code === BR && Number(nutrient) === POTASSIUM_ID;

  // Machine data selector
  const harvestYearOptions = sortByStartTime(harvest_data_files)
    .filter((file) => file.start_time !== null)
    .map((file) => ({
      id: file.id,
      label: `${formatToYear(file.start_time)} ${capitalize(file.crop || '')}`,
      value: file.processed_geojson_uri,
    }));

  // Imagery selector
  const imageryYearOptions = composite_imagery_layers
    .sort((a, b) => (a.year < b.year ? 1 : -1))
    .filter((file) => file.geojson_uri !== null)
    .map((file) => ({
      id: file.id,
      label: `${file.year}`,
      value: file.geojson_uri,
    }));

  // Crop selector
  const imageryCropOptions = [
    {
      id: 1,
      label: getString('corn', language),
      value: CORN,
    },
    {
      id: 2,
      label: getString('soybeans', language),
      value: SOYBEANS,
    },
  ];

  // Density selector
  const proDensityOptions = useMemo(() => {
    if (isRemoval && harvestYearOptions.length) {
      return PRO_EXPORT_ACRE_DENSITY_OPTIONS;
    }
    return selectedPlan?.plan.pro_densities.length
      ? PRO_EXPORT_ACRE_DENSITY_OPTIONS.filter((option) =>
          selectedPlan.plan.pro_densities.includes(option.value),
        )
      : [];
  }, [harvestYearOptions, rxType, selectedPlan]);

  // Formula selector
  const formulaOptionsByCategory = getFormulaOptions(language, field, nutrient);
  const { NUTRIENTS: nutrientOptions, REMOVAL: removalOptions } = formulaOptionsByCategory;
  const allFormulaOptions = rxType === RX_TYPE_BUILDUP ? nutrientOptions : removalOptions;

  const handleFormulaChange = (value = '') => {
    prescriptionForm.setFieldValue('formula', value);
    if (REMOVAL_REC_FORMULAS.includes(value)) {
      if (!harvestLayer && generateFrom === YIELD) {
        prescriptionForm.setFieldValue('harvestLayer', harvestYearOptions[0]?.value || null);
      }
      if (!imageryLayer && generateFrom === IMAGERY) {
        prescriptionForm.setFieldValue('imageryLayer', imageryYearOptions[0]?.value || null);
      }
      if (!proDensity) {
        prescriptionForm.setFieldValue('proDensity', PRO_EXPORT_ACRE_DENSITY_OPTIONS[0].value);
      }
    } else {
      prescriptionForm.setFieldValue('harvestLayer', null);
      prescriptionForm.setFieldValue('imageryLayer', null);
    }
  };

  const getAvailableInputs = (forceNutrient?: string) => {
    const filteredInputs =
      field.features[0].properties.country_code === BR
        ? inputs
        : inputs.filter((i) => !BRAZIL_ONLY_INPUTS.includes(i.id));
    return getInputOptions(filteredInputs, forceNutrient || nutrient, formula);
  };

  const inputOptions = getAvailableInputs().map((i) => ({
    id: i.id,
    label: i.displayName,
    value: String(i.value.id),
  }));

  const handleNutrientChange = (val: string) => {
    const optionsByCategory = getFormulaOptions(language, field, val);
    const allOptions =
      rxType === RX_TYPE_BUILDUP ? optionsByCategory.NUTRIENTS : optionsByCategory.REMOVAL;
    if (allOptions.length) {
      prescriptionForm.setFieldValue('formula', allOptions[0].value);
    }
    const availableInputs = getAvailableInputs(val);
    if (availableInputs.length) {
      prescriptionForm.setFieldValue('input', String(availableInputs[0].value.id));
    }
    prescriptionForm.setFieldValue('nutrient', val);
  };

  // Submit functionality
  const submit = async () => {
    prescriptionForm.setFieldValue('isSubmitting', true);
    const { id: fieldId, country_code: countryCode, crop_plans } = field.features[0].properties;
    const crop_plan = sortByCreatedAt(crop_plans || [])?.[0];
    // for ph prescriptions, we want to show ph on the map but use buffer ph for
    // the amount calculations
    const analyticId = nutrient === PH ? BPH_SIKORA_ID : Number(nutrientOptionSelected?.id);
    const density = PRO_EXPORT_ACRE_DENSITY_OPTIONS.find((option) => option.value === proDensity);

    const zones = density
      ? undefined
      : (samplingPlanSamples || [])
          .map((sample) => ({
            ...sample,
            geometry: sample.geometry.geometries.find((geom) => geom.type === POLYGON),
            properties: {
              sample_uuid: sample.properties.sample_uuid,
              amount:
                sample.properties.analytics[SOIL_ATTRIBUTES]?.[analyticId]?.quantity ||
                selectedPlan?.plan.analytics[SOIL_ATTRIBUTES]?.[analyticId]?.quantity ||
                null,
              products: sample.properties.products,
            },
          }))
          .filter(
            (sample) =>
              Boolean(sample.geometry) && sample.properties.products.includes(NUTRIENT_PANEL),
          );
    // @ts-ignore
    const defaultValues =
      (countryCode === BR ? DEFAULT_RX_VALUES_BR[analyticId] : DEFAULT_RX_VALUES_US[analyticId]) ||
      {};

    const harvestFile = harvest_data_files.find(
      (file) => file.processed_geojson_uri === harvestLayer,
    );

    const imageryFile = composite_imagery_layers.find((file) => file.geojson_uri === imageryLayer);

    const getProDensity = () => {
      if (generateFrom === SOIL_TEST) {
        return proLayer ? density?.value : null;
      }
      return density?.value;
    };

    const payload = {
      ...defaultValues,
      field_id: fieldId,
      name: `${field.features[0].properties.name} - ${nutrient.toUpperCase()}`,
      zones,
      sampling_plan_id: selectedPlan?.id,
      crop: crop_plan?.crop || defaultValues.crop,
      expected_yield: crop_plan?.target_yield_per_acre || defaultValues.expected_yield,
      maximum_rate: maxRate || defaultValues.maximum_rate,
      pro_density: getProDensity(),
      machine_data_id: generateFrom === YIELD ? harvestFile?.id : null,
      composite_imagery_id: generateFrom === IMAGERY ? imageryFile?.id : null,
      formula_name: formula || defaultValues.formula_name,
      input_id: input ? Number(input) : defaultValues.input_id,
      imagery_calculation_max: generateFrom === IMAGERY ? maxYield : null,
      imagery_calculation_min: generateFrom === IMAGERY ? minYield : null,
    };
    const response = await postPrescription(payload);
    await Promise.all([
      dispatch(getFieldGeometry(fieldId)),
      dispatch(getProPrescriptions(fieldId)),
    ]);
    showToast(getString('prescriptionCreatedMsg', language), 'success');
    onSubmit(response.id);
    prescriptionForm.setFieldValue('isSubmitting', false);
  };

  // Map
  const map = (() => {
    const planAnalytic =
      selectedPlan?.plan.analytics[SOIL_ATTRIBUTES]?.[selectedNutrient?.id || -1];
    if (!(samplingPlanSamples && selectedNutrient && selectedPlan && planAnalytic)) {
      return null;
    }

    if (isRemoval) {
      if (generateFrom === YIELD && !harvestYearOptions.length) {
        return <MissingDataMessage field={field} language={language} type={YIELD} />;
      }
      if (generateFrom === IMAGERY && !imageryYearOptions.length) {
        return <MissingDataMessage field={field} language={language} type={IMAGERY} />;
      }
    }

    return (
      <MapThumbnail
        activeAnalytic={selectedNutrient}
        field={field}
        generateFrom={generateFrom}
        harvestData={
          isRemoval
            ? harvest_data_files.find((file) => file.processed_geojson_uri === harvestLayer)
            : null
        }
        imageryData={
          isRemoval
            ? composite_imagery_layers.find((file) => file.geojson_uri === imageryLayer)
            : null
        }
        planAnalytic={planAnalytic}
        proLayer={proLayer}
        samples={samplingPlanSamples}
        samplingPlan={selectedPlan.plan}
      />
    );
  })();

  useEffect(() => {
    prescriptionForm.setFieldValue('maxRate', undefined);
  }, [nutrient]);

  useEffect(() => {
    const { crop_plans } = field.features[0].properties;
    const crop_plan = sortByCreatedAt(crop_plans || [])?.[0];
    prescriptionForm.setValues({
      formula: formula || String(allFormulaOptions?.[0]?.value || ''),
      harvestLayer: harvestLayer || harvestYearOptions[0]?.value || null,
      imageryLayer: imageryLayer || imageryYearOptions[0]?.value || null,
      input: input || inputOptions[0]?.value || '',
      nutrient: nutrient || planNutrientOptions?.[0]?.value || '',
      proDensity:
        proDensity ||
        proDensityOptions.find((option) => option.value === DEFAULT_RX_DENSITY.value)?.value ||
        null,
      samplePlan: samplePlan || samplingPlanOptions[0]?.value || '',
      crop: crop || crop_plan?.crop || CORN,
    });
  }, [
    allFormulaOptions,
    crop,
    field,
    formula,
    harvestLayer,
    harvestYearOptions,
    imageryLayer,
    imageryYearOptions,
    input,
    inputOptions,
    proDensity,
    proDensityOptions,
    samplePlan,
    samplingPlanOptions,
  ]);

  return (
    <Modal
      centered
      size="xl"
      title={getString('createNutrientRx', language)}
      onClose={onClose}
      opened={opened}
    >
      <Flex data-test-id="create-rx-modal-body" gap="md">
        <Stack w="25rem">
          {!isProduction && (
            <SegmentedControl
              // TODO: set order based on field
              data={[
                { label: getString('buildup', language), value: RX_TYPE_BUILDUP },
                { label: getString('removal', language), value: RX_TYPE_REMOVAL },
              ]}
              onChange={handleChangeRxType}
              value={rxType}
            />
          )}
          <Group justify="space-between">
            <Text>{getString('nutrient', language)}:</Text>
            <Select
              data={planNutrientOptions}
              onChange={(val) => val && handleNutrientChange(val)}
              value={nutrient}
            />
          </Group>
          <Group justify="space-between">
            <Text>{getString('generateRxFrom', language)}:</Text>
            <Select
              data={generateFromOptions}
              disabled={rxType === RX_TYPE_BUILDUP}
              onChange={(val) => val && handleGenerateFromChange(val)}
              value={generateFrom}
            />
          </Group>
          <Group justify="space-between">
            <Text>{getString('formula', language)}:</Text>
            <Select
              data={allFormulaOptions}
              onChange={(value) => value && handleFormulaChange(value)}
              value={formula}
            />
          </Group>
          {(!!(proLayer && proDensityOptions.length) || isRemoval) && (
            <Group justify="space-between">
              <Text>{getString('density', language)}:</Text>
              <Select
                value={proDensity}
                onChange={(val) => val && prescriptionForm.setFieldValue('proDensity', val)}
                data={proDensityOptions}
              />
            </Group>
          )}
          {rxType === RX_TYPE_BUILDUP && (
            <Group justify="space-between">
              <Text>{getString('soilTest', language)}:</Text>
              <Select
                value={samplePlan}
                onChange={(val) => val && prescriptionForm.setFieldValue('samplePlan', val)}
                data={samplingPlanOptions}
              />
            </Group>
          )}
          {isRemoval && generateFrom === YIELD && (
            <Group justify="space-between">
              <Text>{capitalize(getString('year', language))}:</Text>
              <Select
                data={harvestYearOptions}
                onChange={(val) => val && prescriptionForm.setFieldValue('harvestLayer', val)}
                value={harvestLayer}
              />
            </Group>
          )}
          {isRemoval && generateFrom === IMAGERY && (
            <Group justify="space-between">
              <Text>{capitalize(getString('year', language))}:</Text>
              <Select
                data={imageryYearOptions}
                onChange={(val) => val && prescriptionForm.setFieldValue('imageryLayer', val)}
                value={imageryLayer}
              />
            </Group>
          )}
          <Group justify="space-between">
            <Text>{getString('input', language)}:</Text>
            <Select
              data={inputOptions}
              onChange={(val) => val && prescriptionForm.setFieldValue('input', val)}
              value={input}
            />
          </Group>
          {isBrazilK && (
            <Group justify="space-between">
              <Text>{getString('maximumRate', language)}:</Text>
              <Input
                data-test-id="rx-maximum-rate"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  prescriptionForm.setFieldValue('maxRate', Number(e.target.value || 0))
                }
                type="number"
                value={maxRate}
              />
            </Group>
          )}
          {generateFrom === IMAGERY && (
            <>
              <Group justify="space-between">
                <Text>{getString('crop', language)}:</Text>
                <Select
                  data={imageryCropOptions}
                  onChange={(val) => val && prescriptionForm.setFieldValue('crop', val)}
                  value={crop}
                />
              </Group>
              <Group justify="space-between">
                <Text>{getString('maxYield', language)}:</Text>
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    prescriptionForm.setFieldValue('maxYield', Number(e.target.value || 0))
                  }
                  type="number"
                  value={maxYield}
                />
              </Group>
              <Group justify="space-between">
                <Text>{getString('minYield', language)}:</Text>
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    prescriptionForm.setFieldValue('minYield', Number(e.target.value || 0))
                  }
                  type="number"
                  value={minYield}
                />
              </Group>
            </>
          )}
        </Stack>
        <Box className={styles.Map}>{map}</Box>
      </Flex>
      <Group justify="flex-end">
        <Button
          className={styles.Submit}
          data-test-id="create-edit-rx"
          disabled={!nutrient || !samplePlan || (isRemoval && !(harvestLayer || imageryLayer))}
          onClick={submit}
          loading={isSubmitting}
        >
          {getString('createAndEditRx', language)}
        </Button>
      </Group>
    </Modal>
  );
};

export default CreatePrescriptionModal;
