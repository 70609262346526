import { Grid } from '@mantine/core';
import { PER_ACRE } from 'constants/pricing';
import { PriceSummary } from 'store/pricing/types';
import { roundTwoDecimal } from 'util/numUtils';
import OrderSummaryGrid from './OrderSummaryGrid';

type Props = {
  productPriceSummary: PriceSummary;
  isListPrice: boolean;
};

const { Col: Column } = Grid;

// TODO: self-serviced vs. EO-serviced
const SummaryLineItem = ({ productPriceSummary, isListPrice }: Props) => {
  const { zones, sku_name, acres, total_price, total_list_price, price_type } = productPriceSummary;
  const priceTotal = isListPrice ? total_list_price : total_price;
  const isPerAcre = price_type === PER_ACRE;
  const dollarsPerZone = !isPerAcre
    ? `$${roundTwoDecimal(priceTotal / zones)?.toLocaleString()}`
    : '';
  const dollarsPerAcre = isPerAcre
    ? `$${roundTwoDecimal(priceTotal / acres)?.toLocaleString()}`
    : '';

  return (
    <OrderSummaryGrid>
      <Column span={2} pl="lg">
        {sku_name}
      </Column>
      <Column span={1} pl="md">
        {zones}
      </Column>
      <Column span={1} pl="sm">
        {dollarsPerZone}
      </Column>
      <Column span={1}>{dollarsPerAcre}</Column>
      <Column span={1}>${priceTotal.toLocaleString()}</Column>
    </OrderSummaryGrid>
  );
};

export default SummaryLineItem;
