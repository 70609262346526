import React from 'react';
import { SimpleGrid, Text } from '@mantine/core';
import { RiskMapboxSample } from 'store/fields/types';
import { RISK_FILL_COLORS } from 'util/mapImageryColors';
import { isNumber } from 'util/numUtils';
import { roundAnalyticValue } from 'util/results';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { SingleAnalyticType } from 'store/analytics/types';
import styles from './ZoneDetails.module.css';

type ZoneDetailRowPropsType = {
  sample: RiskMapboxSample;
  index: number;
  hasElevation: boolean;
  planAnalytic: SingleAnalyticType;
};

const ZoneDetailRow = ({ sample, index, hasElevation, planAnalytic }: ZoneDetailRowPropsType) => {
  const { properties } = sample;

  return (
    <SimpleGrid cols={3}>
      <Text>{index}</Text>
      {hasElevation && <Text>{properties.elevation_level || NOT_APPLICABLE}</Text>}
      <Text
        className={styles.Quantity}
        style={{
          backgroundColor:
            properties['fill-color'] &&
            [RISK_FILL_COLORS.MODERATE_RISK, RISK_FILL_COLORS.HIGH_RISK].includes(
              properties['fill-color'],
            )
              ? properties['fill-color']
              : undefined,
        }}
      >
        {isNumber(properties.quantity)
          ? roundAnalyticValue(properties.quantity)
          : `${roundAnalyticValue(planAnalytic.quantity)}*`}
      </Text>
    </SimpleGrid>
  );
};

export default ZoneDetailRow;
