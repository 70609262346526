import { Select } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { getString } from 'strings/translation';
import { getZoneCreationOptions } from 'util/samplePlan';

type Props = {
  language: string;
  onChange: (zoneType: string) => void;
  isProOrTillRx: boolean;
};

const ZoneTypeSelect = ({ language, isProOrTillRx, onChange }: Props) => {
  const form = useOrderFormContext();
  const formValues = form.getValues();
  const options = getZoneCreationOptions(language, isProOrTillRx);

  return (
    <Select
      key={form.key('creationOption')}
      label={getString('type', language)}
      data={options}
      w="11rem"
      {...form.getInputProps('creationOption')}
      onChange={(zoneType) => {
        if (zoneType) {
          onChange(zoneType);
          form.getInputProps('creationOption').onChange(zoneType);
        }
      }}
      value={formValues.creationOption}
    />
  );
};

export default ZoneTypeSelect;
