import { handleJsonResponse, requestGet, requestPost, requestPut, SERVICE_URL } from 'util/request';
import { queryStringify } from 'util/stringUtils';
import { BillingAddress, PasswordlessUserEmailType, User } from './types';

export const requestUserSet = (page?: number, name?: string, sortby?: string, desc?: boolean) =>
  requestGet(`${SERVICE_URL}/users/?${queryStringify({ page, name, sortby, desc })}`).then(
    handleJsonResponse,
  );

export const requestUserDetails = (id: number) =>
  requestGet(`${SERVICE_URL}/user/${id}`, {
    mock: 'user',
  }).then(handleJsonResponse);

export const requestUserAgencySearch = (name?: string) =>
  requestGet(`${SERVICE_URL}/user_agency_search/?${queryStringify({ name })}`).then(
    handleJsonResponse,
  );

export const requestUserSearch = (name?: string) =>
  requestGet(`${SERVICE_URL}/user_search/?${queryStringify({ name })}`).then(handleJsonResponse);

export const registerUser = (body: Partial<User>) =>
  requestPost(`${SERVICE_URL}/register`, {
    body,
  }).then(handleJsonResponse);

export const updateUserDetails = async (body: Partial<User>, id: number) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/user/${id}`, {
      body,
    }),
  );

export const updateBillingDetails = async (body: BillingAddress, id: number) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/billing_address/${id}`, {
      body,
    }),
  );

export const registerBillingDetails = async (body: BillingAddress, userId: number) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/billing_address/${userId}`, {
      body,
    }),
  );

export const requestOperationAllUsers = async (operationId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/operation_all_users/${operationId}`));

export const requestOperationUsers = async (operationId: number): Promise<{ users: User[] }> =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/operation_agents_operators_samplers/${operationId}`),
  );

export const requestPatternSamplers = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/pattern_samplers/`));

export const requestInitialMetaDetails = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/static_data_for_ui/`));

export const requestRecordUserActivity = async (operation_id: number) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/user_activity_log/`, {
      body: {
        operation_id,
      },
    }),
  );

export const requestUserPrelogin = async (email: string) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/user_prelogin/${email}`));

export const requestUserPasswordlessCode = async (body: Partial<PasswordlessUserEmailType>) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/passwordless_code_request/`, {
      body,
    }),
  );

export const requestVerifyUserPasswordless = async (
  body: Partial<PasswordlessUserEmailType> & { code: string },
) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/passwordless_verify/`, {
      body,
    }),
  );
