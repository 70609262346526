import { Flex } from '@mantine/core';
import SamplingPointsForm, { SamplingPointsFormProps } from './SamplingPointsForm';

type Props = SamplingPointsFormProps & {
  map: React.ReactNode;
};

const SamplingPointsStep = (props: Props) => {
  const { map } = props;

  return (
    <Flex h="100%" w="100%" gap="xl">
      <SamplingPointsForm {...props} />
      {map}
    </Flex>
  );
};

export default SamplingPointsStep;
