import { Button, Space, Stack } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import showToast from 'actions/toastActions';
import { Header } from 'common/Components/Mantine/Header';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Agency, ManageAgencyRouteParams } from 'store/agencies/types';
import { createContractPricing } from 'store/pricing/requests';
import { ContractPricingPayload } from 'store/pricing/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { pricingQueryKeys } from 'util/queryKeys';
import ContractPricingModal from './ContractPricingModal';
import ContractsPricingList from './ContractsPricingList';

type PricingContractContainerPropTypes = {
  agency: Agency;
};

const PricingContractContainer = ({ agency }: PricingContractContainerPropTypes) => {
  const language = useBroswerLanguage();
  const { agencyId } = useParams<ManageAgencyRouteParams>();
  const queryClient = useQueryClient();
  const [showCreatePricingModal, setShowCreatePricingModal] = useState(false);
  const agencyIdString = agencyId as string;

  const createContractPricingMutation = useMutation({
    mutationFn: (body: ContractPricingPayload) => createContractPricing(body),
    onError: (error) => showToast(error.message, 'error'),
    onSuccess: () => {
      showToast('Contract pricing created successfully', 'success');
      queryClient.invalidateQueries({
        queryKey: pricingQueryKeys.contractsByAgency(agencyIdString),
      });
    },
  });

  return (
    <Stack>
      <Header title={`${getString('contractPricing', language)} - ${agency.name}`}>
        <Button variant="outline" onClick={() => setShowCreatePricingModal(true)}>
          {getString('createNewContract', language)}
        </Button>
      </Header>
      {showCreatePricingModal && (
        <ContractPricingModal
          agencyIdString={agencyIdString}
          onClose={() => setShowCreatePricingModal(false)}
          onSubmit={(newPricing) => createContractPricingMutation.mutate(newPricing)}
        />
      )}
      <ContractsPricingList agencyIdString={agencyIdString} />
      <Space h="xl" />
    </Stack>
  );
};

export default PricingContractContainer;
