import React from 'react';
import { Text, Flex, Select, type SelectProps, FlexProps } from '@mantine/core';

type Props = {
  labelId: string;
  labelText: string;
  selectProps: Omit<SelectProps, 'aria-labelledby' | 'label'>;
  rootFlexProps?: FlexProps;
};

const SelectWithInlineLabel = ({ labelId, selectProps, labelText, rootFlexProps }: Props) => {
  return (
    <Flex gap="xs" align="center" {...rootFlexProps}>
      <Text id="analysis-package-label" fw="bold" size="sm">
        {labelText}
      </Text>
      <Select {...selectProps} aria-labelledby={labelId} />
    </Flex>
  );
};

export default SelectWithInlineLabel;
