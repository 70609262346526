import { Accordion, Space } from '@mantine/core';
import { usePriceEstimateQuery } from 'apps/ZoneAnalysisV3/queries';
import { useState } from 'react';
import { OrderSummaryMode, PriceSummary } from 'store/pricing/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getOrderPriceTotalsBySkuType } from 'util/pricing';
import OrderSummaryAccordionControl from './OrderSummaryAccordionControl';
import OrderSummaryColumnHeaders from './OrderSummaryColumnHeaders';
import OrderSummaryModeToggle from './OrderSummaryModeToggle';
import OrderSummaryTotalsFooter from './OrderSummaryTotalsFooter';
import SummaryLineItem from './SummaryLineItem';

const ANALYSIS = 'analysis';
const LIST_PRICE = 'listPrice';
const PRICE = 'price';
const FIELD_WORK = 'fieldWork';

const OrderSummary = () => {
  const language = useBroswerLanguage();
  const { priceEstimateQuery } = usePriceEstimateQuery();
  const { data } = priceEstimateQuery;
  const [mode, setMode] = useState<OrderSummaryMode>(LIST_PRICE);
  const { price_summary = [] } = data || {};
  const isListPrice = mode === LIST_PRICE;

  const displayedProducts = price_summary.reduce(
    (acc: { analysis: PriceSummary[]; fieldWork: PriceSummary[] }, product) => {
      if (!product.is_not_billed || isListPrice) {
        const key = product.sku_type === ANALYSIS ? ANALYSIS : FIELD_WORK;
        acc[key].push(product);
      }
      return acc;
    },
    { analysis: [], fieldWork: [] },
  );

  const calculateTotalPrice = (products: PriceSummary[]) =>
    products.reduce(
      (acc, product) => {
        const key = isListPrice ? LIST_PRICE : PRICE;
        acc[key] += isListPrice ? product.total_list_price : product.total_price;
        return acc;
      },
      { price: 0, listPrice: 0 },
    );

  const analysisPrices = getOrderPriceTotalsBySkuType(displayedProducts.analysis, isListPrice);
  const fieldWorkPrices = getOrderPriceTotalsBySkuType(displayedProducts.fieldWork, isListPrice);
  const totalAnalysisPrice = calculateTotalPrice(displayedProducts.analysis);
  const totalFieldWorkPrice = calculateTotalPrice(displayedProducts.fieldWork);

  return (
    <>
      <OrderSummaryModeToggle language={language} mode={mode} onChange={setMode} />
      <Space />
      <OrderSummaryColumnHeaders language={language} />
      <Accordion w="100%" multiple>
        <Accordion.Item value={ANALYSIS}>
          <OrderSummaryAccordionControl
            perZoneTotalPrice={analysisPrices.perZone}
            perAcreTotalPrice={analysisPrices.perAcre}
            totalPrice={totalAnalysisPrice[isListPrice ? LIST_PRICE : PRICE]}
            titleText={getString('analysis', language)}
            zoneCount={displayedProducts.analysis[0]?.zones}
          />
          <Accordion.Panel>
            {displayedProducts.analysis.map((product) => (
              <SummaryLineItem
                key={product.sku_id}
                productPriceSummary={product}
                isListPrice={isListPrice}
              />
            ))}
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value={FIELD_WORK}>
          <OrderSummaryAccordionControl
            perZoneTotalPrice={fieldWorkPrices.perZone}
            perAcreTotalPrice={fieldWorkPrices.perAcre}
            totalPrice={totalFieldWorkPrice[isListPrice ? LIST_PRICE : PRICE]}
            titleText={getString('fieldWork', language)}
            zoneCount={displayedProducts.fieldWork[0]?.zones}
          />
          <Accordion.Panel>
            {displayedProducts.fieldWork.map((product) => (
              <SummaryLineItem
                key={product.sku_id}
                productPriceSummary={product}
                isListPrice={isListPrice}
              />
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      {!!data && (
        <OrderSummaryTotalsFooter
          language={language}
          totalPrice={isListPrice ? data?.estimated_list_price : data?.estimated_price}
          pricePerAcre={
            isListPrice ? data?.estimated_list_price_per_acre : data?.estimated_price_per_acre
          }
        />
      )}
    </>
  );
};

export default OrderSummary;
