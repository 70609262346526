import { Button, Group, NumberInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { getString } from 'strings/translation';
import { useContractPricingFormContext } from './contractPricingContext';

type Props = {
  language: string;
  onCancelClick: () => void;
};

const ContractPricingInputs = ({ language, onCancelClick }: Props) => {
  const form = useContractPricingFormContext();
  const { isDirty } = form;

  return (
    <Group
      align="end"
      style={{ zIndex: 3, borderTop: '1px solid var(--mantine-color-gray-3)' }}
      pos="sticky"
      bottom={0}
      py="md"
      bg="var(--mantine-color-body)"
    >
      <DatePickerInput
        key={form.key('signedAt')}
        label={getString('signedAt', language)}
        required
        w="12rem"
        withAsterisk
        {...form.getInputProps('signedAt')}
      />
      <DatePickerInput
        key={form.key('expiredAt')}
        label={getString('expiredOn', language)}
        required
        w="12rem"
        withAsterisk
        {...form.getInputProps('expiredAt')}
      />
      <NumberInput
        key={form.key('committedSamples')}
        label={getString('committed_samples', language)}
        min={1}
        required
        withAsterisk
        {...form.getInputProps('committedSamples')}
      />
      <Group ml="auto">
        <Button variant="outline" onClick={onCancelClick}>
          {getString('cancel', language)}
        </Button>
        <Button type="submit" variant="filled" disabled={!isDirty()}>
          {getString('saveContractChanges', language)}
        </Button>
      </Group>
    </Group>
  );
};

export default ContractPricingInputs;
