import {
  RECEIVE_TIMINGS,
  REQUEST_TIMINGS,
  TimingActionsType,
  TimingsResponseItemType,
  TIMINGS_REQUEST_ERROR,
} from './types';

export const requestTimings = (): TimingActionsType => ({
  type: REQUEST_TIMINGS,
});

export const receiveTimings = (payload: {
  current_crop_year: number;
  current_timing: string;
  sampling_timings: TimingsResponseItemType[];
}): TimingActionsType => ({
  type: RECEIVE_TIMINGS,
  payload,
});

export const timingsRequestError = (): TimingActionsType => ({
  type: TIMINGS_REQUEST_ERROR,
});
