import { Divider, Space, Title } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { roundTwoDecimal } from 'util/numUtils';
import { getAcreageUnitFromLang } from 'util/units';

type Props = {
  name: string;
  acreage: number;
};

const ZoneAnalysisV3Header = ({ name, acreage }: Props) => {
  const language = useBroswerLanguage();
  const headerTitleName = `${name} (${roundTwoDecimal(acreage)} ${getAcreageUnitFromLang(language)})`;
  const headerTitle = `${headerTitleName} - ${getString('editSamplePlan', language)}`;

  return (
    <div>
      <Space h="md" />
      <Title order={2}>{headerTitle}</Title>
      <Divider my="md" size="sm" />
    </div>
  );
};

export default ZoneAnalysisV3Header;
