import { useNavigate } from 'react-router-dom';
import { featureCollection } from '@turf/helpers';
import { useDispatch } from 'react-redux';
import { Button, Group } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { NITRATE_PANEL } from 'constants/products';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { FieldType } from 'store/fields/types';
import { deleteSamplePlan, postSamplePlan } from 'store/samplePlans/requests';
import { SamplePlanType } from 'store/samplePlans/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { setupSamplesToSubmitV3, getSampleGroupings } from 'util/samplePlanV3';
import AnalysisProductPrice from './AnalysisProductPrice';
import { getNavButtonsText } from './utils';
import showToast from 'actions/toastActions';
import {
  useAllSkusQuery,
  useOperationUsersQuery,
  usePriceEstimateQuery,
} from 'apps/ZoneAnalysisV3/queries';
import { checkSkuHasProductFromList, getSkuById } from 'util/pricing';
import { STANDARD_NUTRIENT_SKU_ID } from 'constants/pricing';
import { operationAllowsMultiplePlans } from 'util/samplePlan';
import { useNumericRouteParams } from 'apps/ZoneAnalysisV3/hooks';
import { getFieldGeometry } from 'store/fields/thunks';

type Props = {
  activeStepNumber: number;
  onStepClick: (nextStep: number) => void;
  fieldFeatureCollection: FieldType;
};

const StepperFooter = ({ activeStepNumber, onStepClick, fieldFeatureCollection }: Props) => {
  const { operationId, fieldId, planId } = useNumericRouteParams();
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { data: allSkus } = useAllSkusQuery();
  const { data: operationUsers } = useOperationUsersQuery();
  const { priceEstimateQuery } = usePriceEstimateQuery();
  const form = useOrderFormContext();
  const formValues = form.getValues();
  const transformValues = form.getTransformedValues();
  const prevNext = getNavButtonsText(formValues, form.getTransformedValues(), language);
  const { previous, next } = prevNext[activeStepNumber];
  const isFinalStep = activeStepNumber === Object.keys(prevNext).length - 1;
  const isMultiPlanCapable = operationAllowsMultiplePlans(operationUsers?.users);

  const orderMutation = useMutation({
    onError: (error) => {
      showToast(error.message, 'error');
    },
    mutationFn: async () => {
      const productizedFeatures = setupSamplesToSubmitV3(
        formValues,
        transformValues,
        fieldFeatureCollection,
        allSkus || [],
      );

      const is590Analysis = getSkuById(
        allSkus || [],
        formValues.nutrientAnalysisPackageId || STANDARD_NUTRIENT_SKU_ID,
      )?.is_590_analysis;

      const body: Partial<SamplePlanType> = {
        agency_id: formValues.agencyId,
        assigned_to_id: formValues.sampledById || null,
        assigned_to_scan_id: formValues.scannedById || null,
        field_id: fieldFeatureCollection.features[0].properties.id,
        is_590_analysis: is590Analysis,
        is_pro: formValues.isProScan,
        is_till_rx: formValues.isTillRx,
        name: formValues.samplePlanName,
        notes: formValues.samplerInstructions,
        nutrient_external_lab_identifier: formValues.externalLabKey || undefined,
        ready_to_sample: formValues.readyToSample,
        rnd: formValues.rnd,
        sample_timing: formValues.sampleTiming || undefined,
        sku_prices: priceEstimateQuery.data?.price_summary, // 👈 causes 500
        zone_type: formValues.creationOption,
        // @ts-ignore
        samples: featureCollection(productizedFeatures),
      };

      if (formValues.isSplitDensity) {
        const nutrientSku = getSkuById(
          allSkus || [],
          formValues.nutrientAnalysisPackageId || STANDARD_NUTRIENT_SKU_ID,
        );
        body.sample_groupings = getSampleGroupings(
          productizedFeatures,
          checkSkuHasProductFromList(nutrientSku, [NITRATE_PANEL]),
        );
      }
      if (planId && isMultiPlanCapable) {
        await postSamplePlan(body);
        return deleteSamplePlan(planId);
      }
      return postSamplePlan(body);
    },
    onSuccess: () => {
      dispatch(getFieldGeometry(fieldId));
      navigate(`/orders/${operationId}`);
    },
  });

  const hasRequiredZones = () =>
    (transformValues.isSampleAndScan && formValues.zones && formValues.scanPoints) ||
    (transformValues.isScanPointsOnly && formValues.scanPoints) ||
    (transformValues.isSampleOnly && formValues.zones);

  return (
    <Group
      bg="var(--mantine-color-body)"
      justify="space-between"
      py="md"
      mx="auto"
      w="100%"
      maw={1000}
      style={{
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: 'var(--mantine-color-gray-3)',
      }}
    >
      <AnalysisProductPrice language={language} />
      <Group>
        {!!previous && (
          <Button
            size="lg"
            leftSection={<FaArrowLeft width={32} style={{ padding: 0 }} />}
            variant="default"
            onClick={() => onStepClick(activeStepNumber - 1)}
          >
            {previous}
          </Button>
        )}
        <Button
          size="lg"
          rightSection={isFinalStep ? null : <FaArrowRight width={32} style={{ padding: 0 }} />}
          loading={orderMutation.isPending}
          disabled={isFinalStep && !hasRequiredZones()}
          onClick={() => {
            if (isFinalStep) {
              orderMutation.mutate();
            } else {
              onStepClick(activeStepNumber + 1);
            }
          }}
        >
          {next}
        </Button>
      </Group>
    </Group>
  );
};

export default StepperFooter;
