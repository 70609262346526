import { Box, Button } from '@mantine/core';
import center from '@turf/center';
import { Feature, featureCollection, MultiPolygon, Point, Polygon } from '@turf/helpers';
import union from '@turf/union';
import showToast, { type ToastType } from 'actions/toastActions';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import useMapboxGl from 'common/MapHooks';
import { type ViewPortProps } from 'common/Maps/types';
import {
  CIRCLE,
  FILL,
  GROUPED_ZONES,
  GROUP_MAX_OPACITY,
  LINE,
  PARTIAL_ANALYTICS,
  PARTIAL_CIRCLE,
  PARTIAL_ZONE,
  POINT,
  POLYGON,
  STATIC_OUTLINE,
  WHITE_OUTLINE,
} from 'constants/mapbox';
import mapboxgl, { GeoJSONSourceRaw, Layer } from 'mapbox-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FieldType } from 'store/fields/types';
import { getString } from 'strings/translation';
import { splitMultipolygon } from 'util/geospatial';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { removeMapLayer } from 'util/mapbox';
import { setLayerFunction } from './mapUtils';
import useAnalysisMapSetup from './useAnalysisMapSetup';
import useBioZoneDisplay from './useBioZoneDisplay';

interface MapProps {
  field: FieldType;
  drawRef: { current: any | null };
}

const SplitDensityMap = ({ field, drawRef }: MapProps) => {
  const language = useBroswerLanguage();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);
  const form = useOrderFormContext();
  const formValues = form.getValues();

  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];
  const [currentLayerIds, setGroupLayerIds] = useState<string[]>([]);
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
    width: 0,
    height: 0,
  });

  const displayToast = (message: string, type?: ToastType, time?: number) =>
    showToast(message, type, time);

  useMapboxGl(mapContainerRef, mapRef, drawRef, viewport, setViewport, () => {}, true);
  const { mapHasLoaded, initialViewport } = useAnalysisMapSetup(
    mapRef,
    wrapperRef,
    field,
    () => {},
    setViewport,
    viewport,
  );

  const resetStaticLine = useCallback(() => {
    if (mapRef.current?.getLayer(STATIC_OUTLINE)) {
      mapRef.current?.setPaintProperty(STATIC_OUTLINE, 'line-opacity', 0.1);
    }
  }, [mapRef]);

  const removeGroupLayers = useCallback(() => {
    setGroupLayerIds((currentLayers) => {
      currentLayers.forEach((layer) => {
        removeMapLayer(mapRef, layer);
        removeMapLayer(mapRef, `${layer}-outline`);
      });
      return [];
    });
  }, [mapRef]);

  const setLayer = useCallback(
    (id: string, source: GeoJSONSourceRaw, layer: Partial<Layer>) =>
      setLayerFunction(mapRef, id, source, layer),
    [mapRef],
  );

  useBioZoneDisplay(mapRef, mapHasLoaded);

  const handleGroupedZones = useCallback(() => {
    const groupLayers =
      formValues.zones?.features.reduce(
        (groups, feature) => {
          const groupId = feature.properties?.sample_group;
          if (groupId) {
            const layerName = `${GROUPED_ZONES}-${groupId}`;
            if (!groups[layerName]) {
              groups[layerName] = [feature];
            } else {
              groups[layerName].push(feature);
            }
          }
          return groups;
        },
        {} as { [name: string]: Feature<Polygon | Point>[] },
      ) || {};
    // Clear existing group layers
    currentLayerIds.forEach((layer) => {
      removeMapLayer(mapRef, layer);
      removeMapLayer(mapRef, `${layer}-outline`);
    });
    setGroupLayerIds([]);
    const layerNames = Object.keys(groupLayers);
    if (layerNames.length) {
      setGroupLayerIds((currentLayers) => currentLayers.concat(layerNames));
      try {
        const opacityIncrement = GROUP_MAX_OPACITY / (layerNames.length + 1);
        layerNames.forEach((layer, index) => {
          const source = {
            type: 'geojson',
            data: featureCollection(groupLayers[layer]),
          } as GeoJSONSourceRaw;
          if (formValues.zoneGeomType === POLYGON) {
            try {
              const unionizedGroup = union(...(groupLayers[layer] as Feature<Polygon>[]));
              const groupZones = splitMultipolygon(
                unionizedGroup as Feature<MultiPolygon | Polygon>,
              );
              source.data = featureCollection(groupZones);
            } catch (error) {
              source.data = featureCollection(groupLayers[layer]);
            }
          }

          if (formValues.zoneGeomType === POINT) {
            setLayer(layer, source, {
              type: CIRCLE,
              paint: {
                ...PARTIAL_CIRCLE,
                'circle-opacity': opacityIncrement * (index + 1),
              },
            });
          } else {
            setLayer(layer, source, {
              type: FILL,
              paint: {
                ...PARTIAL_ZONE,
                'fill-opacity': opacityIncrement * (index + 1),
              },
            });
            setLayer(`${layer}-outline`, source, {
              type: LINE,
              paint: {
                ...WHITE_OUTLINE,
                'line-opacity': 0.5,
              },
            });
          }
        });
      } catch (e) {
        // log error to expose topology exceptions to the client console for debugging
        // eslint-disable-next-line
        console.error(e);
        displayToast(getString('unableToDisplayZoneGroupingError', language), 'error');
      }
    }
  }, [formValues.zones, formValues.zoneGeomType, setLayer, removeGroupLayers]);

  useEffect(() => {
    if (mapHasLoaded && mapRef) {
      removeMapLayer(mapRef, PARTIAL_ANALYTICS);
      handleGroupedZones();
      resetStaticLine();
    }
  }, [mapRef, mapHasLoaded, handleGroupedZones, removeGroupLayers, resetStaticLine]);

  const recenterMap = () => {
    setViewport(initialViewport);
    if (mapRef.current) {
      mapRef.current.setZoom(initialViewport.zoom);
      mapRef.current.setCenter([initialViewport.longitude, initialViewport.latitude]);
    }
  };

  return (
    <Box h="100%" pos="relative" ref={wrapperRef} flex={1}>
      <Box ref={mapContainerRef} h="100%" w="100%" />
      <Button variant="white" onClick={recenterMap} bottom={10} pos="absolute" right="3rem">
        {getString('recenter', language)}
      </Button>
    </Box>
  );
};
export default SplitDensityMap;
