import { Flex } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import SplitDensitySection from '../ZoneInputs/SplitDensitySection';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { PERFORMANCE_PANEL } from 'constants/products';

const SplitDensityForm = () => {
  const form = useOrderFormContext();
  const formValues = form.getValues();
  const language = useBroswerLanguage();

  return (
    <Flex w={425} h="100%" style={{ overflowY: 'auto' }}>
      <SplitDensitySection
        language={language}
        showCompleteBioLabel={formValues.products.includes(PERFORMANCE_PANEL)}
      />
    </Flex>
  );
};

export default SplitDensityForm;
