import { Box, Divider, Group } from '@mantine/core';
import { useOperationQuery } from 'apps/ZoneAnalysisV3/queries';
import AnalysisSamplingSection from './AnalysisSamplingSection';
import SamplePlanDetailsSection from './SamplePlanDetailsSection';
import SamplingSection from './SamplingSection';

const AnalysisSamplingStep = () => {
  const { data: operation } = useOperationQuery();

  return (
    <Box maw={1000} mx="auto">
      <AnalysisSamplingSection />
      <Divider my="xl" />
      {!!operation && (
        <Group gap="xl" align="flex-start">
          <SamplingSection operation={operation} />
          <SamplePlanDetailsSection operation={operation} />
        </Group>
      )}
    </Box>
  );
};

export default AnalysisSamplingStep;
