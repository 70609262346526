import { Button, List } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import showToast from 'actions/toastActions';
import { useState } from 'react';
import { updateContractPricing } from 'store/pricing/requests';
import { ContractPricingPostMutationVars, ContractPricingType } from 'store/pricing/types';
import { pricingQueryKeys } from 'util/queryKeys';
import { contractsPricingQueryOptions } from 'util/queryOptions';
import ContractPricingModal from './ContractPricingModal';

type Props = {
  agencyIdString: string;
};

const ContractsPricingList = ({ agencyIdString }: Props) => {
  const queryClient = useQueryClient();

  const [selectedContractPricing, setSelectedContractPricing] =
    useState<ContractPricingType | null>(null);

  const { data: contractsQueryData } = useQuery({
    ...contractsPricingQueryOptions(agencyIdString),
  });

  const editContractPricingMutation = useMutation({
    mutationFn: ({ contractId, payload }: ContractPricingPostMutationVars) =>
      updateContractPricing(contractId as number, payload),
    onError: (error) => showToast(error.message, 'error'),
    onSuccess: () => {
      showToast('Contract pricing updated successfully', 'success');

      queryClient.invalidateQueries({
        queryKey: pricingQueryKeys.contractsByAgency(agencyIdString),
      });
    },
  });

  return (
    <>
      <List listStyleType="none">
        {contractsQueryData
          ?.filter((contractPricing) => contractPricing.agency_id)
          .map((contractPricing, i) => (
            <List.Item key={i}>
              <Button
                onClick={() => setSelectedContractPricing(contractPricing)}
                variant="subtle"
                size="md"
              >
                Signed at {contractPricing.signed_at}
              </Button>
            </List.Item>
          ))}
      </List>
      {!!selectedContractPricing && (
        <ContractPricingModal
          agencyIdString={agencyIdString}
          contractPricing={selectedContractPricing}
          onClose={() => setSelectedContractPricing(null)}
          onSubmit={(payload) =>
            editContractPricingMutation.mutate({
              contractId: selectedContractPricing.id,
              payload,
            })
          }
        />
      )}
    </>
  );
};

export default ContractsPricingList;
