import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiAlertTriangle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import {
  Stack,
  Grid,
  Text,
  Image,
  Paper,
  Center,
  Popover,
  Group,
  NumberInput,
  Tooltip,
} from '@mantine/core';

import classNames from 'classnames';
import { RootState } from 'store';
import { US } from 'constants/countries';
import { getCategoryFromParams } from 'util/results';
import { AnalyticType } from 'store/analytics/types';
import { OperationFieldType } from 'store/operation/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getCropImage, getOtherCrops } from 'util/overviewResultsDisplay';
import { CORN, SOYBEANS } from 'constants/variables';
import { getChartAnalytics, getCropPlanRowValues, getLatestCropPlan } from 'util/cropPlans';
import { CatalogType } from 'store/catalogs/types';
import {
  allCategories,
  CROP_PLAN_HEADERS,
  OPEN_RISKS,
  OUTSTANDING_RISK_COLOR,
  SEED,
  TRAITS,
} from 'constants/cropPlan';
import { putPostCropTargetYield } from 'store/cropPlans/thunks';
import { RISK_FILL_COLORS } from 'util/mapImageryColors';
import { FieldType } from 'store/fields/types';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { getString } from 'strings/translation';

import Styles from './FieldRow.module.css';
import CropPlanChart from './CropPlanChart';

type ParamsType = {
  operationId: string;
  analysis: string;
};

type FieldRowPropsType = {
  field: OperationFieldType;
  fieldGeometry: FieldType | undefined;
  cropType: string;
  hasFailedGeometry: boolean;
  isFetchingGeometry: boolean;
  willFetch: boolean;
  defaultCatalog: CatalogType | null;
};

const FieldRow = ({
  field,
  fieldGeometry,
  hasFailedGeometry,
  isFetchingGeometry,
  willFetch,
  cropType,
  defaultCatalog,
}: FieldRowPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const { analysis } = useParams<ParamsType>();
  const category = getCategoryFromParams(analysis);
  const cropPlan = getLatestCropPlan(fieldGeometry);

  const [targetYield, setTargetYield] = useState<number | string>('');
  const [openChart, toggleOpenChart] = useState<boolean>(false);
  const headerList = CROP_PLAN_HEADERS[category];
  const [selectedChartInfo, setSelecteChartInfo] = useState<{
    label: string;
    value: string | number;
  } | null>(null);

  const { allAnalytics, analyticSortOrder } = useSelector((state: RootState) => ({
    allAnalytics: state.analytics.analytics,
    pestGroups: state.cropPlanning.pestGroups,
    analyticSortOrder: state.analytics.analyticSortOrder,
  }));

  const countryCode = fieldGeometry?.features[0].properties.country_code || US;

  const analytics = getChartAnalytics(
    analysis,
    cropType,
    allAnalytics,
    analyticSortOrder,
    countryCode,
  ) as AnalyticType[];

  const oustandingAnalytics = analytics.reduce((list, analytic) => {
    const analyticId =
      analytic.display_config.secondary_analytic_id &&
      cropPlan?.outstanding_risks.analytics.hasOwnProperty(
        analytic.display_config.secondary_analytic_id,
      )
        ? analytic.display_config.secondary_analytic_id
        : analytic.id;
    const outstandingAnalytic = cropPlan?.outstanding_risks.analytics[analyticId];
    if (outstandingAnalytic?.adjusted_risk && outstandingAnalytic.adjusted_risk > 1) {
      return list.concat([analytic.id]);
    }
    return list;
  }, [] as number[]).length;
  const hasOpenRisks = cropPlan?.outstanding_risks?.analytics !== undefined;

  const rowValues = getCropPlanRowValues(fieldGeometry, oustandingAnalytics).filter((row) =>
    [...headerList, OPEN_RISKS].includes(row.label),
  );

  useEffect(() => {
    if (cropPlan) {
      setTargetYield(cropPlan.target_yield_per_acre);
    }
  }, [cropPlan]);

  const setCropTypeOnPlan = async (crop: string, target?: string | number) => {
    dispatch(putPostCropTargetYield(language, field.id, crop, cropPlan?.id, target));
  };

  const addRemoveRecommendedInput = (inputSelected: { label: string; value: string | number }) => {
    if (inputSelected.label === selectedChartInfo?.label) {
      setSelecteChartInfo(null);
    } else {
      setSelecteChartInfo(inputSelected);
      toggleOpenChart(true);
    }
  };

  useEffect(() => {
    if (
      (openChart && selectedChartInfo === null) ||
      (selectedChartInfo?.label && !headerList.includes(selectedChartInfo.label))
    ) {
      setSelecteChartInfo(rowValues[0]);
    } else if (!openChart && selectedChartInfo !== null) {
      setSelecteChartInfo(null);
    }
  }, [openChart, selectedChartInfo, rowValues, headerList]);

  const getOpenRiskColor = (value: number | string, index: number) => {
    if (hasOpenRisks && index === rowValues.length - 1) {
      if (value > 0) {
        return OUTSTANDING_RISK_COLOR;
      }
      return RISK_FILL_COLORS.LOW_RISK;
    }
    return '';
  };

  if (!fieldGeometry && !isFetchingGeometry && !hasFailedGeometry && !willFetch) {
    return <></>;
  }

  const isEditable = cropPlan && [CORN, SOYBEANS].includes(cropPlan.crop);

  const getDisplay = (rowValue: { label: string; value: string | number }) => {
    if (oustandingAnalytics) {
      // show warning if crop plan has outstanding risks and category is unprotected
      if (rowValue.value === NOT_APPLICABLE && rowValue.label !== OPEN_RISKS) {
        return <FiAlertTriangle color="red" />;
      }
    }
    return (
      <Text truncate="end" size="sm">
        {rowValue.value}
      </Text>
    );
  };
  const getTooltip = (rowValue: { label: string; value: string | number }) => {
    if (oustandingAnalytics) {
      // show warning if crop plan has outstanding risks and category is unprotected
      if (rowValue.value === NOT_APPLICABLE && rowValue.label !== OPEN_RISKS) {
        if ([SEED, TRAITS].includes(rowValue.label)) {
          return getString('seedMissing', language);
        }
        return getString(`${rowValue.label}Missing`, language);
      }
    }
    return rowValue.value;
  };

  const headerCount = CROP_PLAN_HEADERS[category].length;
  return (
    <>
      <Grid gutter="xs" columns={4 + allCategories.length}>
        <Grid.Col span={2}>
          <Stack
            gap={0}
            className={Styles.Hover}
            justify="center"
            h="3rem"
            onClick={() => toggleOpenChart(!openChart)}
          >
            <Text size="xs" fw={700}>
              {field.farm_name ? `${field.farm_name}: ${field.name}` : field.name}
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col span={1}>
          <Popover width="auto" trapFocus position="bottom" withArrow shadow="md">
            <Popover.Target>
              <Paper shadow="sm" withBorder className={Styles.CropSelect}>
                <Center h="3rem">
                  <Image h="2rem" w="auto" src={getCropImage(cropPlan?.crop)} />
                </Center>
              </Paper>
            </Popover.Target>
            <Popover.Dropdown>
              <Group gap="sm">
                {getOtherCrops(cropType).map((crop) => (
                  <Image
                    key={crop}
                    h="2rem"
                    w="auto"
                    src={getCropImage(crop)}
                    className={Styles.CropImgBox}
                    onClick={() => setCropTypeOnPlan(crop)}
                  />
                ))}
              </Group>
            </Popover.Dropdown>
          </Popover>
        </Grid.Col>
        <Grid.Col span={1}>
          <Paper shadow="sm" className={Styles.CropSelect}>
            <NumberInput
              hideControls
              value={targetYield}
              onBlur={() => setCropTypeOnPlan(cropType || CORN, targetYield)}
              onChange={setTargetYield}
              size="xs"
              classNames={{
                input: Styles.CropInput,
              }}
            />
          </Paper>
        </Grid.Col>
        {rowValues.map((rowVal, index) => (
          <Grid.Col
            span={allCategories.length / headerCount}
            key={rowVal.label}
            onClick={() =>
              index !== rowValues.length - 1 &&
              isEditable &&
              cropPlan.crop &&
              addRemoveRecommendedInput(rowVal)
            }
          >
            <Tooltip label={getTooltip(rowVal)} bg="blue" disabled={index === rowValues.length - 1}>
              <Paper
                bg={getOpenRiskColor(rowVal.value, index)}
                shadow={selectedChartInfo?.label === rowVal.label ? 'xl' : 'md'}
                withBorder={selectedChartInfo?.label === rowVal.label}
                className={classNames(
                  Styles.RowValue,
                  selectedChartInfo?.label === rowVal.label ? Styles.Border : undefined,
                )}
                onClick={() =>
                  index === rowValues.length - 1 && isEditable && toggleOpenChart(!openChart)
                }
              >
                {getDisplay(rowVal)}
              </Paper>
            </Tooltip>
          </Grid.Col>
        ))}
      </Grid>
      {fieldGeometry && openChart && cropPlan && (
        <CropPlanChart
          analytics={analytics}
          cropType={cropType}
          defaultCatalog={defaultCatalog}
          fieldGeometry={fieldGeometry}
          selectedChartInfo={selectedChartInfo}
          cropPlan={cropPlan}
        />
      )}
    </>
  );
};

export default FieldRow;
