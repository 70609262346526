import { Accordion, Grid, Text, Title } from '@mantine/core';
import OrderSummaryGrid from './OrderSummaryGrid';

type Props = {
  perAcreTotalPrice: number;
  perZoneTotalPrice: number;
  totalPrice: number;
  titleText: string;
  zoneCount: number;
};

const PaddedColumn = ({ text }: { text: string | number }) => {
  return (
    <Grid.Col span={1}>
      <Text pl="sm">{text}</Text>
    </Grid.Col>
  );
};

const OrderSummaryAccordionControl = ({
  perAcreTotalPrice,
  perZoneTotalPrice,
  totalPrice,
  titleText,
  zoneCount,
}: Props) => {
  const perZoneTotalPriceString = perZoneTotalPrice ? `$${perZoneTotalPrice.toLocaleString()}` : '';
  const perAcreTotalPriceString = perAcreTotalPrice ? `$${perAcreTotalPrice.toLocaleString()}` : '';
  return (
    <Accordion.Control>
      <OrderSummaryGrid>
        <Grid.Col span={2}>
          <Title fw="bold" order={4}>
            {titleText}
          </Title>
        </Grid.Col>
        <PaddedColumn text={zoneCount} />
        <PaddedColumn text={perZoneTotalPriceString} />
        <PaddedColumn text={perAcreTotalPriceString} />
        <PaddedColumn text={`$${totalPrice.toLocaleString()}`} />
      </OrderSummaryGrid>
    </Accordion.Control>
  );
};

export default OrderSummaryAccordionControl;
