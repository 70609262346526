import { Button, Group, Stack, Text } from '@mantine/core';
import { featureCollection, FeatureCollection, Point, Polygon } from '@turf/helpers';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { ZONE_TYPES } from 'constants/mapbox';
import { NUTRIENT_PANEL } from 'constants/products';
import { CUSTOM_POINTS, densityOptions, GRID_POINTS, GRID_ZONES } from 'constants/samplePlanning';
import { GeoJsonProperties } from 'geojson';
import { FieldType } from 'store/fields/types';
import { ANALYSIS_TYPES } from 'store/zoneAnalysisV2/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { isNutrientPanel } from 'util/product';
import { getDensity, getSubmitPointsMsg, is590DensityValid, isGridsOption } from 'util/samplePlan';
import acToHectares, { getAcreageUnitFromLang } from 'util/units';
import styles from '../../Form.module.css';
import ProPointScanDensitySelect from './ProPointScanDensitySelect';
import ProPointTypeSelect from './ProPointTypeSelect';
import UploadGridPointsButton from '../ZoneInputs/UploadGridPointsButton';
import { PreviewZonesType } from 'store/pricing/types';

export type SamplingPointsFormProps = {
  fieldFeatureCollection: FieldType;
  resetZones: (
    creationType:
      | typeof ANALYSIS_TYPES.CREATION_OPTION
      | typeof ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
    creationOption: string,
  ) => void;
  resetPreviewScanGrid: (dens: number) => void;
  drawRef: any;
  createFinalizedGrid: (
    gridType: typeof GRID_POINTS | typeof GRID_ZONES,
    previewZones: PreviewZonesType,
  ) => Promise<
    | FeatureCollection<Point, GeoJsonProperties>
    | FeatureCollection<Polygon, GeoJsonProperties>
    | null
  >;
};

const ScanPointsForm = ({
  resetZones,
  resetPreviewScanGrid,
  createFinalizedGrid,
  drawRef,
  fieldFeatureCollection,
}: SamplingPointsFormProps) => {
  const language = useBroswerLanguage();

  const form = useOrderFormContext();
  const formValues = form.getValues();

  const isProGridPoints = formValues.proPointCreationOption === GRID_POINTS;
  const isProCustomPoints = formValues.proPointCreationOption === CUSTOM_POINTS;
  const { acreage_unit, acreage } = fieldFeatureCollection.features[0]?.properties;

  const setupEditLockScanPoints = async () => {
    form.setValues({
      disableScanMapTools: false,
    });
    if (isProCustomPoints) {
      return resetZones(ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION, CUSTOM_POINTS);
    }
    if (formValues.editScanPoints) {
      const pointsMapped = isProGridPoints
        ? await createFinalizedGrid(GRID_POINTS, formValues.previewScanPoints)
        : drawRef.current.getAll();
      form.setValues({
        scanPoints: featureCollection(
          pointsMapped?.features?.map((feat) => ({
            ...feat,
            properties: {
              ...feat.properties,
              zone_type: ZONE_TYPES.POINT,
            },
          })) || [],
        ),
        zonesLocked: true,
        disableScanMapTools: false,
        editScanPoints: false,
        previewScanPoints: null,
      });
    } else {
      form.setValues({
        disableScanMapTools: true,
        editScanPoints: true,
        scanPoints: null,
      });
      resetPreviewScanGrid(formValues.scanDensity);
    }
  };

  const selectScanCreationType = (creationOption?: string) => {
    form.setValues({
      editScanPoints: true,
      disableScanMapTools: !!isGridsOption(creationOption || formValues.proPointCreationOption),
    });
    resetZones(
      ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
      creationOption || formValues.proPointCreationOption,
    );
  };

  const getProPointButtonText = () => {
    if (isProCustomPoints) {
      return getString('resetPoints', language);
    }
    return getString(formValues.editScanPoints ? 'lockPoints' : 'generatePoints', language);
  };

  const isProNutrientDisabled = !formValues.zonesLocked && !isNutrientPanel(formValues.products);

  const is590Invalid = !is590DensityValid(
    formValues.is590Analysis,
    formValues.zones,
    formValues.isProScan,
    fieldFeatureCollection,
  );

  const scanPointsCount = formValues.scanPoints?.features?.length || 0;
  const scanPointsDensity = getDensity(scanPointsCount, acreage) || 0;

  return (
    <Stack w={425} h="100%" style={{ overflowY: 'auto' }}>
      <Group gap="sm" align="flex-end" wrap="nowrap" className={styles.HighlightedColor}>
        <ProPointTypeSelect
          language={language}
          showTillMapperLabel={formValues.isTillRx && !formValues.products.includes(NUTRIENT_PANEL)}
          disabled={isProNutrientDisabled}
          onChange={(val) => val && selectScanCreationType(val)}
        />
        {isProGridPoints && (
          <ProPointScanDensitySelect
            language={language}
            disabled={isProNutrientDisabled}
            options={densityOptions[acreage_unit]}
            onChange={(density) => {
              if (density && formValues.editScanPoints) {
                resetPreviewScanGrid(Number(density));
              }
            }}
          />
        )}
        <UploadGridPointsButton
          language={language}
          disabled={isProNutrientDisabled}
          onMutate={() => selectScanCreationType()}
        />
        <Button variant="outline" onClick={setupEditLockScanPoints}>
          {getProPointButtonText()}
        </Button>
      </Group>
      <Group>
        <Text
          size="xs"
          fs="italic"
          c={
            getSubmitPointsMsg(
              formValues.isProScan,
              formValues.isTillRx,
              formValues.scanPoints,
              fieldFeatureCollection,
            ) || is590Invalid
              ? 'darkRed'
              : 'blue'
          }
        >
          {getString('points', language)}: <b>{scanPointsCount}</b>
        </Text>
        <Text
          size="xs"
          fs="italic"
          c={
            getSubmitPointsMsg(
              formValues.isProScan,
              formValues.isTillRx,
              formValues.scanPoints,
              fieldFeatureCollection,
            ) || is590Invalid
              ? 'darkRed'
              : 'blue'
          }
        >
          {getString('density', language)}:{' '}
          <b>
            {acToHectares(scanPointsDensity, acreage_unit)} {getAcreageUnitFromLang(language)} /{' '}
            {getString('zone', language).toLowerCase()}
          </b>
        </Text>
      </Group>
    </Stack>
  );
};

export default ScanPointsForm;
