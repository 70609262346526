import React from 'react';
import { Box, BoxProps, Text } from '@mantine/core';

import styles from './MapLegend.module.css';
import { LegendGradient } from './LegendGradient';
import { LEGEND_MAX_WIDTH } from 'constants/mapbox';

type Props = {
  colorStops: string[];
  marks?: (number | string)[];
  showPlus?: boolean;
  title: React.ReactNode;
  rootBoxProps?: BoxProps;
  maxWidth?: number;
};

export const LegendWrap = ({
  colorStops,
  marks,
  showPlus,
  title,
  rootBoxProps = {},
  maxWidth,
}: Props) => {
  return (
    <Box
      className={styles.legendWrap}
      px="xs"
      pt={4}
      pb="md"
      {...rootBoxProps}
      style={{
        maxWidth: maxWidth || LEGEND_MAX_WIDTH,
        ...rootBoxProps.style,
      }}
    >
      <Text
        fw="bold"
        component="h3"
        fz="sm"
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {title || ' '}
      </Text>
      <LegendGradient colorStops={colorStops} marks={marks} showPlus={showPlus} />
    </Box>
  );
};
