import React, { useState } from 'react';
import { Box } from '@mantine/core';
import { Accordion, AdminAgentAccess } from 'common';
import { NO_DATA as NoDataText } from 'constants/defaultValues';
import { RX_DETAILS, SUMMARY } from 'constants/results';
import { AnalyticType, SingleAnalyticType } from 'store/analytics/types';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';
import { RecommendationType } from 'store/recommendations/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getFieldRisk, getResultRecommendation, roundAnalyticValue } from 'util/results';
import { capitalize } from 'util/stringUtils';
import Recommendations from '../../../common/Recommendations';
import styles from './SingleAnalytic.module.css';
import CompactionSummary from './CompactionSummary';

type FormPropsType = {
  activeAnalytic: AnalyticType;
  analyticAccordionsOpen: string[];
  field: FieldType;
  operation: OperationType;
  planAnalytic: SingleAnalyticType;
  recommendations: RecommendationType[];
  samplingPlan: SamplingPlanType;
  toggleAccordion: (arg0: string) => void;
  tmpShowRxDetails?: boolean;
};

const CompactionAnalytic = ({
  operation,
  samplingPlan,
  activeAnalytic,
  analyticAccordionsOpen,
  toggleAccordion,
  planAnalytic,
  recommendations,
  tmpShowRxDetails,
  field,
}: FormPropsType) => {
  const language = useBroswerLanguage();
  const [editRecommendation, toggleEditRecommendation] = useState(false);
  const risk = getFieldRisk(planAnalytic, samplingPlan, activeAnalytic);

  const recommendation = getResultRecommendation(
    recommendations,
    activeAnalytic.id,
    planAnalytic.risk_level,
  ) as RecommendationType;

  return (
    <Box className={styles.SingleWrapper}>
      {editRecommendation && planAnalytic && recommendation && (
        <AdminAgentAccess>
          <Recommendations
            className={styles.EditRecommendation}
            analyticId={activeAnalytic.id}
            riskLevel={planAnalytic.risk_level}
            samplingPlan={samplingPlan}
            recommendation={recommendation}
            operation={operation}
            onClose={() => toggleEditRecommendation(false)}
          />
          <div className={styles.Overlay} />
        </AdminAgentAccess>
      )}
      <div className={styles.AccordionPrint}>
        <Accordion
          title={getString('summary', language)}
          toggleOpen={() => toggleAccordion(SUMMARY)}
          open={analyticAccordionsOpen.includes(SUMMARY)}
          placeholder={`${activeAnalytic.name}, ${getString(
            'fieldAvg',
            language,
          )}: ${roundAnalyticValue(planAnalytic.quantity)} ${planAnalytic.unit || ''}, (${capitalize(
            planAnalytic.risk_level || NoDataText,
          )} ${getString('risk', language)})`}
        >
          <CompactionSummary
            field={field}
            planAnalytic={planAnalytic}
            recommendation={recommendation}
            risk={risk}
            toggleEditRecommendation={toggleEditRecommendation}
          />
        </Accordion>
        {tmpShowRxDetails && (
          <Accordion
            title={getString('rxDetails', language)}
            toggleOpen={() => toggleAccordion(RX_DETAILS)}
            open={analyticAccordionsOpen.includes(RX_DETAILS)}
            placeholder={`placeholder`}
          >
            <div>Details....</div>
          </Accordion>
        )}
      </div>
    </Box>
  );
};

export default CompactionAnalytic;
