import React from 'react';
import { Box, Center, NavLink, Text } from '@mantine/core';
import classNames from 'classnames';

import { FieldType } from 'store/fields/types';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { getString } from 'strings/translation';
import { IMAGERY, YIELD } from 'constants/prescription';

import styles from './CreatePrescriptionModal.module.css';
import mapStyles from './MapThumbnail.module.css';

interface MissingDataMessageProps {
  field: FieldType;
  language: string;
  type: typeof IMAGERY | typeof YIELD;
}

const MissingDataMessage = ({ field, language, type }: MissingDataMessageProps) => {
  const { operation_id } = field.features[0].properties;
  return (
    <Box className={mapStyles.FieldMap}>
      <Box className={classNames(mapStyles.MapWrapper, styles.NoHarvest)} mt="xs" p="sm">
        <Center>
          <Text fw={FONT_WEIGHT_BOLD} size="xl">
            {getString(type === YIELD ? 'yieldDataNotFound' : 'imageryNotFound', language)}
          </Text>
        </Center>
        <Text>
          {getString(
            type === YIELD ? 'yieldDataNotFoundDescription' : 'imageryNotFoundDescription',
            language,
          )}
        </Text>
        <NavLink
          href={`/operations/${operation_id}/ingest_history`}
          label={getString('manualUpload', language)}
          td="underline"
        />
        <NavLink
          href="/connected-accounts"
          label={getString('connectOutsideAccounts', language)}
          td="underline"
        />
      </Box>
    </Box>
  );
};

export default MissingDataMessage;
