import { Box, Grid } from '@mantine/core';
import { getString } from 'strings/translation';
import OrderSummaryGrid from './OrderSummaryGrid';

const { Col: Column } = Grid;

type Props = {
  language: string;
};

const OrderSummaryColumnHeaders = ({ language }: Props) => {
  return (
    <Box px="md" w="100%">
      <OrderSummaryGrid>
        <Column fw="bold" span={2}>
          {getString('product', language)}
        </Column>
        <Column fw="bold" span={1}>
          {getString('zones', language)}
        </Column>
        <Column fw="bold" span={1}>
          {getString('pricePerZone', language)}
        </Column>
        <Column fw="bold" span={1}>
          {getString('pricePerAcre', language)}
        </Column>
        <Column fw="bold" span={1}>
          {getString('totalPrice', language)}
        </Column>
      </OrderSummaryGrid>
    </Box>
  );
};

export default OrderSummaryColumnHeaders;
