import { Container, Space, Stack, Text } from '@mantine/core';
import OrderSummary from './OrderSummary/OrderSummary';
import SamplingExecution from './OrderSummary/SamplingExecution';

const ReviewOrderStep = () => {
  return (
    <Container size="md">
      <Stack align="center">
        <Text fz="h2" fw="bold" component="h2">
          Review Order
        </Text>
        <OrderSummary />
        <Space />
        <SamplingExecution />
      </Stack>
    </Container>
  );
};

export default ReviewOrderStep;
