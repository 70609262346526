import { Flex, Stack, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { useOperationUsersQuery } from 'apps/ZoneAnalysisV3/queries';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { sampleTimingsQueryOptions } from 'util/queryOptions';
import ReadyToSampleCheckbox from './ReadyToSampleCheckbox';

const SamplingExecution = () => {
  const language = useBroswerLanguage();
  const operationUsersQuery = useOperationUsersQuery();
  const sampleTimingsQuery = useQuery(sampleTimingsQueryOptions);
  const form = useOrderFormContext();
  const { sampledById, scannedById, sampleTiming } = form.getValues();

  const sampledBy = operationUsersQuery.data?.users.find(({ id }) => id === sampledById);
  const scannedBy = operationUsersQuery.data?.users.find(({ id }) => id === scannedById);

  const sampleTimingText = sampleTimingsQuery.data?.sampling_timings.find(
    ({ value }) => value === sampleTiming,
  )?.display_name;

  return (
    <Stack px="lg" gap="sm">
      <Title order={4} fw="bold">
        {getString('sampleExecution', language)}
      </Title>
      <Stack px="lg" gap="sm">
        <Flex
          rowGap="sm"
          columnGap="lg"
          display="grid"
          style={{ gridTemplateColumns: 'auto auto' }}
          w="fit-content"
        >
          {!!sampledBy && (
            <>
              <Text fw="bold">{getString('sampledBy', language)}</Text>
              <Text>
                {sampledBy.first_name} {sampledBy.last_name}
              </Text>
            </>
          )}
          {!!scannedBy && (
            <>
              <Text fw="bold">{getString('scannedBy', language)}</Text>
              <Text>
                {scannedBy.first_name} {scannedBy.last_name}
              </Text>
            </>
          )}
          <Text fw="bold" tt="capitalize">
            {getString('sampleTiming', language)}
          </Text>
          <Text>{sampleTimingText}</Text>
        </Flex>
        <Text size="sm">{getString('planConfMsg', language)}</Text>
        <ReadyToSampleCheckbox />
      </Stack>
    </Stack>
  );
};

export default SamplingExecution;
