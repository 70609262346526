import React, { useState } from 'react';
import { Button, Group, Select, Space, Stack, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';
import { FiSearch } from 'react-icons/fi';
import { Header } from 'common/Components/Mantine/Header';
import { Typeahead } from 'common/Components/Mantine/Typeahead';
import { AGRONOMIC_PRODUCTS, CATALOG_INPUT_TYPES, SEED, SEEDS } from 'constants/cropPlan';
import { CORN, SOYBEANS } from 'constants/variables';
import { AgronomicProductType, SeedType } from 'store/cropPlans/types';
import { requestGetProductSearch, requestGetSeedSearch } from 'store/cropPlans/requests';
import { DEBOUNCE } from 'util/request';
import { CatalogType } from 'store/catalogs/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_STANDARD } from 'constants/mantine';
import { getCropOptions, sortInputsByCoverage } from 'util/cropPlans';

import InputModal from './Common/InputModal';
import { getCategoryFromParams } from 'util/results';

interface ProductSearchProps {
  closeModal: VoidFunction;
  isSaving: boolean;
  modalOpened: boolean;
  onError: (msg: string) => void;
  openModal: VoidFunction;
  toggleIsSaving: (saving: boolean) => void;
  catalog: Partial<CatalogType>;
}

const ProductSearch = ({
  closeModal,
  isSaving,
  modalOpened,
  onError,
  openModal,
  toggleIsSaving,
  catalog,
}: ProductSearchProps) => {
  const language = useBroswerLanguage();
  const [productType, setProductType] = useState<string>(SEED);
  const [crop, setCrop] = useState<string>(CORN);
  const [trait, setTrait] = useState('');

  const isSeed = productType === SEED;

  const [products, setProducts] = useState<AgronomicProductType[]>([]);
  const [seeds, setSeeds] = useState<SeedType[]>([]);

  const [selectedItem, setSelectedItem] = useState<
    Partial<SeedType> | Partial<AgronomicProductType> | null
  >(null);
  const [itemName, setItemName] = useState('');

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const debounceFetchProducts = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: AgronomicProductType[] = await requestGetProductSearch(
          name,
          crop,
          productType,
        );
        setProducts(response);
      } catch (error) {
        onError('Failed to fetch agronomic products.');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );
  const debounceFetchSeeds = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: SeedType[] = await requestGetSeedSearch(name, crop);
        setSeeds(response);
      } catch (error) {
        onError('Failed to fetch seeds.');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );

  const handleSelect = (value: string) => {
    const optionId = parseInt(value, 10);
    if (optionId === 0) {
      setSelectedItem({ crop: crop, analytic_category: productType });
      openModal();
    } else {
      const item = isSeed
        ? seeds.find((p) => p.id === optionId)
        : products.find((p) => p.id === optionId);
      if (item) {
        setItemName(isSeed ? (item as SeedType).hybrid : (item as AgronomicProductType).name);
        setSelectedItem(item);
      }
    }
  };

  const handleProductNameChange = (name: string) => {
    setItemName(name);
    if (name && !isSaving) {
      if (isSeed) {
        debounceFetchSeeds(name);
      } else {
        debounceFetchProducts(name);
      }
    }
  };

  const options = (() => {
    const data = isSeed
      ? seeds.map((seed) => ({
          id: seed.id,
          label: seed.hybrid,
          fw: seed.ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
          ratings: seed.ratings,
        }))
      : products.map((product) => ({
          id: product.id,
          label: product.name,
          fw: product.ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
          ratings: product.ratings,
        }));
    const sorted = sortInputsByCoverage(data);
    return [
      {
        id: 0,
        label: getString(isSeed ? 'createCustomSeed' : 'createCustomProduct', language),
        value: 0,
      },
    ].concat(
      sorted.map((p) => ({
        id: p.id,
        label: p.label,
        value: p.id,
      })),
    );
  })();

  const cropOptions = getCropOptions(language, [CORN, SOYBEANS]);

  const reset = () => {
    setSelectedItem(null);
    setItemName('');
    setTrait('');
  };

  const handleCloseModal = () => {
    reset();
    closeModal();
  };

  const catalogTypesOptions = CATALOG_INPUT_TYPES.map((cat) => ({
    label: getString(cat, language),
    value: cat === SEED ? cat : getCategoryFromParams(cat),
  }));

  const handleProductTypeSelection = (value: string) => {
    const pType = catalogTypesOptions.find((o) => o.value === value)?.value;
    if (pType) {
      reset();
      setProductType(pType);
    }
  };

  return (
    <Stack>
      <Header title={getString('addProducts', language)} divider="sm" order={3} />
      <Group justify="space-between" align="flex-end" grow>
        <Select
          data={catalogTypesOptions}
          label={getString('type', language)}
          onChange={(value) => value && handleProductTypeSelection(value)}
          value={productType}
        />
        {productType === SEED ? (
          <Select
            data={cropOptions}
            label={getString('crop', language)}
            onChange={(c) => (c ? setCrop(c) : () => {})}
            value={crop}
          />
        ) : (
          <TextInput disabled readOnly />
        )}
        <Typeahead
          value={itemName}
          onTextChange={handleProductNameChange}
          onSelect={handleSelect}
          onDeselect={reset}
          data={options}
          placeholder={getString('product', language)}
          leftSection={<FiSearch />}
          disabled={Boolean(selectedItem)}
        />
        <TextInput
          disabled={productType !== SEED}
          label={getString('trait', language)}
          onChange={() => {}}
          value={trait}
          readOnly
        />
        <Button
          color="#112F63"
          disabled={!selectedItem || modalOpened}
          onClick={openModal}
          radius="lg"
          variant="filled"
        >
          + {getString('addProduct', language)}
        </Button>
      </Group>
      <Space h="sm" />
      {modalOpened ? (
        <InputModal
          closeModal={handleCloseModal}
          isTemporary
          modalOpened={modalOpened}
          catalog={catalog}
          data={selectedItem || {}}
          type={isSeed ? SEEDS : AGRONOMIC_PRODUCTS}
        />
      ) : null}
    </Stack>
  );
};

export default ProductSearch;
