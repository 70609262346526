import turfBbox from '@turf/bbox';
import turfArea from '@turf/area';
import turfCircle from '@turf/circle';
import turfCenter from '@turf/center';
import { M3 } from 'constants/analysis';
import { US } from 'constants/countries';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import {
  AWAITING_RESULTS,
  HIGH_RISK,
  LOW_RISK,
  MODERATE_RISK,
  ND,
  NOT_ANALYZED,
  NOT_DETECTED,
  NO_DATA,
  RISK_AREA_TYPES,
} from 'constants/fieldRisks';
import { PORTUGUESE } from 'constants/languages';
import {
  NUTRIENT_PANEL,
  PRESSURE_PANEL,
  ROOTWORM_PRESSURE_PANEL,
  SCN_PRESSURE_PANEL,
} from 'constants/products';
import {
  allPhIds,
  ALUMINUM_ID,
  analyticToMethodMap,
  ANALYTIC_DISPLAY_CATEGORIES,
  BCSR,
  BIOFERTILITY,
  bufferPhIds,
  COMPACTION,
  CROP_PROTECTION,
  FOLIAR,
  macronutrientIds,
  micronutrientIds,
  NORTHERN_CORN_ROOTWORM_ID,
  NUTRIENTS,
  NUTRIENT_PANEL_NOT_ANALYZED,
  OM_ID,
  OM_MEASURED_ID,
  PATHOGENS,
  PERFORMANCE_PANEL_NOT_ANALYZED,
  phosphorusIds,
  PRESSURE_PANEL_NOT_ANALYZED,
  qpcrAnalyticIds,
  ROOTWORM_ID,
  RX,
  SCN_ID,
  SOIL_ATTRIBUTES,
  SOIL_HEALTH,
  SUGAR_BEETS,
  WESTERN_CORN_ROOTWORM_ID,
} from 'constants/results';
import { CANCELLED, WONT_SAMPLE } from 'constants/samples';
import { CORN } from 'constants/variables';
import { Feature, Geometry } from 'geojson';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike, Map as MapboxMap } from 'mapbox-gl';
import {
  AnalyticSortOrderType,
  AnalyticType,
  RiskRangesType,
  SingleAnalyticType,
} from 'store/analytics/types';
import { FieldType, MapboxSample, SampleType, SamplingPlanType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';
import { RecommendationType } from 'store/recommendations/types';
import { SamplePlanType } from 'store/samplePlans/types';
import { getString } from 'strings/translation';
import {
  ACRE_SIZE_AT_POINT_RADIUS,
  CUSTOM_POINTS,
  DISPLAY_POINT_RADIUS,
  GRID_POINTS,
  GRID_ZONES,
} from '../constants/samplePlanning';
import { SampleGeoJSON } from './generalTypes';
import { squareMetersToAcres } from './geospatial';
import { NOT_ANALYZED_FILL, NO_DATA_FILL, RISK_FILL_COLORS, WHITE } from './mapImageryColors';
import { isNumber, roundThreeDecimal, roundTwoDecimal } from './numUtils';
import { sortAnalyticsById } from './sortByName';
import { FIELD_OUTLINE, LEGEND_MAX_WIDTH } from 'constants/mapbox';
import { getProLayerPopupContent } from 'apps/Results/common/MapPopup';

export const analyticPreferenceList = (operation: OperationType, analytics: AnalyticType[]) => {
  if (!operation) {
    return [];
  }

  return analytics.filter((analytic) => showAnalyticForMethodPreference(operation, analytic));
};

const showAnalyticForMethodPreference = (operation: OperationType, analytic: AnalyticType) => {
  const checkPreference = (preference: string | undefined) => {
    const method = analyticToMethodMap[analytic.id];
    if (!preference) {
      return method === M3;
    }
    return preference === method;
  };
  if (analytic.id === ALUMINUM_ID) {
    // Aluminum is analyzed with resin analysis and does not map to
    // other procedures. Always return true here and filter downstream.
    return true;
  }

  if (analytic.category === SOIL_ATTRIBUTES) {
    const preferences = operation.result_preferences;
    if (phosphorusIds.includes(analytic.id)) {
      return checkPreference(preferences?.p_method_shown);
    }
    if (macronutrientIds.includes(analytic.id)) {
      return checkPreference(preferences?.ca_k_mg_method_shown);
    }
    if (micronutrientIds.includes(analytic.id)) {
      return checkPreference(preferences?.micro_method_shown);
    }
  }

  return true;
};

export const getAnalytic = (
  sampleOrSamplePlan: SamplingPlanType | SampleType | undefined | null,
  category: string,
  id: number,
) => {
  if (id === OM_ID) {
    return getOmAnalytic(sampleOrSamplePlan);
  }
  return sampleOrSamplePlan?.analytics[category]?.[id];
};

export const getRiskDisplayName = (analyticId: number, risk: string, language: string) =>
  bufferPhIds.includes(analyticId) ? NOT_APPLICABLE : getString(`${risk}Risk`, language);

export const getSampleAnalyticQuantity = (
  sample: SampleType,
  activeAnalytic: AnalyticType,
): number | null => {
  const analyticValue = getAnalytic(sample, activeAnalytic.category, activeAnalytic.id)?.quantity;
  // Checking if key exists. OK even if value is None
  return isNumber(analyticValue) ? analyticValue : null;
};

export const roundAnalyticValue = (value: number | null | undefined) => {
  if (!value) {
    return value;
  }

  if (value > 0 && value < 0.01) {
    return '<0.01';
  }
  return roundThreeDecimal(value);
};

export const getPlanRiskColor = (riskLevel: string | undefined) => {
  switch (riskLevel) {
    case HIGH_RISK:
      return RISK_FILL_COLORS.HIGH_RISK;
    case MODERATE_RISK:
      return RISK_FILL_COLORS.MODERATE_RISK;
    case LOW_RISK:
      return RISK_FILL_COLORS.LOW_RISK;
    default:
      return NO_DATA_FILL;
  }
};

export const getAnalyticFillColor = (
  sample: SampleType,
  activeAnalytic: AnalyticType,
  samplingPlan?: SamplingPlanType,
) => {
  const displayConfig = activeAnalytic?.display_config;
  if (sample.status === WONT_SAMPLE) {
    return NOT_ANALYZED_FILL;
  }
  const analyticValue = getAnalytic(sample, activeAnalytic.category, activeAnalytic.id);
  const interpolatedValue =
    samplingPlan && getInterpolatedValue(sample, activeAnalytic, samplingPlan);
  if (displayConfig?.is_sample_nd) {
    if (!analyticValue?.quantity && interpolatedValue === ND) {
      return RISK_FILL_COLORS.LOW_RISK;
    }
    return RISK_FILL_COLORS.HIGH_RISK;
  }
  // Checking if key exists. OK even if value is None
  if (
    !analyticValue ||
    !analyticValue.hasOwnProperty('risk_level') ||
    (analyticValue.quantity === null && !bufferPhIds.includes(activeAnalytic.id))
  ) {
    return getPlanRiskColor(
      samplingPlan?.analytics[activeAnalytic.category]?.[activeAnalytic.id]?.risk_level,
    );
  }
  return getPlanRiskColor(analyticValue.risk_level);
};

export const getSampleAnalyticRiskLevel = (sample: SampleType, activeAnalytic: AnalyticType) => {
  const analyticValue = getAnalytic(sample, activeAnalytic.category, activeAnalytic.id);
  return analyticValue?.risk_level || null;
};

export const getPlanRiskLevel = (planAnalytic?: SingleAnalyticType | null) => {
  if (planAnalytic?.risk_level) {
    return planAnalytic.risk_level;
  }
  if (planAnalytic?.hasOwnProperty('risk_level')) {
    return NO_DATA;
  }
  if (planAnalytic) {
    return AWAITING_RESULTS;
  }

  return NOT_ANALYZED;
};

export const getFieldRisk = (
  planAnalytic: SingleAnalyticType | undefined,
  samplingPlan: SamplingPlanType,
  activeAnalytic: AnalyticType,
) => {
  const riskLevel = planAnalytic?.risk_summary?.risk_level_order;
  const displayConfig = activeAnalytic?.display_config;
  if (riskLevel === RISK_AREA_TYPES.BINARY && planAnalytic?.quantity) {
    return HIGH_RISK;
  }
  if (riskLevel === RISK_AREA_TYPES.BINARY_REVERSE) {
    return planAnalytic?.quantity ? LOW_RISK : HIGH_RISK;
  }
  if (displayConfig?.is_field_nd && planAnalytic?.risk_level === LOW_RISK) {
    return NOT_DETECTED;
  }
  if (planAnalytic?.risk_level) {
    return planAnalytic.risk_level;
  }
  if (planAnalytic?.hasOwnProperty('risk_level')) {
    return NO_DATA;
  }
  if (
    !samplingPlan.product ||
    (samplingPlan.product === ROOTWORM_PRESSURE_PANEL && activeAnalytic.id !== ROOTWORM_ID) ||
    (samplingPlan.product === SCN_PRESSURE_PANEL && activeAnalytic.id !== SCN_ID)
  ) {
    return NOT_ANALYZED;
  }
  return AWAITING_RESULTS;
};

export const getResultRecommendation = (
  recommendations: RecommendationType[] | null | undefined,
  analytic_id: number,
  risk?: string | null,
  all: boolean = false,
) => {
  const translatedRisk = risk || LOW_RISK;
  const matchingRec = (recommendations || []).filter(
    (r: RecommendationType) => r.analytic_id === analytic_id && r.risk_level === translatedRisk,
  );

  return all ? matchingRec : matchingRec[0];
};

/** Get the min and max sample quantity for a given analytic. */
export const getMinMaxSampleRange = (
  samples: MapboxSample[],
  analytic: AnalyticType,
): {
  lowest: number;
  highest: number;
} => {
  const minMax = samples.reduce(
    (all: { lowest: number; highest: number }, single) => {
      if (single.geometry) {
        const sampleValue = getSampleAnalyticQuantity(single.properties, analytic);
        if (sampleValue !== null && isNumber(sampleValue)) {
          return {
            lowest: sampleValue < all.lowest ? sampleValue : all.lowest,
            highest: sampleValue > all.highest ? sampleValue : all.highest,
          };
        }
      }
      return all;
    },
    { lowest: 100000, highest: 0 },
  );

  return minMax;
};

/** Get 2-digit string of min and max sample quantity as "lowest - highest". */
export const getSampleRangeText = (lowest: number, highest: number): string =>
  `${roundAnalyticValue(lowest)} - ${roundAnalyticValue(highest)}`;

export const getPercentAtRiskAcres = (samples: MapboxSample[], analytic: AnalyticType) => {
  return samples
    .filter((sample) => sample.geometry !== null)
    .reduce((total, sample) => {
      const riskLevel = getSampleAnalyticRiskLevel(sample.properties, analytic);
      if (riskLevel === HIGH_RISK || riskLevel === MODERATE_RISK) {
        const area = squareMetersToAcres(turfArea(sample));
        return total + area;
      }
      return total;
    }, 0);
};

export const getTotalSamplesAtRisk = (samples: MapboxSample[], analytic: AnalyticType) => {
  return samples.filter((sample) => {
    if (sample.geometry === null) {
      return false;
    }
    const riskLevel = getSampleAnalyticRiskLevel(sample.properties, analytic);
    return riskLevel === HIGH_RISK || riskLevel === MODERATE_RISK;
  }).length;
};

export const getMidwestBenchmarkText = (midwestBenchmark: SingleAnalyticType | null) => {
  const highRiskText = midwestBenchmark?.risk_summary.high
    ? `, ${midwestBenchmark?.risk_summary.high} are high risk.`
    : '.';

  return midwestBenchmark?.risk_summary
    ? `Midwest benchmarks indicate levels of ${midwestBenchmark.risk_summary.moderate}
      are moderate risk${highRiskText}`
    : '';
};
export const getDomainMaxFromRiskRanges = (riskRanges: RiskRangesType) => {
  return Math.max(
    ...([
      ...(riskRanges.low?.flat() || []),
      ...(riskRanges.moderate?.flat() || []),
      ...(riskRanges.high?.flat() || []),
    ] as number[]),
  );
};

// Check if a quantity falls into a risk range
const isQuantityInRange = (range: number[] | undefined, quantity: number) => {
  return range && quantity >= range[0] && quantity <= range[1];
};

// Get the risk range that a specific quantity falls into OR if it's the highest
// range, then quantity above it will fall into that range
export const getRiskSetFromQuantity = (
  range: number[][] | undefined,
  quantity: number | undefined,
  isHighestLevel: boolean | undefined,
) => {
  if (!range?.length || !quantity) {
    return null;
  }
  if (
    isQuantityInRange(range[1], quantity) ||
    (range[1] && isHighestLevel && quantity > Math.max(...range[1]))
  ) {
    return range[1];
  }
  if (
    isQuantityInRange(range[0], quantity) ||
    (range[0] && isHighestLevel && quantity > Math.max(...range[0]))
  ) {
    return range[0];
  }
  return null;
};

export const getCategoryFromParams = (analysis: string | undefined) => {
  if (analysis === CROP_PROTECTION) {
    return PATHOGENS;
  }
  if (analysis === FOLIAR) {
    return FOLIAR;
  }
  if (analysis === SUGAR_BEETS) {
    return SUGAR_BEETS;
  }
  if (analysis === NUTRIENTS || analysis === RX) {
    return SOIL_ATTRIBUTES;
  }
  if (analysis === BCSR) {
    return BCSR;
  }
  if (analysis === COMPACTION) {
    return COMPACTION;
  }
  return BIOFERTILITY;
};

export const getAnalyticFromPlan = (
  plan: SamplingPlanType | undefined | null,
  analytic: AnalyticType,
) => getAnalytic(plan, analytic.category, analytic.id);

export const rangeToString = (range: number[] | undefined | null, decimalLength?: number) => {
  if (!range?.length) {
    return '';
  }
  return isNumber(decimalLength)
    ? `${range[0].toFixed(decimalLength)} - ${range[1].toFixed(decimalLength)}`
    : `${range[0]} - ${range[1]}`;
};

export const riskRangeToString = (range: number[][] | undefined | null, decimalLength?: number) =>
  range
    ? range
        .filter((single) => single.length)
        .map((single) => rangeToString(single, decimalLength))
        .join(', ')
    : '';

const fulfillAnalyticsFromConfig = (
  analyticSortConfig: { [crop: string]: number[] },
  analyticsPerTab: AnalyticType[],
) =>
  Object.keys(analyticSortConfig).reduce(
    (all, cat) => ({
      ...all,
      [cat]: sortAnalyticsById(
        analyticsPerTab
          .filter((analytic) => (analyticSortConfig?.[cat] || []).includes(analytic.id))
          .map((analytic) => ({
            ...analytic,
            subCategory: cat,
          })),
        analyticSortConfig[cat],
      ),
    }),
    {},
  );

const getAnalyticsInTab = (
  analyticIdsForCrops: { [crop: string]: number[] },
  analytics: AnalyticType[],
  countryCode: string,
) => {
  const analyticIdsPerTab = Array.from(
    new Set(Object.values(analyticIdsForCrops).flatMap((ids) => ids)),
  );
  return analytics.filter(
    (analytic) =>
      analyticIdsPerTab.includes(analytic.id) &&
      analytic.display_config?.countries?.includes(countryCode),
  );
};

export const getSubCategoryAnalyticsV2 = (
  analytics: AnalyticType[],
  fieldGeometries: FieldType[],
  analyticIdsForCrops: { [crop: string]: number[] },
) => {
  const countryCode = fieldGeometries?.[0]?.features[0].properties.country_code || US;
  const analyticsPerTab = getAnalyticsInTab(analyticIdsForCrops, analytics, countryCode);
  return {
    analyticsPerTab,
    subCategoryAnalytics: fulfillAnalyticsFromConfig(analyticIdsForCrops, analyticsPerTab),
  };
};

export const getAnalyticId = (activeAnalytic: AnalyticType, samplingPlan: SamplePlanType) => {
  if (activeAnalytic.id === OM_ID) {
    if (samplingPlan.analytics[activeAnalytic.category]?.[OM_ID]) {
      return OM_ID;
    }
    return OM_MEASURED_ID;
  }
  return activeAnalytic.id;
};

export const checkEnlargePointZone = (
  sample: MapboxSample | SampleGeoJSON | null,
  samplingPlan: SamplingPlanType,
  acreSize: number,
  field?: FieldType,
) => {
  if (sample === null || !field) {
    return false;
  }
  if (
    samplingPlan.is_pro &&
    samplingPlan.zone_type === GRID_ZONES &&
    sample.properties.zone_type === 'point'
  ) {
    return true;
  }
  const sampleSize = squareMetersToAcres(turfArea(sample));
  const fieldSize = squareMetersToAcres(turfArea(field));
  return (
    sampleSize < ACRE_SIZE_AT_POINT_RADIUS &&
    fieldSize > acreSize &&
    samplingPlan.zone_type &&
    [CUSTOM_POINTS, GRID_POINTS].includes(samplingPlan.zone_type)
  );
};

export const sortByRiskColor = (samples: GeoJSON.Feature<GeoJSON.Geometry, SampleType>[]) => {
  return Object.values(
    samples.reduce(
      (all: any, sample) => {
        if (sample === null) {
          return all;
        }
        if (sample.properties['fill-color'] === RISK_FILL_COLORS.HIGH_RISK) {
          return {
            ...all,
            high: [...all.high, sample],
          };
        }
        if (sample.properties['fill-color'] === RISK_FILL_COLORS.MODERATE_RISK) {
          return {
            ...all,
            moderate: [...all.moderate, sample],
          };
        }
        return {
          ...all,
          others: [...all.others, sample],
        };
      },
      { others: [], moderate: [], high: [] },
    ),
  ).flatMap((all) => all) as GeoJSON.Feature<GeoJSON.Geometry, SampleType>[];
};

const getSeasonDisplayName = (year: number, language: string) => {
  const seasonString = getString('season', language);
  if (language === PORTUGUESE) {
    return `${seasonString} ${year}`;
  }

  return `${year} ${seasonString}`;
};

export const getCropYearOptionsV2 = (cropYears: number[], language: string) => {
  if (cropYears?.length) {
    return cropYears.map((year) => ({
      label: getSeasonDisplayName(year, language),
      value: String(year),
    }));
  }

  const currentYear = new Date().getFullYear();
  return [
    {
      label: getSeasonDisplayName(currentYear, language),
      value: String(currentYear),
    },
  ];
};

const getAnalyzedKeyForAnalytic = (analytic: AnalyticType) => {
  if (qpcrAnalyticIds.includes(analytic.id)) {
    return PRESSURE_PANEL_NOT_ANALYZED;
  }
  switch (analytic.category) {
    case BIOFERTILITY:
      return PERFORMANCE_PANEL_NOT_ANALYZED;
    case SOIL_HEALTH:
      return PERFORMANCE_PANEL_NOT_ANALYZED;
    case SOIL_ATTRIBUTES:
      return NUTRIENT_PANEL_NOT_ANALYZED;
    default:
      return null;
  }
};

const cancelledNullOrOMValues = (activeAnalytic: AnalyticType, samplingPlan: SamplingPlanType) => {
  const displayConfig = activeAnalytic?.display_config;
  const newQuantity = getAnalytic(
    samplingPlan,
    activeAnalytic.category,
    activeAnalytic.id,
  )?.quantity;
  if (displayConfig?.is_sample_nd && !newQuantity) {
    return ND;
  }
  if (!bufferPhIds.includes(activeAnalytic.id)) {
    return `${roundAnalyticValue(newQuantity)}*`;
  }
  return 'N/A';
};

const getInterpolatedValue = (
  properties: SampleType,
  activeAnalytic: AnalyticType,
  samplingPlan: SamplingPlanType,
) => {
  const displayConfig = activeAnalytic?.display_config;
  const keyForAnalysis = getAnalyzedKeyForAnalytic(activeAnalytic);
  const quantity = getSampleAnalyticQuantity(properties, activeAnalytic);
  if (
    properties.status === CANCELLED ||
    (keyForAnalysis && properties[keyForAnalysis] === true) ||
    quantity === null
  ) {
    return cancelledNullOrOMValues(activeAnalytic, samplingPlan);
  }
  if (displayConfig?.is_sample_nd && quantity === 0) {
    return ND;
  }
  return roundAnalyticValue(quantity);
};

export const adjustSamplesForInterpolation = (
  features: Feature<Geometry, SampleType & { quantity: number }>[],
  activeAnalytic: AnalyticType,
  samplingPlan: SamplingPlanType,
) =>
  features.map((f) => ({
    ...f,
    properties: {
      ...f.properties,
      quantity: getInterpolatedValue(f.properties, activeAnalytic, samplingPlan),
      'fill-color': getAnalyticFillColor(f.properties, activeAnalytic, samplingPlan),
    },
  })) as Feature<Geometry, SampleType & { quantity: number }>[];

export const getOmQuantities = (samples: MapboxSample[]) => {
  const values = samples.map((sample) => getOmAnalytic(sample.properties)?.quantity || 0);
  if (!samples.length) {
    return {
      average: 0,
      max: 0,
      min: 0,
    };
  }
  const average = values.reduce((a, b) => a + b) / values.length;
  return {
    average: roundTwoDecimal(average),
    max: roundTwoDecimal(Math.max(...values)),
    min: roundTwoDecimal(Math.min(...values)),
  };
};

export const getAccountSelectionOptions = (language: string) => [
  {
    value: '/operations/manage/all',
    displayName: getString('allAccounts', language),
    label: getString('allAccounts', language),
  },
  {
    value: '/operations/manage',
    displayName: getString('myAccounts', language),
    label: getString('myAccounts', language),
  },
];

export const getAnalysisViewOptions = (
  language: string,
  categories: string[] | undefined = ANALYTIC_DISPLAY_CATEGORIES,
  hideRx?: boolean,
  hideCompaction?: boolean,
) =>
  categories
    .map((cat, idx) => ({
      id: idx,
      displayName: getString(cat, language),
      label: getString(cat, language),
      value: cat,
    }))
    .filter((cat) => {
      if (hideCompaction && cat.value === COMPACTION) {
        return false;
      }

      return !hideRx || cat.value !== RX;
    });

const getOmAnalytic = (sampleOrSamplingPlan: SampleType | SamplingPlanType | undefined | null) => {
  const measuredOm = sampleOrSamplingPlan?.analytics[SOIL_ATTRIBUTES]?.[OM_MEASURED_ID];
  if (sampleOrSamplingPlan?.nutrient_panel_analyze_om) {
    return measuredOm;
  }
  const modeledOm = sampleOrSamplingPlan?.analytics[SOIL_ATTRIBUTES]?.[OM_ID];
  return modeledOm;
};

export const getDisplaySamplesForAnalytic = (
  samples: MapboxSample[],
  activeAnalytic: AnalyticType,
) => {
  const subsamples = samples.filter((sample) => sample.properties.biological_subsample);
  const standardSamples = samples.filter((sample) => !sample.properties.biological_subsample);
  const analyticSamples =
    ![SOIL_ATTRIBUTES, BCSR].includes(activeAnalytic.category) && subsamples.length
      ? subsamples
      : standardSamples;
  return analyticSamples.filter((sample) => sample.geometry !== null);
};

export const planHasMissingSample = (samples: MapboxSample[], analytic: AnalyticType) => {
  const displayConfig = analytic?.display_config;
  const key = getAnalyzedKeyForAnalytic(analytic);

  const displaySamples = getDisplaySamplesForAnalytic(samples, analytic);
  return (
    displaySamples.some((sample) => key && sample.properties[key] === true) ||
    displaySamples.some((sample) => {
      // for rootworm we need to check multiple analytics
      const analyticSearchIds =
        analytic.id === ROOTWORM_ID
          ? [NORTHERN_CORN_ROOTWORM_ID, WESTERN_CORN_ROOTWORM_ID]
          : [analytic.id];
      const analytics = analyticSearchIds.map(
        (id) => sample.properties.analytics[analytic.category]?.[id],
      );
      return (
        analytics.every((a) => !a || !Object.keys(a).length) ||
        analytics.some((a) => !displayConfig?.is_field_nd && a.quantity === null)
      );
    })
  );
};

export const getAnalyticsPerTypeCrop = (
  analyticSortOrder: AnalyticSortOrderType,
  analytics: AnalyticType[],
  analyticType: string = CROP_PROTECTION,
  crop: string = CORN,
  analyticsInField?: string[],
) =>
  (analyticSortOrder[analyticType || CROP_PROTECTION]?.[crop || CORN] || []).reduce(
    (all: AnalyticType[], lytic) => {
      const analytic = analytics.find((a) => a.id === lytic);
      return analytic && (!analyticsInField || analyticsInField.includes(String(analytic.id)))
        ? [...all, analytic]
        : all;
    },
    [],
  );

export const addFieldAndGetZoom = (map: MapboxMap, field: FieldType) => {
  const bbox = turfBbox(field) as LngLatBoundsLike;
  const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;

  if (map.getLayer(FIELD_OUTLINE)) {
    map.removeLayer(FIELD_OUTLINE);
  }

  if (map.getSource(FIELD_OUTLINE)) {
    map.removeSource(FIELD_OUTLINE);
  }

  map.addLayer({
    id: FIELD_OUTLINE,
    type: 'line',
    source,
    paint: { 'line-color': WHITE, 'line-width': 2 },
  });

  map.fitBounds(bbox, {
    duration: 0,
    padding: {
      top: 20,
      bottom: 20,
      left: LEGEND_MAX_WIDTH,
      right: LEGEND_MAX_WIDTH,
    },
  });

  return map.getZoom();
};

export const addSampleLayersToMap = (
  activeAnalytic: AnalyticType,
  samplingPlan: SamplingPlanType,
  map: MapboxMap,
  field: FieldType,
  samples: MapboxSample[],
  layerIds: {
    points: string;
    pointsQuantity: string;
  },
) => {
  const displaySamples = getDisplaySamplesForAnalytic(samples, activeAnalytic);

  const validSamples = samplingPlan.is_pro
    ? displaySamples.filter((sample) => {
        const panelToSearch = [BCSR, SOIL_ATTRIBUTES].includes(activeAnalytic.category)
          ? NUTRIENT_PANEL
          : PRESSURE_PANEL;
        return sample.properties.products.includes(panelToSearch);
      })
    : displaySamples;

  const mappedFeatures = sortByRiskColor(
    validSamples.map((val) => ({
      ...val,
      geometry: checkEnlargePointZone(val, samplingPlan, 5, field)
        ? // @ts-expect-error
          turfCircle(turfCenter(val.geometry), DISPLAY_POINT_RADIUS, {
            units: 'meters',
            steps: 100,
          }).geometry
        : val.geometry,
    })),
  ) as Feature<Geometry, SampleType & { quantity: number }>[];

  // adjust quantity values to show asterisks if a specific processing is not available
  const analysisAdjustedSamples = adjustSamplesForInterpolation(
    mappedFeatures,
    activeAnalytic,
    samplingPlan,
  );

  const adjustedSource: mapboxgl.AnySourceData = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: analysisAdjustedSamples,
    },
  };

  if (!map.getLayer(layerIds.points) && validSamples.length) {
    map.addLayer({
      id: layerIds.points,
      type: 'fill',
      source: adjustedSource as GeoJSONSourceRaw,
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-outline-color': WHITE,
      },
    });

    map.addLayer({
      id: layerIds.pointsQuantity,
      type: 'symbol',
      source: adjustedSource,
      layout: {
        'text-field': ['get', 'quantity'],
        'text-allow-overlap': true,
        'text-justify': 'center',
        'text-size': 9,
      },
    });
  }
};

export const setupNonTillageProLayer = (
  map: MapboxMap,
  mapProId: string,
  fillColor: mapboxgl.Expression,
  geojsonUri: string,
  setPopupInfo: (info: { lng: number; lat: number; content: React.ReactNode } | null) => void,
  popupSettings: {
    layerParam: string;
    labelPrefix: string;
    units: string;
  },
) => {
  map.addSource(mapProId, {
    type: 'geojson',
    data: geojsonUri,
  });

  map.addLayer(
    {
      id: mapProId,
      type: 'fill',
      source: mapProId,
      paint: {
        'fill-color': fillColor,
        'fill-opacity': 1,
      },
    },
    FIELD_OUTLINE,
  );

  map.on('click', mapProId, (evt) => {
    const content = getProLayerPopupContent(
      map,
      evt,
      popupSettings.layerParam,
      mapProId,
      popupSettings.labelPrefix,
      popupSettings.units,
    );

    setPopupInfo(content ? { ...evt.lngLat, content } : null);
  });
};

/**
 * Check if the analytic is a pH or buffer pH analytic
 *
 * @param analyticId id of the analytic to check
 * @returns true if the analytic is a pH or buffer pH analytic
 */
export const getIsPh = (analyticId: number) => allPhIds.includes(analyticId);

export const getFieldHasSamplingPlanWithExternalLab = (field: FieldType) =>
  field.features.some((f) =>
    f.properties.sampling_plans.some((plan) => plan.nutrient_external_lab_identifier),
  );
