import { Group, Select, Text } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { getString } from 'strings/translation';
import { getSplitDensityOptions } from 'util/samplePlan';
import { useSplitDensityZoneMutation } from './mutations';

type Props = {
  language: string;
  showCompleteBioLabel: boolean;
  totalZones: number;
  numGroups: number;
};

const SplitDensityQuantitySelect = ({
  language,
  showCompleteBioLabel,
  totalZones,
  numGroups,
}: Props) => {
  const form = useOrderFormContext();
  const inputProps = form.getInputProps('splitIndex');
  const label = getString(showCompleteBioLabel ? 'complete_bio' : 'pressure_panel', language);
  const showMismatch = numGroups && numGroups !== form.getValues().splitIndex;

  const mutation = useSplitDensityZoneMutation(
    (groupedZones) => form.setFieldValue('zones', groupedZones),
    language,
  );

  const splitDensityOptions = getSplitDensityOptions(totalZones).map((option) => ({
    label: option.displayName.toString(),
    value: option.value.toString(),
  }));

  return (
    <Group justify="space-between">
      <Text>{label}: </Text>
      <Group>
        <Text fs="italic" hidden={!showMismatch}>{`*${numGroups} actual`}</Text>
        <Select
          key={form.key('splitIndex')}
          disabled={mutation.isPending}
          aria-label={label}
          data={splitDensityOptions}
          w="11rem"
          {...inputProps}
          onChange={(value) => {
            const { zones } = form.getValues();

            inputProps.onChange(value);

            if (zones) {
              mutation.mutate({
                groupCount: parseInt(value as string, 10),
                zones,
              });
            }
          }}
        />
      </Group>
    </Group>
  );
};

export default SplitDensityQuantitySelect;
