import { Button } from '@mantine/core';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import { getString } from 'strings/translation';
import { isGridsOption } from 'util/samplePlan';

type Props = {
  language: string;
  onClick: () => void;
};

const LockUnlockZonesButton = ({ language, onClick }: Props) => {
  const form = useOrderFormContext();
  const { creationOption, zonesLocked } = form.getValues();

  if (!isGridsOption(creationOption)) {
    return null;
  }

  return (
    <Button variant="outline" onClick={onClick} size="sm">
      {getString(zonesLocked ? 'editZones' : 'lockZones', language)}
    </Button>
  );
};

export default LockUnlockZonesButton;
