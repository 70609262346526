import { FeatureCollection, Point, Polygon } from 'geojson';
import { Feature, featureCollection, point } from '@turf/helpers';
import {
  convertToCircle,
  getNewZonesFromDrag,
  getRotatedGridZones,
  processSingleZoneFeature,
} from 'util/geospatial';
import { GeoJSONSource, GeoJSONSourceRaw, Layer } from 'mapbox-gl';
import {
  CIRCLE,
  FILL,
  POINT,
  POLYGON,
  ZONE_SELECTED,
  ZONE_TYPES,
  HIGHLIGHT_POINT,
  HIGHLIGHT_ZONE,
  PARTIAL_ANALYTICS,
  MODES,
  MERGE,
  SPLIT,
  DELETE,
} from 'constants/mapbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import showToast from 'actions/toastActions';
import { getString } from 'strings/translation';
import { FieldType } from 'store/fields/types';
import removeMapLayer from 'util/mapbox';
import { CUSTOM_POINTS, GRID_POINTS } from 'constants/samplePlanning';
import {
  CreationOption,
  MapDrawReferenceType,
  MapReferenceType,
  MouseDownReferenceType,
  MouseDownType,
  OrderForm,
} from 'store/pricing/types';
import { getMapSelectedUuids } from 'util/samplePlan';

export const moveGridFunction = (
  e: { lngLat: { lng: number; lat: number } },
  mapRef: MapReferenceType,
  previewZones: FeatureCollection<Point | Polygon> | null,
) => {
  if (previewZones) {
    const newPreviewZoneFeatCollection = getNewZonesFromDrag(
      [e.lngLat.lng, e.lngLat.lat],
      previewZones,
    );
    if (newPreviewZoneFeatCollection) {
      const preview = mapRef.current?.getLayer('preview-boundary');

      // Set layer data directly
      const previewBoundary: mapboxgl.GeoJSONSource = mapRef.current?.getSource(
        'preview-boundary',
      ) as GeoJSONSource;
      const previewOutline: mapboxgl.GeoJSONSource = mapRef.current?.getSource(
        'preview-outline',
      ) as GeoJSONSource;
      previewBoundary.setData(newPreviewZoneFeatCollection);

      // Do not show for points
      if (preview?.type !== CIRCLE) {
        previewOutline.setData(newPreviewZoneFeatCollection);
      }
    }
  }
};

export const createPointFunction = (
  e: FeatureCollection<Point>,
  drawRef: MapDrawReferenceType,
  zoneType: 'zones' | 'scanPoints',
  field: FieldType,
  language: string,
  setValues: (values: Partial<OrderForm>) => void,
) => {
  try {
    const all: FeatureCollection<Point> = drawRef.current.getAll();
    const clean = all.features.filter((feat) => feat.properties?.sample_uuid);
    const firstFeat = e.features?.[0];
    if (firstFeat && booleanPointInPolygon(firstFeat, field.features[0])) {
      const newFeat = processSingleZoneFeature(firstFeat, clean.length + 1, ZONE_TYPES.POINT);
      setValues({
        [zoneType]: featureCollection([...clean, newFeat]) as FeatureCollection<Point>,
      });
    }
  } catch (err) {
    showToast(getString('errorWithFieldGeometryMsg', language), 'error', 7000);
  }
};

export const onClickPointFunction = (
  e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
  mapRef: MapReferenceType,
  drawRef: MapDrawReferenceType,
  geomType: typeof POLYGON | typeof POINT,
) => {
  const all: FeatureCollection<Polygon | Point> = drawRef.current.getAll();
  const foundZone = all.features.find((feat) => {
    const convertedPolygon =
      feat.geometry.type === POINT
        ? convertToCircle(feat as Feature<Point>)
        : (feat as Feature<Polygon>);
    const foundPointOrPoly = booleanPointInPolygon(
      point([e.lngLat.lng, e.lngLat.lat]),
      convertedPolygon,
    );
    return (
      foundPointOrPoly && convertedPolygon.properties?.zone_type === geomType.toLocaleLowerCase()
    );
  });
  const zoneUuid = foundZone?.properties?.sample_uuid;
  if (foundZone && zoneUuid) {
    if (mapRef.current?.getLayer(ZONE_SELECTED)) {
      const { _data } = mapRef.current?.getSource(ZONE_SELECTED) as any;
      const alreadyHighlighted = (_data as FeatureCollection).features.some(
        (feat) => feat.properties?.sample_uuid === zoneUuid,
      );
      const zoneAddedOrRemoved = alreadyHighlighted
        ? (_data as FeatureCollection).features.filter(
            (feat) => feat.properties?.sample_uuid !== zoneUuid,
          )
        : [_data.features, foundZone].flat();
      const highlightLayer = featureCollection(zoneAddedOrRemoved as Feature<Polygon | Point>[]);
      (mapRef.current?.getSource(ZONE_SELECTED) as GeoJSONSource).setData(highlightLayer);
    } else {
      const source = {
        type: 'geojson',
        data: featureCollection([foundZone]),
      } as GeoJSONSourceRaw;
      mapRef.current?.addLayer({
        id: ZONE_SELECTED,
        type: geomType === POINT ? CIRCLE : FILL,
        source,
        // @ts-ignore
        paint: geomType === POINT ? HIGHLIGHT_POINT : HIGHLIGHT_ZONE,
      });
    }
  }
};

export const resetDrawingModeFunction = (
  locking: boolean,
  mapRef: MapReferenceType,
  creationOption: CreationOption,
  resetStaticLine: () => void,
  setDrawAction: (action: string | null) => void,
  setToDefaultMode: (mode: string) => void,
  onClickFunctionsTurnOff: MouseDownType[],
) => {
  if (!locking) {
    removeMapLayer(mapRef, PARTIAL_ANALYTICS);
    resetStaticLine();
  }
  setDrawAction(null);
  setToDefaultMode(
    // eslint-disable-next-line no-nested-ternary
    locking
      ? MODES.STATIC
      : [CUSTOM_POINTS, GRID_POINTS].includes(creationOption)
        ? MODES.DRAW_POINT
        : MODES.SELECT,
  );
  // turn all listeners off
  onClickFunctionsTurnOff.forEach((handler) => mapRef.current?.off('click', handler));
};

export const handleDeleteFunction = (
  mapRef: MapReferenceType,
  zones: FeatureCollection<Polygon | Point> | null,
  zonesType: 'zones' | 'scanPoints',
  language: string,
  setValues: (values: Partial<OrderForm>) => void,
  resetDrawingMode: (locking: boolean) => void,
) => {
  const deletingZones = getMapSelectedUuids(mapRef);
  const filteredZones = zones?.features.filter(
    (feat) => !deletingZones.includes(feat.properties?.sample_uuid),
  );
  removeMapLayer(mapRef, ZONE_SELECTED);
  resetDrawingMode(false);
  setValues({
    [zonesType]: featureCollection(filteredZones as Feature<Polygon>[]),
  });
  showToast(getString('deleteZonesSuccess', language));
};

export const handleSelectFunction = (
  mapRef: MapReferenceType,
  drawRef: MapDrawReferenceType,
  action: string,
  start: boolean,
  setDrawAction: (val: string | null) => void,
  onClickUnlockedPolyPoint: (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void,
  resetDrawingMode: (locking: boolean) => void,
) => {
  if ([MERGE, SPLIT, DELETE].includes(action)) {
    setDrawAction(action);
  } else {
    setDrawAction(null);
  }
  if (start) {
    drawRef.current?.changeMode(MODES.STATIC);
    mapRef.current?.on('click', onClickUnlockedPolyPoint);
  } else {
    resetDrawingMode(false);
  }
};

export const setLayerFunction = (
  mapRef: MapReferenceType,
  id: string,
  source: GeoJSONSourceRaw,
  layer: Partial<Layer>,
) => {
  if (mapRef.current) {
    if (mapRef.current.getLayer(id) && mapRef.current.getLayer(id).type !== layer.type) {
      removeMapLayer(mapRef, id);
    }
    if (!mapRef.current.getLayer(id) && !mapRef.current.getSource(id) && layer.type) {
      mapRef.current.addLayer({
        id,
        // @ts-ignore
        type: layer.type,
        source,
        // @ts-ignore
        paint: layer.paint,
      });
    } else if (source.data) {
      // @ts-ignore
      (mapRef.current.getSource(id) as GeoJSONSource).setData(source.data);
    }
  }
};

export const rotateGridFunction = (
  mapRef: MapReferenceType,
  mouseDownRef: MouseDownReferenceType,
  previewZones: FeatureCollection<Polygon | Point> | null,
  zoneType: 'previewZones' | 'previewScanPoints',
  isClockwise: boolean,
  gridAngle: number,
  setValues: (values: Partial<OrderForm>) => void,
  setIsMouseDown: (val: boolean) => void,
) => {
  if (previewZones) {
    const { rotationAngle, newPreviewZones } = getRotatedGridZones(isClockwise, previewZones);
    setValues({
      gridAngle: gridAngle + rotationAngle,
    });
    if (mouseDownRef.current) {
      mapRef.current?.off('mousedown', 'preview-boundary', mouseDownRef.current);
      newPreviewZones?.length &&
        setValues({
          [zoneType]: featureCollection(newPreviewZones),
        });
      setIsMouseDown(false);
    }
  }
};
