import React, { useEffect, useRef, useState } from 'react';
import center from '@turf/center';
import { featureCollection } from '@turf/helpers';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';
import turfBbox from '@turf/bbox';
import 'mapbox-gl/dist/mapbox-gl.css';

import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { AnalyticType, SingleAnalyticType } from 'store/analytics/types';
import {
  CompositeImageryType,
  FieldType,
  MachineDataType,
  MapboxSample,
  SamplingPlanType,
} from 'store/fields/types';
import {
  adjustSamplesForInterpolation,
  getAnalyticFillColor,
  getFieldRisk,
  getSampleAnalyticQuantity,
  setupNonTillageProLayer,
} from 'util/results';
import { Text, Box, Group } from '@mantine/core';
import { getRiskColorFill } from 'util/chartUtils';
import { MAP_HEADER_GRAY, WHITE } from 'util/mapImageryColors';
import useMapboxGl from 'common/MapHooks';
import { NUTRIENT_PANEL } from 'constants/products';
import Popup from 'common/Maps/Popup';
import { getNonTillageProMapPaintFill, setNonTillageProHoverHandlers } from 'util/proMaps';
import { getProNutrientPopupSettings } from 'apps/Results/common/MapPopup';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { FIELD_OUTLINE } from 'constants/mapbox';
import { PopupState } from 'common/Maps/types';
import { getUnitBuAc } from 'util/units';
import { addImageryDataToMap, addMachineDataToMap } from 'util/prescription';
import { roundThreeDecimal } from 'util/numUtils';

import styles from './MapThumbnail.module.css';
import { IMAGERY, YIELD } from 'constants/prescription';

interface MapThumbnailProps {
  activeAnalytic: AnalyticType;
  field: FieldType;
  generateFrom: string;
  harvestData: MachineDataType | null | undefined;
  imageryData: CompositeImageryType | null | undefined;
  planAnalytic: SingleAnalyticType;
  proLayer: EOInferenceLayerType | null | undefined;
  samples: MapboxSample[];
  samplingPlan: SamplingPlanType;
}

const MapThumbnail = ({
  activeAnalytic,
  field,
  generateFrom,
  harvestData,
  imageryData,
  planAnalytic,
  proLayer,
  samples,
  samplingPlan,
}: MapThumbnailProps) => {
  const language = useBroswerLanguage();
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];

  const [viewport, setViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [popupInfo, setPopupInfo] = useState<PopupState>(null);
  const shouldShowProMap = proLayer && samplingPlan.pro_densities.length;

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, false);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  const risk = getFieldRisk(planAnalytic, samplingPlan, activeAnalytic);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;

      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (map.getLayer(FIELD_OUTLINE)) {
        map.removeLayer(FIELD_OUTLINE);
      }
      if (map.getSource(FIELD_OUTLINE)) {
        map.removeSource(FIELD_OUTLINE);
      }

      map.addLayer({
        id: FIELD_OUTLINE,
        type: 'line',
        source,
        paint: { 'line-color': WHITE, 'line-width': 2 },
      });

      map.fitBounds(bbox, {
        duration: 0,
        padding: 30,
      });
      const zoom = map.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const prefix = 'layer';
      const style = map.getStyle();
      style.layers.forEach((layer) => {
        if (layer.id.startsWith(prefix)) {
          map.removeLayer(layer.id);
        }
      });
      Object.keys(style.sources).forEach((source) => {
        if (source.startsWith(prefix)) {
          map.removeSource(source);
        }
      });
      if (harvestData && generateFrom === YIELD) {
        const sourceLayerId = `${prefix}-${harvestData.id}`;
        addMachineDataToMap(harvestData, map, sourceLayerId);
      } else if (imageryData && generateFrom === IMAGERY) {
        const sourceLayerId = `${prefix}-${imageryData.id}`;
        addImageryDataToMap(imageryData, map, sourceLayerId);
      } else if (shouldShowProMap && planAnalytic.data_summary) {
        const sourceLayerId = `${prefix}-${activeAnalytic.id}`;

        const fillColor = getNonTillageProMapPaintFill(
          activeAnalytic,
          planAnalytic.data_summary,
          proLayer,
        );
        const popupSettings = getProNutrientPopupSettings(
          activeAnalytic.id,
          proLayer,
          planAnalytic.unit,
        );
        setNonTillageProHoverHandlers(map, sourceLayerId);
        setupNonTillageProLayer(
          map,
          sourceLayerId,
          fillColor,
          proLayer.geojson_uri,
          (info) => setPopupInfo(info),
          popupSettings,
        );
      } else {
        const validSamples = samples.filter(
          (sample) =>
            sample.geometry !== null && sample.properties.products.includes(NUTRIENT_PANEL),
        );
        const mappedFeatures = validSamples.map((val) => ({
          ...val,
          properties: {
            ...val.properties,
            'fill-color': getAnalyticFillColor(val.properties, activeAnalytic, samplingPlan),
            quantity: roundThreeDecimal(getSampleAnalyticQuantity(val.properties, activeAnalytic)),
          },
        }));
        const newFeatureCollection = featureCollection(mappedFeatures);
        const source = {
          type: 'geojson',
          data: newFeatureCollection,
        } as GeoJSONSourceRaw;
        // adjust quantity values to show asterisks if a specific processing is not available
        const analysisAdjustedSamples = adjustSamplesForInterpolation(
          // @ts-expect-error
          mappedFeatures,
          activeAnalytic,
          samplingPlan,
        );
        const adjustedSource = {
          type: 'geojson',
          data: featureCollection(analysisAdjustedSamples),
        };
        if (!map.getLayer(prefix)) {
          if (validSamples.length) {
            map.addLayer({
              id: prefix,
              type: 'fill',
              source,
              paint: {
                'fill-color': ['get', 'fill-color'],
                'fill-outline-color': WHITE,
              },
            });
            map.addLayer({
              id: `${prefix}-quantity`,
              type: 'symbol',
              // @ts-expect-error
              source: adjustedSource,
              layout: {
                'text-field': ['get', 'quantity'],
                'text-justify': 'center',
                'text-size': 12,
              },
            });
          }
        }
      }
      map.getStyle();
    }
  }, [mapHasLoaded, activeAnalytic, samplingPlan, harvestData, imageryData]);

  const getHeaderText = () => {
    if (harvestData && generateFrom === YIELD) {
      return (
        <>
          <Text c={WHITE} fw={700}>
            {activeAnalytic.name}
          </Text>
          <Text c={WHITE}>
            <b>{getString('yield', language)}:</b>{' '}
            {`${Math.round(harvestData.mean || 0)} ${getUnitBuAc(field.features[0].properties.acreage_unit)}`}
          </Text>
        </>
      );
    }
    if (imageryData && generateFrom === IMAGERY) {
      return (
        <>
          <Text c={WHITE} fw={700}>
            {activeAnalytic.name}
          </Text>
          <Text c={WHITE}>
            <b>{getString('ndvi', language)}:</b> {`${(imageryData.mean || 0).toFixed(2)}`}
          </Text>
        </>
      );
    }
    return (
      <>
        <Text fw={700}>{activeAnalytic.name}</Text>
        <Text>
          <b>{getString('risk', language)}:</b> {getString(risk, language)}
        </Text>
      </>
    );
  };

  return (
    <Box className={styles.FieldMap}>
      <Group
        gap="sm"
        style={{
          paddingLeft: '0.5rem',
          backgroundColor: harvestData
            ? MAP_HEADER_GRAY
            : getRiskColorFill(planAnalytic.risk_level),
        }}
      >
        {getHeaderText()}
      </Group>
      <div ref={mapContainerRef} className={styles.MapWrapper} />
      {popupInfo && mapRef.current && (
        <Popup
          {...popupInfo}
          map={mapRef.current}
          anchor="bottom"
          onClose={() => setPopupInfo(null)}
        >
          {popupInfo.content}
        </Popup>
      )}
    </Box>
  );
};

export default MapThumbnail;
