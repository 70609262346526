import { HIGH_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { TILL_RX_PROPERTIES_KEY, TILL_TEST_RESULTS_PROPERTIES_KEY } from 'constants/proMaps';
import { COMPACTION_ID } from 'constants/results';
import darkRedMarker from 'images/icons/mapMarkers/darkRedMarker.png';
import darkYellowMarker from 'images/icons/mapMarkers/darkYellowMarker.png';
import highRiskMarker from 'images/icons/mapMarkers/highRiskMarker.png';
import lightBlueMarker from 'images/icons/mapMarkers/lightBlueMarker.png';
import lightRedMarker from 'images/icons/mapMarkers/lightRedMarker.png';
import lightYellowMarker from 'images/icons/mapMarkers/lightYellowMarker.png';
import lowRiskMarker from 'images/icons/mapMarkers/lowRiskMarker.png';
import moderateRiskMarker from 'images/icons/mapMarkers/moderateRiskMarker.png';
import redMarker from 'images/icons/mapMarkers/redMarker.png';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { SamplePlanTrackingType } from 'store/samplePlans/types';
import { getString } from 'strings/translation';
import darkBlueMarker from '../images/icons/mapMarkers/darkBlueMarker.png';
import { roundToNPlaces } from './numUtils';
import { getIsPh } from './results';
import { hasNoProducts } from './samplePlanTracking';

export const MapMarkers = {
  DARK_BLUE_MARKER: { name: 'dark-blue-marker', image: darkBlueMarker },
  DARK_RED_MARKER: { name: 'dark-red-marker', image: darkRedMarker },
  DARK_YELLOW_MARKER: { name: 'dark-yellow-marker', image: darkYellowMarker },
  LIGHT_BLUE_MARKER: { name: 'light-blue-marker', image: lightBlueMarker },
  LIGHT_RED_MARKER: { name: 'light-red-marker', image: lightRedMarker },
  LIGHT_YELLOW_MARKER: { name: 'light-yellow-marker', image: lightYellowMarker },
  RED_MARKER: { name: 'red-marker', image: redMarker },
  HIGH_RISK_MARKER: { name: 'high-risk-marker', image: highRiskMarker },
  MODERATE_RISK_MARKER: { name: 'moderate-risk-marker', image: moderateRiskMarker },
  LOW_RISK_MARKER: { name: 'low-risk-marker', image: lowRiskMarker },
};

export const BRIGHT_BLUE = '#0060FF';

export const DARK_BLUE = '#0F3161';
export const LIGHT_BLUE = '#8B97AF';

export const PRESCRIPTION_COLORS: [number, string][] = [
  [0.1, '#89b6ff'],
  [0.2, '#76a9ff'],
  [0.3, '#629dff'],
  [0.4, '#4e91ff'],
  [0.5, '#3b85ff'],
  [0.6, '#2778ff'],
  [0.7, '#146cff'],
  [0.8, '#0060ff'],
  [0.9, '#0059eb'],
  [1, '#0051d8'],
];

export const NO_RISK_FILL = '#00B84A';
export const AWAITING_RESULTS_FILL = '#FFFFFF';
export const NOT_ANALYZED_FILL = '#000000';
export const NO_DATA_FILL = '#dddddd';

export const RISK_FILL_COLORS = {
  HIGH_RISK: '#E68080',
  MODERATE_RISK: '#FFBD80',
  LOW_RISK: '#80DBA3',
  NOT_DETECTED: '#C4C4C4',
};

export const SALES_FLOW_COLORS = {
  DARK_BLUE: '#0F3161',
  GREEN: '#72CE97',
  RED: '#DA7272',
  GREY: '#8797B0',
};

export const PH_COLORS = {
  high: '#1d3564',
  mid: '#8a5796',
  low: '#f4bd4f',
};

export const RX_INCH_LOW = 0;
export const RX_INCH_HIGH = 18;
export const COMPACTION_PSI_LOW = 0;
export const COMPACTION_PSI_HIGH = 600;

export const CROP_PLAN_YELLOW = '#FFEC99';
export const WHITE = '#FFFFFF';
export const OVERLAY_GREY = '#C4C4C4';
export const BORDER_GREY = '#404040';
export const DRAW_LIGHT_BLUE = '#3bb2d0';
export const DRAW_DARK_BLUE = '#0e6996';
export const DRAW_ORANGE = '#fbb03b';
export const DRAW_DARK_GREY = '#404040';
export const GREY_BLUE = '#4e5c6a';
export const LIGHT_GREEN = '#92FE88';
export const RED = '#D82A3B';
export const RESTRICTED_RED = '#E88484';
export const PRO_MAP_HIGH = '#FCB730';
export const PRO_MAP_LOW = '#6C01A7';
export const MAP_HEADER_GRAY = '#8897B1';
export const YELLOW = '#FFFF00';
export const DARK_GREEN = '#036E3A';

export const isFullyAssigned = (samplePlan: SamplePlanTrackingType) => {
  const isProOrTillRx = samplePlan.is_pro || samplePlan.is_till_rx;
  if (samplePlan.is_till_rx && hasNoProducts(samplePlan)) {
    return true;
  }
  if (isProOrTillRx && samplePlan.assigned_to_id && samplePlan.assigned_to_scan_id) {
    return true;
  }
  return !isProOrTillRx && samplePlan.assigned_to_id;
};

export const getMapPinColor = (samplePlan: SamplePlanTrackingType) => {
  if (samplePlan.alerts.length && samplePlan.ready_to_sample) {
    return MapMarkers.DARK_RED_MARKER.name;
  }
  if (samplePlan.alerts.length) {
    return MapMarkers.LIGHT_RED_MARKER.name;
  }
  if (isFullyAssigned(samplePlan)) {
    return MapMarkers.DARK_BLUE_MARKER.name;
  }
  if (samplePlan.ready_to_sample) {
    return MapMarkers.DARK_YELLOW_MARKER.name;
  }
  return MapMarkers.LIGHT_YELLOW_MARKER.name;
};

export const getPlanTrackingLegendIcons = (language: string) => [
  {
    name: getString('alertsAndReady', language),
    icon: MapMarkers.DARK_RED_MARKER.image,
  },
  {
    name: getString('alertsAndNotReady', language),
    icon: MapMarkers.LIGHT_RED_MARKER.image,
  },
  {
    name: getString('fullyAssigned', language),
    icon: MapMarkers.DARK_BLUE_MARKER.image,
  },
  {
    name: getString('ready', language),
    icon: MapMarkers.DARK_YELLOW_MARKER.image,
  },
  {
    name: getString('default', language),
    icon: MapMarkers.LIGHT_YELLOW_MARKER.image,
  },
];

export const getMapPinColorRiskLevels = (riskLevel: string) => {
  if (riskLevel === HIGH_RISK) {
    return MapMarkers.HIGH_RISK_MARKER.name;
  }
  if (riskLevel === MODERATE_RISK) {
    return MapMarkers.MODERATE_RISK_MARKER.name;
  }
  return MapMarkers.LOW_RISK_MARKER.name;
};

export const getMapLegendColorStops = (highestRiskOrder: string, analyticId: number) => {
  if (getIsPh(analyticId)) {
    return [PH_COLORS.low, PH_COLORS.mid, PH_COLORS.high];
  }

  if (highestRiskOrder === RISK_FILL_COLORS.LOW_RISK || analyticId === COMPACTION_ID) {
    return [RISK_FILL_COLORS.LOW_RISK, RISK_FILL_COLORS.MODERATE_RISK, RISK_FILL_COLORS.HIGH_RISK];
  }

  return [RISK_FILL_COLORS.HIGH_RISK, RISK_FILL_COLORS.MODERATE_RISK, RISK_FILL_COLORS.LOW_RISK];
};

export const getCompactionTestResultsFillColor = (
  {
    high,
    low,
  }: {
    high: number;
    low: number;
  } = {
    high: COMPACTION_PSI_HIGH,
    low: COMPACTION_PSI_LOW,
  },
): mapboxgl.Expression => {
  const mid = (high - low) / 2 + low;

  return [
    'interpolate',
    ['linear'],
    ['get', TILL_TEST_RESULTS_PROPERTIES_KEY],
    low,
    RISK_FILL_COLORS.LOW_RISK,
    mid,
    RISK_FILL_COLORS.MODERATE_RISK,
    high,
    RISK_FILL_COLORS.HIGH_RISK,
  ];
};

export const getCompactionRxFillColor = (): mapboxgl.Expression => [
  'interpolate',
  ['linear'],
  ['get', TILL_RX_PROPERTIES_KEY],
  RX_INCH_LOW,
  RISK_FILL_COLORS.LOW_RISK,
  RX_INCH_HIGH / 2,
  RISK_FILL_COLORS.MODERATE_RISK,
  RX_INCH_HIGH,
  RISK_FILL_COLORS.HIGH_RISK,
];

export const getLegendTitle = (
  analyticId: number,
  isCompactionTestResults: boolean,
  isRx: boolean,
  planAnalyticUnit = '',
  inferenceLayer?: EOInferenceLayerType | null,
) => {
  if (getIsPh(analyticId)) {
    return 'pH';
  }

  if (isRx) {
    return 'in';
  }

  const isCompaction = analyticId === COMPACTION_ID;

  if (isCompaction && inferenceLayer && isCompactionTestResults) {
    return inferenceLayer.layer_param?.replace(/_/g, ' ') || '';
  }

  return planAnalyticUnit;
};

/**
 * Get a reversed legend-friendly array of numbers based on the high and low values. The function
 * will keep retrying itself until a unique set of numbers is generated.
 *
 * @param high Highest value
 * @param low Lowest value
 * @param numberOfDecimals How many decimals to round to. Default: `0`
 * @param numberOfMarks How many marks to generate. Default: `7`
 * @returns Reversed array of numbers based on the high and low values
 */
export const getLegendMarks = (
  high: number,
  low: number,
  numberOfDecimals = 0,
  numberOfMarks = 7,
): number[] => {
  const step = (high - low) / (numberOfMarks - 1);

  const marks = Array.from({ length: numberOfMarks }, (_, i) => {
    const value = low + i * step;

    return roundToNPlaces(numberOfDecimals)(value) || value;
  });

  const numberOfUniqueMarks = new Set(marks).size;
  const numbersAreNotUnique = numberOfUniqueMarks < numberOfMarks;

  if (numbersAreNotUnique) {
    // Keep increasing the number of decimals until we have unique numbers
    return getLegendMarks(high, low, numberOfDecimals + 1);
  }

  marks.reverse();

  return marks;
};
